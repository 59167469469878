export default function ({
  showSuggestions,
  filteredSuggestions,
  userInput,
  activeSuggestion,
  onClickFn,
  isError,
  value,
}) {
  if (showSuggestions && value) {
    if (filteredSuggestions.length) {
      return (
        <>
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} onClick={onClickFn}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        </>
      );
    } else {
      return (
        <>
          <div className="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        </>
      );
    }
  }
}
