export const GROUP_DETAIL = {
  ROOMS_INSIDE_TITLE: "Rooms Inside the Block",
  ROOMS_OUTSIDE_TITLE: "Rooms Outside the Block",
  ADD_ADJUSTMENT: "Add Adjustment",
  EDIT_ADJUSTMENT: "Edit Adjustment",
  DEFER_PAYMENT_TITLE: "Defer Payment",
  DEFER_PAYMENT_QA: "Release funded commission at 45 days?",
  BATCH_TITLE: "Group Batches",
  BATCH_SELECTED: "batch selected",
};
