import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../../utils/Api_URL";
import {
  parseJwt,
  buildPropertyDetails,
  gotoLogin,
  setSession,
  getSession,
  downloadFile,
  setExpiry,
} from "../../../utils/utils";
import { IATA_API_ERROR, IATA_ERROR } from "../../../utils/constants";

const initialState = {
  userInfoLoading: true,
  overviewLoading: true,
  userInfo: null,
  propertyDetails: {},
  propertyName: "",
  propCode: "",
  currencyType: "PROPERTY",
  showBroadcastMsg: true,
  // showPersonalMsg: true,
  transientBatch: {},
  groupBatch: {},
  researchAudit: {},
  transientTax: [],
  hasMultipleProperty: true,
  propCodeChanged: false,
  isInvalidPropertyCodeError: false,
  propertyErrorMsg: "",
  logOut: false,
  isRespGroup: false,
  batchJobRunning: false,
  sessionReset: 0,
  pendingLinkBlockID: "",
  hasUnsavedChanges: false,
};

export const landingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    closeBroadcastMsg: (state) => {
      state.showBroadcastMsg = false;
    },
    openBroadcastMsg: (state) => {
      state.showBroadcastMsg = true;
    },
    // closePersonalMsg: (state) => {
    //   state.showPersonalMsg = false;
    // },
    // openPersonalMsg: (state) => {
    //   state.showPersonalMsg = true;
    // },
    updateUserInfoLoading: (state, action) => {
      state.userInfoLoading = action.payload;
    },
    updateOverviewLoading: (state, action) => {
      state.overviewLoading = action.payload;
    },
    usersInfoReceived: (state, action) => {
      if (action.payload) {
        state.userInfo = buildPropertyDetails(action.payload);
        state.propCode = state.userInfo?.propCode;
        state.currency = state.userInfo?.currency;
        state.logOut = false;
      } else {
        state.userInfo = null;
        state.propCode = "";
        state.propertyName = "";
        state.currency = null;
        state.logOut = false;
      }
    },
    updateBatchJobAlert: (state, action) => {
      const userData = state.userInfo;
      userData.authIndicator = action.payload.authIndicator;
      state.userInfo = userData;
      state.batchJobRunning = action.payload.batchJobRunning;
    },
    userLogout: (state, action) => {
      state.logOut = true;
    },
    batchDetailsRecevied: (state, action) => {
      state.transientBatch = action?.payload?.transientBatch || {};
      state.groupBatch = action?.payload?.groupBatch || {};
      state.researchAudit = action?.payload?.groupResearchAudit || {};
      state.transientTax = action?.payload?.transientTax || {};
    },
    updateCurrancy: (state, action) => {
      state.currencyType = action.payload;
    },
    updatePropertyCode: (state, action) => {
      setSession("propCode", action.payload);
      state.propCode = action.payload;
    },
    updatePropertyDetails: (state, action) => {
      state.propertyDetails = action.payload;
      state.propertyName = action.payload?.propName;
      setSession("propName", action.payload?.propName);
    },
    changedPropertyCode: (state, action) => {
      state.propCodeChanged = action.payload;
    },
    updatePropertyCodeError: (state, action) => {
      state.isInvalidPropertyCodeError = action.payload;
    },
    updateMultiCurrencyFlag: (state, action) => {
      state.hasMultipleProperty = action.payload;
    },
    updateRespGroup: (state, action) => {
      state.isRespGroup = action.payload;
    },
    resetSessionTimer: (state, action) => {
      state.sessionReset = state.sessionReset + 1;
    },
    setPendingLinkBlockID: (state, action) => {
      state.pendingLinkBlockID = action.payload;
    },
    unsavedAlert: (state, action) => {
      state.hasUnsavedChanges = action.payload;
    },
  },
});

export const {
  // closePersonalMsg,
  // openPersonalMsg,
  closeBroadcastMsg,
  openBroadcastMsg,
  updateUserInfoLoading,
  updateOverviewLoading,
  usersInfoReceived,
  batchDetailsRecevied,
  updateCurrancy,
  updatePropertyCode,
  changedPropertyCode,
  updatePropertyCodeError,
  updateMultiCurrencyFlag,
  updatePropertyDetails,
  userLogout,
  updateRespGroup,
  updateBatchJobAlert,
  resetSessionTimer,
  setPendingLinkBlockID,
  unsavedAlert,
} = landingSlice.actions;

export default landingSlice.reducer;

export const createSession = () => async (dispatch) => {
  dispatch(usersInfoReceived(null));
  try {
    let response = {};
    let existingSessionID = getSession("x-session-id");
    if (existingSessionID) {
      response = await axios.get(
        `${API_URL.GET_SESSION}?sessionId=${existingSessionID}`
      );
      if (response?.data?.sessionId) {
        setSession("x-session-id", response.data.sessionId);
        setSession("accessToken", response.data.accessToken);
        setSession("sessionDuration", response.data.sessionDuration);
        setExpiry(response.data.sessionDuration);
        const userInfo = parseJwt(response.data.accessToken);
        dispatch(usersInfoReceived(userInfo));
      }
    } else {
      gotoLogin();
      throw response;
    }
  } catch (error) {
    console.error(error);
    if (error?.response?.data?.message === "User session Expired") {
      gotoLogin();
    }
  }
};

export const extendSession = () => async (dispatch) => {
  try {
    let response = {};
    let existingSessionID = getSession("x-session-id");
    if (existingSessionID) {
      response = await axios.get(
        `${API_URL.GET_SESSION}?sessionId=${existingSessionID}`
      );
      if (response?.data?.sessionId) {
        setSession("x-session-id", response.data.sessionId);
        setSession("accessToken", response.data.accessToken);
        setSession("sessionDuration", response.data.sessionDuration);
        setExpiry(response.data.sessionDuration);
      }
    } else {
      gotoLogin();
      throw response;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message === "User session Expired") {
      gotoLogin();
    }
  }
};

export const validateIATAFilter = (iataNum) => async (dispatch) => {
  try {
    const iataResponse = await axios.get(`${API_URL.VALIDATE_IATA}/${iataNum}`);
    const indicator = iataResponse?.data?.indicator;
    const isValid = indicator === null || indicator === "" ? IATA_ERROR : true;
    return isValid;
  } catch (error) {
    if (error?.response?.status === 400) {
      return IATA_ERROR;
    } else {
      return IATA_API_ERROR;
    }
  }
};

export const getPropertyDetails = (propCode) => async (dispatch) => {
  try {
    let response = {};
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(`${API_URL.GET_PROPERTY_DETAILS}`);
    } else {
      response = await axios.get(`${API_URL.GET_PROPERTY_DETAILS}/${propCode}`);
    }
    if (response.data.respGroup) {
      dispatch(updateRespGroup(true));
      let property = {};
      let currencyArray = response.data.propertyList.filter(
        (ele) => ele.currencyCode !== "USD"
      );
      if (currencyArray.length > 0) {
        property.currencyCode = currencyArray[0];
      } else {
        property.currencyCode = response.data.propertyList[0].currencyCode;
      }
      property.propName = response.data.respGroupName;
      dispatch(updatePropertyDetails(property));
    } else {
      dispatch(updatePropertyDetails(response.data.propertyList[0]));
    }
  } catch (error) {}
};

export const fetchBatchDetails = (propertyCode, param) => async (dispatch) => {
  try {
    dispatch(updateOverviewLoading(true));
    let response = {};
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(`${API_URL.GET_BATCH_OVERVIEW}`, {
        params: param,
      });
    } else {
      response = await axios.post(
        `${API_URL.GET_BATCH_OVERVIEW}/${propertyCode}`,
        param
      );
    }
    dispatch(batchDetailsRecevied(response.data));
    dispatch(updateOverviewLoading(false));
    dispatch(updateMultiCurrencyFlag(response?.data?.multipleCurrency));
  } catch (error) {
    console.error(error);
    dispatch(batchDetailsRecevied());
    dispatch(updateOverviewLoading(false));
  }
};

export const validatePropCode = async (propertyCode) => {
  try {
    return await axios.get(`${API_URL.GET_PROPERTY_DETAILS}/${propertyCode}`);
  } catch (error) {
    console.error(error);
    return await error;
  }
};

export const getBatchJobAlert = async (propertyCode) => {
  try {
    const response = await axios.get(`${API_URL.GET_BATCH_ACTIVE_WINDOW}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return await error.response.data;
  }
};

export const getDownloadFile = (filePath, fileName) => async (dispatch) => {
  try {
    const headers = {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    const response = await axios.get(filePath, {
      responseType: "blob",
      headers,
    });
    downloadFile(response, fileName);
    return { success: true };
  } catch (error) {
    return { success: false };
  }
};
