import React, { useEffect, useState } from "react";
import Autocomplete from "../autocomplete/Autocomplete";
import DatePicker from "../datepicker/DatePicker";
import MultiSelect from "../multiselect/MultiSelect";
import MultiSelectv2 from "../multiselect/MultiSelectv2";

export default function FormField({
  name,
  value,
  onChange,
  onBlur,
  onKeyup,
  type,
  multiple,
  isError,
  disabled = false,
  presetion,
  className,
  width,
  ...rest
}) {
  const [changeVal, setChangeVal] = useState(value);

  useEffect(() => {
    if (value !== changeVal) {
      setChangeVal(value);
    }
  }, [value]);

  useEffect(() => {
    //if (rest.defaultValue) {
    setChangeVal(rest.defaultValue);
    // }
  }, [rest.defaultValue]);

  switch (type) {
    case "readonly":
      return (
        <>
          <p className="readonly_text">
            {rest?.displayValue ? rest.displayValue : rest?.defaultValue}
          </p>
          <input
            key={`field-item-${name}`}
            type="hidden"
            name={name}
            id={`field-item-${name}`}
            value={rest?.defaultValue}
            disabled={disabled}
            maxlength={rest.maxLength}
            width={width}
            className={className}
          />
        </>
      );
    case "text":
      return (
        <input
          key={`field-item-${name}`}
          type={type}
          name={name}
          id={`field-item-${name}`}
          value={changeVal}
          placeholder={rest?.placeholder}
          disabled={disabled}
          onChange={(event) => {
            let val = event.target.value;
            if (typeof rest?.precision !== "undefined") {
              /* Logic to remove precision*/
              let precision = Number(rest?.precision);
              if (precision === 0 && val.includes(".")) {
                let [num] = val.split(".");
                val = num;
              } else if (precision > 0 && val.includes(".")) {
                let [num, decimal] = val.split(".");
                const correctDecimal = decimal.slice(0, precision);
                val = num.concat(".", correctDecimal);
              }
            }
            setChangeVal(val);
            if (onChange) {
              onChange(val);
            }
          }}
          onKeyUp={(event) => {
            let val = event.target.value;
            setChangeVal(val);
            if (onKeyup) {
              onKeyup(val);
            }
          }}
          onBlur={(event) => {
            let val = event.target.value;
            setChangeVal(val);
            if (onBlur) {
              onBlur(val);
            }
          }}
          maxlength={rest?.maxLength}
          width={width}
          className={className}
        />
      );
    case "radio":
      return rest?.options.map((e, index) => (
        <div className="form-sub-item" key={`field-item-${name}-${index + 1}`}>
          <input
            type={type}
            name={e}
            id={`field-item-${name}`}
            value={e}
            checked={value === e}
            onChange={(event) => onChange(event.target.value)}
            disabled={disabled}
            width={width}
            className={className}
          />
          <label for={`field-item-${name}`} className="px-2">
            {e}
          </label>
        </div>
      ));
    case "select":
      return (
        <select
          key={`field-item-${name}`}
          type={type}
          name={name}
          id={`field-item-${name}`}
          multiple={multiple}
          value={value}
          onChange={(event) => {
            let val = Array.from(event.target.selectedOptions, (option) => {
              if (rest?.placeholder && rest?.placeholder === option.value) {
                return "";
              } else {
                return option.value;
              }
            });
            if (!multiple) {
              val = val[0];
            }
            onChange(val);
          }}
          disabled={disabled}
          width={width}
          className={className}
        >
          {rest?.placeholder ? (
            <option key={`field-item-${name}-option`} value={rest?.placeholder}>
              {rest?.placeholder}
            </option>
          ) : (
            ""
          )}
          {rest?.options?.map((e, index) => (
            <option key={`field-item-${name}-${index + 1}`} value={e}>
              {e}
            </option>
          ))}
          ;
        </select>
      );
    case "selectv2":
      return (
        <select
          key={`field-item-${name}`}
          name={name}
          id={`field-item-${name}`}
          multiple={multiple}
          onChange={(event) => {
            let val = Array.from(event.target.selectedOptions, (option) => {
              if (rest?.placeholder && rest?.placeholder === option.value) {
                return "";
              } else {
                return option.value;
              }
            });
            if (!multiple) {
              val = val[0];
            }
            onChange(val);
          }}
          disabled={disabled}
          width={width}
          className={className}
          value={changeVal}
        >
          {rest?.placeholder ? (
            <option key={`field-item-${name}-option`} value={rest?.placeholder}>
              {rest?.placeholder}
            </option>
          ) : (
            ""
          )}
          {rest?.options?.map((opt, index) => (
            <option
              title={opt.label}
              key={`field-item-${name}-${index + 1}`}
              value={opt.value}
            >
              {opt.label}
            </option>
          ))}
          ;
        </select>
      );
    case "checkbox":
      return (
        <div className="form-sub-item">
          <input
            key={`field-item-${name}`}
            type={type}
            name={name}
            id={`field-item-${name}`}
            checked={value}
            onChange={(event) => onChange(event.target.checked)}
            disabled={disabled}
            width={width}
            className={className}
          />
          {rest?.checkboxLabel ? (
            <label for={`field-item-${name}`}>{rest?.checkboxLabel}</label>
          ) : null}
        </div>
      );
    case "autocomplete":
      return (
        <Autocomplete
          key={`field-item-${name}`}
          value={value}
          onChange={(val) => onChange(val)}
          suggestions={rest?.options}
          placeholder={rest?.placeholder}
          isError={rest?.rules?.message !== ""}
          disabled={disabled}
          width={width}
          className={className}
        />
      );
    case "date":
      return (
        <DatePicker
          maxDate={rest.maxDate}
          onChange={(val) => onChange(val)}
          value={changeVal}
          minDate={rest?.minDate}
          disabled={disabled}
          width={width}
          className={className}
        />
      );
    case "multiselect":
      return (
        <MultiSelect
          id={`field-item-${name}`}
          onChange={(val) => onChange(val)}
          placeholder={rest?.placeholder}
          options={rest?.options}
          width={width}
          defaultValue={rest?.defaultValue}
        />
      );
    case "multiselectv2":
      return (
        <MultiSelectv2
          id={`field-item-${name}`}
          onChange={(val) => onChange(val)}
          placeholder={rest?.placeholder}
          options={rest?.options}
          width={width}
          defaultValue={rest?.defaultValue}
        />
      );
    default:
      return null;
  }
}
