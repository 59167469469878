/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import success from "../../assets/success_icon.png";
import danger from "../../assets/danger_icon.png";
import info from "../../assets/info_icon.png";
export default function InfoBar({
  variant,
  enableCloseButton = true,
  enableTimerClose = true,
  timeoutSecs = 10000,
  onCloseCallback,
  disableAutoCloseCallback = false,
  children,
  showIcon = true,
}) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!show && onCloseCallback) {
      onCloseCallback();
    }
    return () => {
      if (!disableAutoCloseCallback && onCloseCallback) {
        onCloseCallback();
      }
    };
  }, [show]);

  const icons = { success, danger, info };
  const IconToShow = icons[variant] ? icons[variant] : danger;

  let timeId;
  useEffect(() => {
    if (enableTimerClose) {
      if (timeId) {
        clearTimeout(timeId);
      }
      timeId = setTimeout(() => {
        setShow(false);
      }, timeoutSecs);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, []);
  return (
    show && (
      <div className="info-bar-comp">
        <Alert
          variant={variant}
          className={`${variant}-infobar alert-bar py-0 px-2`}
          onClose={() => setShow(false)}
          dismissible={enableCloseButton}
        >
          {showIcon ? (
            <img src={IconToShow} alt={variant} className="img-class m-2 " />
          ) : null}
          {showIcon ? (
            <span className="px-2">{children}</span>
          ) : (
            <p className="m-2">{children}</p>
          )}
        </Alert>
      </div>
    )
  );
}
