import { configureStore } from "@reduxjs/toolkit";
import landingReducer from "./feature/landing/landingSlice";
import transientSlice from "./feature/transient/transientSlice";
import groupBlockSlice from "./feature/groupblock/groupBlockSlice";
import staySummarySlice from "./feature/staysummary/staySummarySlice";
import transientTaxSlice from "./feature/transienttax/transientTaxSlice";
import auditSlice from "./feature/audit/auditSlice";
import adminSlice from "./feature/admin/adminSlice";

export const store = configureStore({
  reducer: {
    landing: landingReducer,
    transient: transientSlice,
    group: groupBlockSlice,
    staySummary: staySummarySlice,
    transientTax: transientTaxSlice,
    audit: auditSlice,
    admin: adminSlice,
  },
});
