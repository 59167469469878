import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../../utils/Api_URL";
import { DEFAULT_REVIEW_FILTER } from "../../../utils/config";

const initialState = {
  loadingTransientOverview: true,
  loadingTransientBlock: true,
  block: [],
  transientTotalCount: 1,
  transientBatchOverview: {},
  payloadForTable: {
    filterBy: {
      month: "",
      modIndicator: [],
      status: [...DEFAULT_REVIEW_FILTER],
      batchId: "",
    },
    sortBy: {
      sortColumn: "",
      sortValue: "",
    },
    pageNumber: "1",
    pageSize: "25",
    currencyType: "property",
  },
  reviewApproveSuccess: false,
  reviewApproveFailed: false,
  overviewInternalError: false,
  tableInternalError: false,
  serverError: false,
};

export const transientSlice = createSlice({
  name: "transient",
  initialState,
  reducers: {
    updateTransientBlockLoading: (state, action) => {
      state.loadingTransientBlock = action.payload;
    },
    updateTransientOverviewLoading: (state, action) => {
      state.loadingTransientOverview = action.payload;
    },
    updateTransientTableParams: (state, action) => {
      state.payloadForTable = { ...state.payloadForTable, ...action.payload };
    },
    transientBlockReceived: (state, action) => {
      state.block = action.payload.batchDetails;
      state.transientTotalCount = action.payload.totalCount;
      state.loading = false;
    },
    transientBatchOverviewReceived: (state, action) => {
      state.transientBatchOverview = action.payload?.transientBatch;
    },
    tBReviewApproveSuccess: (state, action) => {
      state.reviewApproveSuccess = action.payload;
    },
    tBReviewApproveFailed: (state, action) => {
      state.reviewApproveFailed = action.payload;
    },
    updateOverviewInternalError: (state, action) => {
      state.overviewInternalError = action.payload;
    },
    updateTableInternalError: (state, action) => {
      state.tableInternalError = action.payload;
    },
    updateServerError: (state, action) => {
      state.serverError = action.payload;
    },
  },
});

export const {
  updateTransientBlockLoading,
  updateTransientOverviewLoading,
  updateTransientTableParams,
  transientBlockReceived,
  transientBatchOverviewReceived,
  updateTransientBatchDetailError,
  tBReviewApproveSuccess,
  tBReviewApproveFailed,
  updateOverviewInternalError,
  updateTableInternalError,
  updateServerError,
} = transientSlice.actions;

export default transientSlice.reducer;

export const fetchtransientBlock = (payload, propCode) => async (dispatch) => {
  try {
    dispatch(updateTransientBlockLoading(true));
    dispatch(updateTableInternalError(false));
    let response = {};
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(
        API_URL.GET_BATCH_DETAILS,
        payload.tablePayload
      );
    } else {
      response = await axios.post(
        `${API_URL.GET_BATCH_DETAILS}/${payload.propCode}`,
        payload.tablePayload
      );
    }
    if (response?.data?.batchDetails) {
      dispatch(transientBlockReceived(response.data));
      dispatch(updateTransientBlockLoading(false));
    } else {
      throw response;
    }
  } catch (error) {
    console.error(error);
    dispatch(updateTransientBlockLoading(false));
    if (error?.response?.status === 404) {
      dispatch(updateServerError(true));
    } else {
      dispatch(updateTableInternalError(true));
    }
  }
};

export const fetchTransientBatchOverview =
  (propertyCode, transientOverviewReqBody) => async (dispatch) => {
    try {
      dispatch(updateTransientOverviewLoading(true));
      dispatch(updateOverviewInternalError(false));
      dispatch(updateServerError(false));
      let response = {};
      if (process.env.REACT_APP_ENV === "local") {
        response = await axios.get(
          `${API_URL.GET_BATCH_OVERVIEW}`,
          transientOverviewReqBody
        );
      } else {
        response = await axios.post(
          `${API_URL.GET_BATCH_OVERVIEW}/${propertyCode}`,
          transientOverviewReqBody
        );
      }
      dispatch(updateTransientOverviewLoading(false));
      dispatch(transientBatchOverviewReceived(response?.data));
    } catch (error) {
      dispatch(updateTransientOverviewLoading(false));
      if (error?.response?.status === 404) {
        dispatch(updateServerError(true));
      } else {
        dispatch(updateOverviewInternalError(true));
      }
    }
  };

export const updateTBStatus =
  (actionStatus, updateTBReqBody) => async (dispatch) => {
    try {
      dispatch(updateTransientBlockLoading(true));
      dispatch(updateServerError(false));
      dispatch(tBReviewApproveFailed(false));
      let response = {};
      response = await axios.post(
        `${API_URL.PUT_BATCH_REVIEW_APPROVE}/${actionStatus}`,
        updateTBReqBody
      );
      dispatch(updateTransientBlockLoading(false));
      if (response.data.status && response.data.status === true) {
        //Success case
        dispatch(tBReviewApproveSuccess(true));
      } else {
        //Error case
        dispatch(tBReviewApproveFailed(true));
      }
    } catch (error) {
      dispatch(updateTransientBlockLoading(false));
      //Error case
      if (error?.response?.status === 404) {
        dispatch(updateServerError(true));
      } else {
        dispatch(tBReviewApproveFailed(true));
      }
    }
  };
