import React from "react";
import { Container } from "react-bootstrap";
import CardDisplay from "../carddisplay/CardDisplay";
import { BATCH_STATUS, CURRENCY_TYPE } from "../../utils/constants";
import totalStaysIcon from "../../assets/total_stays.png";
import totalTaxIcon from "../../assets/total_tax.png";
import totalCommissionsIcon from "../../assets/total_commission.png";

export default function StaySummaryBatchOverview({
  data,
  hasMultipleCurrency,
  currencyType,
}) {
  const transientSummaryData = data?.staySummary;
  const currencyCodeText =
    currencyType === CURRENCY_TYPE.USD ? CURRENCY_TYPE.USD : data?.currencyCode;
  const cardDetail = [
    {
      icon: totalStaysIcon,
      statusDetail: BATCH_STATUS.TOTAL_STAYS.HEADING,
      stayDetail: `${transientSummaryData?.totalStays || 0}`,
      imageClass: "icon-small",
    },
    {
      amountDetail: `${
        currencyType === CURRENCY_TYPE.USD
          ? transientSummaryData?.propAmountUsd
          : transientSummaryData?.propAmount || 0
      } ${currencyCodeText}`,
      icon: totalCommissionsIcon,
      statusDetail: `${BATCH_STATUS.TOTAL_PROPERTY_AMOUNT.HEADING}`,
      imageClass: "icon-large",
    },

    {
      icon: totalTaxIcon,
      amountDetail: `${
        currencyType === CURRENCY_TYPE.USD
          ? transientSummaryData?.taxAmountUsd
          : transientSummaryData?.taxAmount || 0
      } ${currencyCodeText}`,
      statusDetail: ` ${BATCH_STATUS.TOTAL_COMMISSION_TAX.HEADING}`,
      imageClass: "icon-l",
    },
  ];
  const classNames = {
    colClass: "my-3 p-0 mx-0",
    cardClass: "mx-3 px-1 py-2",
    cardBodyClass: "py-3 px-0",
    divClass: "px-4",
  };
  return (
    <Container fluid className="p-0">
      <div md="2" className="row row-cols-3">
        {cardDetail.map((cardData, index) => {
          return (
            <CardDisplay
              key={`transientbatch-card-${index + 1}`}
              icon={cardData.icon}
              statusDetail={cardData.statusDetail}
              amountDetail={cardData.amountDetail}
              stayDetail={cardData.stayDetail}
              fieldName={cardData.fieldName}
              linkTo={cardData.linkTo}
              imageClass={cardData.imageClass}
              classNames={classNames}
              hasMultipleCurrency={hasMultipleCurrency}
              currencyType={currencyType}
            />
          );
        })}
      </div>
    </Container>
  );
}
