import React from "react";
import { Container } from "react-bootstrap";
import { BATCH_STATUS, CURRENCY_TYPE } from "../../utils/constants";
import CardDisplay from "../carddisplay/CardDisplay";
import reviewIcon from "../../assets/review.png";
import approveIcon from "../../assets/check_circle.png";
import pendingFundingIcon from "../../assets/access_time_filled.png";
import totalCommissionsIcon from "../../assets/total_commission.png";

export default function GroupBatchOverview({
  data,
  isLanding,
  overviewLandingClasses,
  currencyType,
  className,
}) {
  const groupData = data;
  const currencyCodeText =
    currencyType === CURRENCY_TYPE.USD
      ? CURRENCY_TYPE.USD
      : groupData?.currencyCode;

  const groupReviewAmt =
    currencyType === CURRENCY_TYPE.USD
      ? groupData?.reviewAmountUsd
      : groupData?.reviewAmount;

  const groupPendingAmt =
    currencyType === CURRENCY_TYPE.USD
      ? groupData?.approvalAmountUsd
      : groupData?.approvalAmount;

  const groupFundAmt =
    currencyType === CURRENCY_TYPE.USD
      ? groupData?.pendingFundingAmountUsd
      : groupData?.pendingFundingAmount;

  const groupTotalCommissionAmt =
    currencyType === CURRENCY_TYPE.USD
      ? groupData?.totalCommissionsUsd
      : groupData?.totalCommissions;
  const cardDetal = [
    {
      icon: reviewIcon,
      statusDetail: `${groupData?.reviewCount || 0} ${
        groupData?.reviewCount?.toString() === "1"
          ? BATCH_STATUS.REVIEW.HEADING_BATCH
          : BATCH_STATUS.REVIEW.HEADING
      }`,
      amountDetail: `${
        isLanding ? groupReviewAmt.split(".")[0] : groupReviewAmt
      } ${currencyCodeText}`,
      fieldName: BATCH_STATUS.REVIEW.TITLE,
      linkTo: `groupblock/Review/${currencyType}`,
      imageClass: "icon-regular",
    },
    {
      icon: approveIcon,
      statusDetail: `${groupData?.approvalCount || 0}  ${
        groupData?.approvalCount?.toString() === "1"
          ? BATCH_STATUS.APPROVE.HEADING_APPROVE
          : BATCH_STATUS.APPROVE.HEADING
      }`,
      amountDetail: `${
        isLanding ? groupPendingAmt.split(".")[0] : groupPendingAmt
      } ${currencyCodeText}`,
      fieldName: BATCH_STATUS.APPROVE.TITLE,
      linkTo: `groupblock/Pending/${currencyType}`,
      imageClass: "icon-regular",
    },
    {
      icon: pendingFundingIcon,
      statusDetail: BATCH_STATUS.PENDING_FUNDING.HEADING,
      amountDetail: `${
        isLanding ? groupFundAmt.split(".")[0] : groupFundAmt
      } ${currencyCodeText}`,
      fieldName: BATCH_STATUS.PENDING_FUNDING.TITLE,
      linkTo: `groupblock/Fund/${currencyType}`,
      imageClass: "icon-regular",
    },
    {
      icon: totalCommissionsIcon,
      statusDetail: BATCH_STATUS.TOTAL_COMMISSION.HEADING,
      amountDetail: `${
        isLanding
          ? groupTotalCommissionAmt.split(".")[0]
          : groupTotalCommissionAmt
      } ${
        currencyType === CURRENCY_TYPE.USD
          ? CURRENCY_TYPE.USD
          : groupData?.currencyCode
      }`,
      fieldName: BATCH_STATUS.TOTAL_COMMISSION.TITLE,
      linkTo: `groupblock/All/${currencyType}`,
      imageClass: "icon-medium",
    },
  ];
  const classNames = {
    colClass: "p-0 mx-0",
    cardClass: "m-3 py-2",
    cardBodyClass: "py-4 px-0",
    divClass: "px-3",
  };
  return (
    <Container fluid className={className}>
      <div className={isLanding ? "row row-cols-2" : "row row-cols-4"}>
        {cardDetal.map((cardData, index) => {
          return (
            <CardDisplay
              key={`land-group-overview-card-${index + 1}`}
              icon={cardData.icon}
              statusDetail={cardData.statusDetail}
              amountDetail={cardData.amountDetail}
              fieldName={cardData.fieldName}
              linkTo={cardData.linkTo}
              imageClass={cardData.imageClass}
              isLinkShown={isLanding}
              classNames={isLanding ? overviewLandingClasses : classNames}
              hasMultipleCurrency={groupData?.multipleCurrency}
              currencyType={currencyType}
            />
          );
        })}
      </div>
    </Container>
  );
}
