import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ThemeProvider from "react-bootstrap/ThemeProvider";
import {
  createSession,
  fetchBatchDetails,
  getBatchJobAlert,
  getPropertyDetails,
  updateBatchJobAlert,
  usersInfoReceived,
} from "./store/feature/landing/landingSlice";

import Layout from "./Layout";
import ErrorBoundary from "./components/errorboundary/ErrorBoundary";
import ContactUs from "./container/contact_us/ContactUs";
import GroupBlock from "./container/groupblock/GroupBlock";
import GroupResearchAndAudit from "./container/groupresearchandaudit/GroupResearchAndAudit";
import Landing from "./container/landing/Landing";
import NoMatch from "./container/no-match";
import StaySummary from "./container/staysummary/StaySummary";
import Transient from "./container/transient/Transient";
import TransientTaxes from "./container/transienttaxes/TransientTaxes";
import Admin from "./container/admin/Admin";
import { buildPropertyDetails, parseJwt, validateSession } from "./utils/utils";
import Logout from "./container/logout/Logout";
import Blank from "./container/blank/Blank";
import { GROUP_EVENT_AUDIT_DAYS, USER_ACCESS_LEVEL } from "./utils/config";
import { ResizeContext } from "./utils/resizeContext";
import AlertMsgProvider from "./utils/context/alertMessageContext";
import InfoBarCollection from "./components/infoBarCollection/InfoBarCollection";

function App() {
  const dispatch = useDispatch();
  const { userInfo, propCode, logOut, currencyType } = useSelector(
    (state) => state.landing
  );

  useEffect(() => {
    dispatch(usersInfoReceived(null));
    const accessToken = validateSession();
    if (accessToken && !userInfo) {
      const userInfo_data = parseJwt(accessToken);
      dispatch(usersInfoReceived(userInfo_data));
    } else if (!accessToken) {
      dispatch(createSession());
    }
  }, []);

  const batchAlert = async () => {
    const res = await getBatchJobAlert(propCode);
    if (res.batchWindowStatus !== 0) {
      dispatch(
        updateBatchJobAlert({
          authIndicator: USER_ACCESS_LEVEL.READ_ONLY,
          batchJobRunning: true,
        })
      );
    } else {
      const accessToken = validateSession();
      const userInfo_data = parseJwt(accessToken);
      const userInfo = buildPropertyDetails(userInfo_data);
      dispatch(
        updateBatchJobAlert({
          authIndicator: userInfo.authIndicator,
          batchJobRunning: false,
        })
      );
    }
  };

  useEffect(() => {
    if (propCode && !logOut) {
      batchAlert();
    }
  }, [propCode]);

  useEffect(() => {
    let interval = setInterval(async () => {
      if (propCode && !logOut) {
        batchAlert();
      }
      if (logOut) {
        clearInterval(interval);
      }
    }, 60000);
    if (logOut) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [propCode, logOut]);

  useEffect(() => {
    if (propCode?.length) {
      dispatch(getPropertyDetails(propCode));
    }
  }, [propCode]);

  useEffect(() => {
    if (userInfo && propCode?.length) {
      const overviewReqBody = {
        currencyType: currencyType.toLowerCase(),
        type: "all",
        filterBy: {
          gsaRangeDays: GROUP_EVENT_AUDIT_DAYS,
        },
      };
      dispatch(fetchBatchDetails(propCode, overviewReqBody));
    }
  }, [userInfo, propCode]);

  const adminRoute =
    userInfo?.admin || userInfo?.ipc
      ? [{ path: "admin", element: <Admin /> }]
      : [];

  const routeForLogin = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: userInfo ? true : false,
          element: <Landing />,
        },
        {
          path: "groupblock",
          children: [
            {
              index: true,
              element: <GroupBlock />,
            },
            {
              path: ":type/:currencyCode",
              element: <GroupBlock />,
            },
            {
              path: "groupstaysummary/:propertyCode/:bNum1/:bNum2",
              element: <StaySummary />,
            },
          ],
        },
        {
          path: "transient",
          children: [
            {
              index: true,
              element: <Transient />,
            },
            {
              path: ":type/:currencyCode",
              element: <Transient />,
            },
            {
              path: "transientstaysummary/:propertyCode/:bNum1/:bNum2",
              element: <StaySummary />,
            },
          ],
        },
        {
          path: "transienttaxes",
          element: <TransientTaxes />,
        },
        {
          path: "group_research_audit",
          element: <GroupResearchAndAudit />,
        },
        {
          path: "*",
          index: !userInfo ? true : false,
          element: <NoMatch />,
        },
        {
          path: "contactus",
          element: <ContactUs />,
        },
        ...adminRoute,
      ],
    },
  ]);
  const [contextCMT, setContextCMT] = useState({ tableWidth: 0 });

  return (
    <div className="app">
      <ErrorBoundary>
        <ResizeContext.Provider value={{ contextCMT, setContextCMT }}>
          <AlertMsgProvider>
            <InfoBarCollection />
            <ThemeProvider
              breakpoints={["xxxl", "xxl", "xl", "lg"]}
              minBreakpoint="lg"
            >
              <RouterProvider router={routeForLogin} />
            </ThemeProvider>
          </AlertMsgProvider>
        </ResizeContext.Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
