import { CURRENCY_TYPE } from "./constants";
import { formatDate, getTransientStatusBG } from "./utils";

export const renderCellPropAmount = (row, currencyType) => {
  const multiCurrency = row?.original?.multipleCurrency;
  let amount = row?.original?.propertyAmount;
  if (multiCurrency) amount = "NA";
  else if (currencyType === CURRENCY_TYPE.USD)
    amount = row?.original?.propertyAmountUsd;
  return <div className="align-right">{amount}</div>;
};

export const renderCellAmount = (row, key, currencyType) => {
  const multiCurrency = row?.original?.multipleCurrency;
  let amount =
    currencyType === CURRENCY_TYPE.USD
      ? row?.original[`${key}Usd`]
      : row?.original[key];
  if (multiCurrency) amount = "NA";
  return <div className="align-right">{amount}</div>;
};

export const renderDateCell = (row, key) => {
  const date = row?.original[key] || "";
  let pattern = new RegExp("^[a-zA-Z ]+$");
  return (
    <span>
      {date
        ? pattern.test(date)
          ? date.toUpperCase()
          : formatDate(date, "DDMMMYY").toUpperCase()
        : ""}
    </span>
  );
};

export const renderCellBatchID = ({ row }) => {
  const batchNum1 =
    row.original && row.original.batchNum1
      ? row.original?.batchNum1.padStart(4, "0")
      : null;
  const batchNum2 =
    row.original && row.original?.batchNum2
      ? row.original?.batchNum2.padStart(4, "0")
      : null;
  return <span>{row?.original?.batchType + batchNum1 + batchNum2}</span>;
};

export const renderCellBatchStatus = ({ row }) => (
  <div
    className={`table-button font-12  
                ${getTransientStatusBG(
                  row?.original?.autoApprovalDate,
                  row?.original?.batchStatus
                )}`}
  >
    {row?.original?.batchStatus || ""}
  </div>
);
