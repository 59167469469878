/* eslint-disable react-hooks/exhaustive-deps */
import "./transienttaxes.scss";
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import TransientTaxFilter from "./component/transienttaxfilter/TransientTaxFilter";
import DataTableServerSide from "../../components/datatableserverside/DataTableServerSide";
import {
  fetchTransientTaxOverview,
  updateTransientTaxParams,
  fetchTransientTaxBlock,
  updateTTaxStatus,
  tTaxBatchActionSuccess,
  tTaxBatchActionFailed,
} from "../../store/feature/transienttax/transientTaxSlice";
import TransientTaxOverview from "./TransientTaxOverview";
import Pagination from "../../components/pagination/Pagination";
import CurrencyButton from "../../components/currencybutton/CurrencyButton";
import {
  getDownloadFile,
  updateCurrancy,
} from "../../store/feature/landing/landingSlice";
import LoadingBar from "../../components/loadingbar/LoadingBar";
import {
  TRANSIENT_TAX,
  PROPERTY_CODE,
  STATUS,
  TT_TABLE_HEADER_IATA,
  TT_TABLE_HEADER_GUEST_NAME,
  TT_TABLE_HEADER_CONFIRMATION,
  TT_TABLE_HEADER_DEPARTURE_DATE,
  TT_TABLE_HEADER_ARRIVAL_DATE,
  TT_TABLE_HEADER_ROOM_NIGHTS,
  TT_TABLE_HEADER_RATE,
  TT_TABLE_HEADER_REVENUE,
  LANDING_NO_DATA_FOUND,
  DOWNLOAD_EXPORT_LABEL,
  TT_ACTION_BAR_BTN_NAME,
  TT_TABLE_STAY_SELECTED,
  TRANSIENT_TAX_CONTENT,
  ONYX_TEXT,
  TT_RELEASE_SUCCESS_MSG,
  TT_UNRELEASE_SUCCESS_MSG_START,
  TT_UNRELEASE_SUCCESS_MSG_MIDDLE,
  TT_UNRELEASE_SUCCESS_MSG_END,
  CURRENCY,
  CURRENCY_TYPE,
} from "../../utils/constants";
import {
  CURRENCY_CONST_PROPERTY,
  CURRENCY_CONST_USD,
  ONYX_LINK,
  TT_DEFAULT_TABLE_PAYLOAD,
  TT_STATUS_NAME,
  TTAX_STATUS_OPEN,
  TTAX_STATUS_PENDING,
  TT_TABLE_RESET_FILTERBY_PAYLOAD,
  USER_ACCESS_LEVEL,
} from "../../utils/config";
import {
  buildExportURL,
  formatDate,
  getTransientTaxStatusBG,
} from "../../utils/utils";
import ActionBar from "../../components/actionbar/ActionBar";
import { Button } from "react-bootstrap";
import InfoBar from "../../components/infobar/InfoBar";
import ErrorText from "../../components/ErrorText";
import { API_URL } from "../../utils/Api_URL";
import { useAlertMsg } from "../../utils/context/alertMessageContext";

export default function TransientTaxes() {
  const { propCode, userInfo, currencyType, propertyDetails } = useSelector(
    (state) => state.landing
  );
  const {
    transientTaxOverview,
    payloadForTable,
    taxDetail,
    transientTaxTotalCount,
    showTaxLoader,
    taxServerError,
    taxOverviewInternalError,
    taxTableInternalError,
    tTaxStatusUpdateSuccess,
    tTaxStatusUpdateFailed,
  } = useSelector((state) => state.transientTax);
  const {
    alertContextState: { transTaxUpdateSuccess, transTaxUpdateError },
    alertContextDispatch,
  } = useAlertMsg();
  const { currencyCode } = useParams();
  const dispatch = useDispatch();
  const [transientTaxStateData, setTransientTaxStateData] = useState([]);
  const [isSelectAllDisabled, setIsSelectAllDisabled] = useState(false);
  const [selectedRowStatus, setSelectedRowStatus] = useState("");
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [resetSelection, setResetSelection] = useState(false);
  const [selectedRows, setSelectedRows] = useState([{}]);
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    return () => {
      alertContextDispatch({
        type: "transTaxServerErrorHide",
      });

      alertContextDispatch({
        type: "transTaxUpdateSuccessHide",
      });

      alertContextDispatch({
        type: "transTaxUpdateErrorHide",
      });
    };
  }, []);

  const makeData = (transientTaxData, rowSelection) => {
    if (rowSelection?.length > 0) {
      setSelectedRowStatus(rowSelection[0]?.status);
      return transientTaxData.map((data) => ({
        ...data,
        isDisabled: data.status !== rowSelection[0]?.status,
      }));
    }
    return transientTaxData.map((data) => ({
      ...data,
      isDisabled:
        userInfo?.authIndicator <= USER_ACCESS_LEVEL.READ_ONLY
          ? true
          : data.status !== TT_STATUS_NAME.OPEN &&
            data.status !== TT_STATUS_NAME.PENDING
          ? true
          : false,
    }));
  };

  const selectedRowCallback = (data) => {
    setSelectedRowCount(data?.length);
    setSelectedRows(data);
    const makeDataOp = makeData(transientTaxStateData, data);
    setTransientTaxStateData(makeDataOp);
  };

  const checkIsSelectAllDisabled = (tTaxDetail) => {
    let tTaxArray = tTaxDetail.map((item) => {
      return item.status;
    });
    const noOfUniqueStatuses = [...new Set(tTaxArray)].length;
    if (
      userInfo?.authIndicator <= USER_ACCESS_LEVEL.READ_ONLY ||
      noOfUniqueStatuses > 1
    ) {
      return true;
    } else if (
      !(
        tTaxArray?.includes(TT_STATUS_NAME.OPEN) ||
        tTaxArray?.includes(TT_STATUS_NAME.PENDING)
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setResetSelection(!resetSelection);
    const makeDataOutput = makeData(taxDetail, selectedRows);
    const isSelectAllDisabled = checkIsSelectAllDisabled(taxDetail);
    setIsSelectAllDisabled(isSelectAllDisabled);
    setTransientTaxStateData(makeDataOutput);
  }, [taxDetail]);

  const renderGuestNameCell = ({ row }) => (
    <div className="guest-name">{row.original.guestName}</div>
  );

  const renderRoomRateCell = (row, currencyType) => (
    <div className="align-right">
      {currencyType === CURRENCY_TYPE.USD
        ? row.original.roomRateUsd
        : row.original.roomRate}
    </div>
  );

  const renderRoomRevenueCell = (row, currencyType) => (
    <div className="align-right">
      {currencyType === CURRENCY_TYPE.USD
        ? row.original.roomRevenueUsd
        : row.original.roomRevenue}
    </div>
  );

  const renderStatusCell = ({ row }) => (
    <div
      className={`table-button font-12 text-center
          ${getTransientTaxStatusBG(row?.original?.status)}`}
    >
      {row?.original?.status || ""}
    </div>
  );

  const column = useMemo(
    () => [
      {
        accessorKey: "propertyCode",
        header: () => PROPERTY_CODE,
        width: 100,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "iata",
        header: () => TT_TABLE_HEADER_IATA,
        width: 80,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "guestName",
        header: () => TT_TABLE_HEADER_GUEST_NAME,
        footer: (props) => props.column.id,
        width: 180,
        cell: renderGuestNameCell,
      },
      {
        accessorKey: "confirmationNumber",
        header: () => TT_TABLE_HEADER_CONFIRMATION,
        width: 110,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "arrivalDate",
        header: () => TT_TABLE_HEADER_ARRIVAL_DATE,
        width: 80,
        footer: (props) => props.column.id,
        cell: ({ row }) =>
          row?.original?.arrivalDate
            ? formatDate(row?.original?.arrivalDate, "DDMMMYY").toUpperCase()
            : null,
      },
      {
        accessorKey: "departureDate",
        header: () => TT_TABLE_HEADER_DEPARTURE_DATE,
        width: 80,
        footer: (props) => props.column.id,
        cell: ({ row }) =>
          row?.original?.departureDate
            ? formatDate(row?.original?.departureDate, "DDMMMYY").toUpperCase()
            : null,
      },
      {
        accessorKey: "roomNights",
        header: () => TT_TABLE_HEADER_ROOM_NIGHTS,
        width: 60,
        sortDescFirst: false,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "roomRate",
        header: () => TT_TABLE_HEADER_RATE,
        width: 95,
        className: "header_right_align",
        footer: (props) => props.column.id,
        cell: ({ row }) => renderRoomRateCell(row, currencyType),
      },
      {
        accessorKey: "roomRevenue",
        header: () => TT_TABLE_HEADER_REVENUE,
        className: "header_right_align",
        width: 95,
        footer: (props) => props.column.id,
        cell: ({ row }) => renderRoomRevenueCell(row, currencyType),
      },
      {
        accessorKey: "currency",
        header: () => CURRENCY,
        footer: (props) => props.column.id,
        width: 80,
        cell: ({ row }) => (
          <span>
            {currencyType === CURRENCY_TYPE.USD
              ? CURRENCY_TYPE.USD
              : row.original.currency}
          </span>
        ),
      },
      {
        accessorKey: "status",
        header: () => STATUS,
        width: 100,
        cell: renderStatusCell,
        footer: (props) => props.column.id,
      },
    ],
    [currencyType]
  );
  const applySort = (sortBy) => {
    // sortBy = sortBy || TT_DEFAULT_TABLE_PAYLOAD.sortBy;
    if (sortBy) {
      updateTTListParam({ sortBy: sortBy });
    }
  };

  const updateTTListParam = (params) => {
    dispatch(updateTransientTaxParams({ ...payloadForTable, ...params }));
  };
  let taxOverviewTimer;
  const refreshTTOverviewData = () => {
    const transientTaxOverviewReqBody = {
      currencyType: currencyType,
      type: TRANSIENT_TAX,
      filterBy: payloadForTable.filterBy,
    };
    dispatch(fetchTransientTaxOverview(propCode, transientTaxOverviewReqBody));
  };
  useEffect(() => {
    if (taxOverviewTimer) {
      clearTimeout(taxOverviewTimer);
    }
    taxOverviewTimer = setTimeout(() => {
      refreshTTOverviewData();
    }, 50);

    return () => {
      if (taxOverviewTimer) {
        clearTimeout(taxOverviewTimer);
      }
    };
  }, [propCode, payloadForTable.filterBy]);

  let taxTableTimer;

  useEffect(() => {
    if (!currencyCode) {
      dispatch(updateCurrancy(CURRENCY_CONST_PROPERTY));
    } else if (currencyCode === CURRENCY_CONST_USD) {
      dispatch(updateCurrancy(CURRENCY_CONST_USD));
    }
  }, [propCode]);

  const refreshTTTableData = () => {
    let tablePayload = { ...payloadForTable, currencyType: currencyType };
    if (propCode) {
      dispatch(fetchTransientTaxBlock({ tablePayload, propCode }));
      if (taxTableTimer) {
        clearTimeout(taxTableTimer);
      }
    }
  };

  useEffect(() => {
    if (taxTableTimer) {
      clearTimeout(taxTableTimer);
    }
    taxTableTimer = setTimeout(() => {
      refreshTTTableData();
    }, 50);
    return () => {
      if (taxTableTimer) {
        clearTimeout(taxTableTimer);
      }
    };
  }, [payloadForTable, propCode, userInfo]);

  useEffect(() => {
    updateTTListParam({ ...TT_DEFAULT_TABLE_PAYLOAD });
  }, []);

  useEffect(() => {
    if (tTaxStatusUpdateSuccess) {
      const actualCount =
        Number(transientTaxTotalCount) - Number(selectedRowCount);
      const pageNumber =
        Number(payloadForTable.pageNumber) > 1
          ? Number(payloadForTable.pageNumber) - 1
          : 1;
      const expectedCount = pageNumber * Number(payloadForTable.pageSize);
      // logic to reset pagination if total record lessthan pagesize
      if (
        Number(payloadForTable.pageNumber) > 1 &&
        actualCount <= expectedCount
      ) {
        updateTTListParam({ ...payloadForTable, pageNumber: 1 });
        refreshTTOverviewData();
      } else {
        refreshTTOverviewData();
        refreshTTTableData();
      }
    }
  }, [tTaxStatusUpdateSuccess, tTaxStatusUpdateFailed]);

  const applyFilter = (filterBy) => {
    let filterByReceived = { ...filterBy };
    if (
      "status" in filterByReceived &&
      filterByReceived.status[0] === undefined
    ) {
      filterByReceived.status = [];
    }
    updateTTListParam({
      filterBy: {
        ...TT_TABLE_RESET_FILTERBY_PAYLOAD,
        ...filterByReceived,
      },
      pageNumber: 1,
    });
  };

  const buildTTaxPayload = (selRows, userId) => {
    const taxStatusDetails = selRows
      ? selRows.map(({ sequenceNumber, propertyCode }) => {
          return { sequenceNumber, propertyCode };
        })
      : [];

    return { userId, taxStatusDetails };
  };

  const actionHandler = (actionStatus) => {
    if (actionStatus) {
      hideReleaseUnreleaseSuccess();
      hideReleaseUnreleaseError();
      setSelectedStatus(actionStatus);
      const updateTTReqBody = buildTTaxPayload(selectedRows, userInfo?.EID);
      dispatch(updateTTaxStatus(actionStatus, updateTTReqBody));
    }
  };

  const onCancelClick = () => {
    setResetSelection(!resetSelection);
  };

  const downloadXLS = () => {
    let fileURL = buildExportURL({
      url: `${API_URL.GET_TRANSIENT_TAX_DETAILS_EXPORT}/${propCode}`,
      filterBy: payloadForTable.filterBy,
      currencyType: currencyType,
    });
    const fileName = `Transient Tax-${propCode}-${formatDate(
      new Date(),
      "DDMMMYY"
    )}`;
    dispatch(getDownloadFile(fileURL, fileName));
  };

  const hideReleaseUnreleaseSuccess = () => {
    dispatch(tTaxBatchActionSuccess(false));
  };

  useEffect(() => {
    if (!transTaxUpdateSuccess.show) {
      hideReleaseUnreleaseSuccess();
    }
  }, [transTaxUpdateSuccess]);

  useEffect(() => {
    if (taxServerError || taxOverviewInternalError) {
      alertContextDispatch({
        type: "transTaxServerErrorShow",
      });
    }
  }, [taxServerError || taxOverviewInternalError]);

  useEffect(() => {
    if (tTaxStatusUpdateSuccess) {
      alertContextDispatch({
        type: "transTaxUpdateSuccessShow",
        payload: { selectedStatus },
      });
    }
  }, [tTaxStatusUpdateSuccess]);

  useEffect(() => {
    if (tTaxStatusUpdateFailed) {
      alertContextDispatch({
        type: "transTaxUpdateErrorShow",
      });
    }
  }, [tTaxStatusUpdateFailed]);

  const hideReleaseUnreleaseError = () => {
    dispatch(tTaxBatchActionFailed(false));
  };

  useEffect(() => {
    if (!transTaxUpdateError.show) {
      hideReleaseUnreleaseError();
    }
  }, [transTaxUpdateError]);

  return (
    <div>
      {transientTaxOverview &&
      transientTaxOverview !== null &&
      Object.keys(transientTaxOverview).length ? (
        <TransientTaxOverview
          overviewData={transientTaxOverview}
          hasMultipleCurrency={
            transientTaxOverview?.transientTax?.multipleCurrency
          }
          currencyType={currencyType}
          className={"tt_summery_card"}
        />
      ) : (
        <p className="mt-3 ms-2"> {LANDING_NO_DATA_FOUND} </p>
      )}
      <p className="mt-4">
        {TRANSIENT_TAX_CONTENT}
        <a
          target="_blank"
          className="view-stays-link"
          rel="noopener noreferrer"
          href={ONYX_LINK}
        >
          {ONYX_TEXT}
        </a>
      </p>
      <TransientTaxFilter filterCallback={applyFilter} />
      <hr />
      <div className="d-flex flex-row-reverse ms-1 mb-4" id="tax-table">
        <div className="transient-currency">
          <CurrencyButton
            showUSD={propertyDetails.currencyCode !== CURRENCY_TYPE.USD}
          />
        </div>
        <button
          onClick={downloadXLS}
          className="download-btn btn btn-link text-decoration-none font-12 mt-auto py-0"
        >
          <i className="download-icon" /> <span>{DOWNLOAD_EXPORT_LABEL}</span>
        </button>
        {taxDetail?.length ? (
          <Pagination
            className="pagination-bar"
            showOnlyInfo={true}
            currentPage={payloadForTable.pageNumber}
            totalCount={transientTaxTotalCount}
            pageSize={payloadForTable.pageSize}
          />
        ) : null}
      </div>
      <ActionBar
        showActionBar={selectedRowCount !== 0}
        message={`${selectedRowCount} ${TT_TABLE_STAY_SELECTED}`}
      >
        <div className=" bd-highlight">
          {selectedRowStatus && selectedRowStatus === TT_STATUS_NAME?.OPEN ? (
            <Button
              className="action-btn weight-400"
              onClick={() => actionHandler(TTAX_STATUS_OPEN)}
              variant="outline-success"
            >
              {TT_ACTION_BAR_BTN_NAME?.RELEASE_TO_BATCH}
            </Button>
          ) : (
            <Button
              className="action-btn weight-400"
              onClick={() => actionHandler(TTAX_STATUS_PENDING)}
              variant="outline-success"
            >
              {TT_ACTION_BAR_BTN_NAME?.UNRELEASE}
            </Button>
          )}
          <Button
            className="btn-link action-cancel-btn p-0"
            onClick={() => onCancelClick()}
            variant="outline-success"
          >
            {TT_ACTION_BAR_BTN_NAME.CANCEL}
          </Button>
        </div>
      </ActionBar>
      {showTaxLoader ? <LoadingBar global /> : null}
      <DataTableServerSide
        data={[...transientTaxStateData]}
        column={column}
        sortCallback={applySort}
        enableCheckboxSelection={true}
        selectedRowCallback={selectedRowCallback}
        isSelectAllDisabled={isSelectAllDisabled}
        resetSelection={resetSelection}
        fixedWidthColumn={true}
      ></DataTableServerSide>
      {taxDetail?.length ? (
        <div>
          <Pagination
            className="pagination-bar"
            currentPage={Number(payloadForTable.pageNumber)}
            totalCount={transientTaxTotalCount}
            pageSize={payloadForTable.pageSize}
            onItemPerPageChange={(pageSize, resetPage) => {
              const element = document.getElementById("tax-table");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
              if (resetPage) {
                updateTTListParam({
                  pageNumber: resetPage,
                  pageSize,
                });
              } else {
                updateTTListParam({
                  pageNumber: payloadForTable.pageNumber,
                  pageSize,
                });
              }
            }}
            onPageChange={(page) => {
              const element = document.getElementById("tax-table");
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                });
              }
              updateTTListParam({
                pageNumber: page,
                pageSize: payloadForTable.pageSize,
              });
            }}
          />
        </div>
      ) : null}
      {taxDetail.length === 0 ? (
        <p className="ms-2">{LANDING_NO_DATA_FOUND}</p>
      ) : null}
    </div>
  );
}
