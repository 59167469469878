/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useId } from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import { PAGINATION_SIZE_OPTION } from "../../utils/config";
import {
  PAGINATION_LABEL_ITEMS_PER_PAGE,
  PAGINATION_LABEL_OF,
  PAGINATION_LABEL_RESULTS,
  PAGINATION_LABEL_SHOWING,
} from "../../utils/constants";

const Pagination = (props) => {
  const {
    onPageChange,
    onItemPerPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize = 25,
    className,
    pageinationInfoType = PAGINATION_LABEL_RESULTS,
    showOnlyInfo = false,
  } = props;
  const uniqueId = useId();
  const pageSizeOption = PAGINATION_SIZE_OPTION;

  const [pageInfo, setPageInfo] = useState({});

  const updatePageInfo = () => {
    const startRecord = pageSize * currentPage + 1 - pageSize;
    let endRecord =
      totalCount < pageSize / currentPage ? totalCount : pageSize * currentPage;
    endRecord = endRecord < totalCount ? endRecord : totalCount;
    const totalRecord = totalCount;
    setPageInfo({ startRecord, endRecord, totalRecord });
  };

  useEffect(() => {
    updatePageInfo();
  }, [pageSize, currentPage, totalCount]);

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const updatePageSize = (event) => {
    const pageSizeChanged = Number(event.target.value);
    onItemPerPageChange(pageSizeChanged, 1);
  };

  let lastPage = paginationRange
    ? paginationRange[paginationRange.length - 1]
    : 0;

  if (showOnlyInfo) {
    return (
      <div className="pagination-info">
        <span className="font-12 weight-400">{`${PAGINATION_LABEL_SHOWING} ${pageInfo.startRecord} - ${pageInfo.endRecord} ${PAGINATION_LABEL_OF} ${pageInfo.totalRecord} ${pageinationInfoType}`}</span>
      </div>
    );
  } else {
    return (
      <>
        <div className="d-flex pagination_comp">
          <span className="page_size pe-3">
            <label>{PAGINATION_LABEL_ITEMS_PER_PAGE}</label>
            <span className="form-item mx-2">
              <select value={pageSize} onChange={updatePageSize}>
                {pageSizeOption.map((pageOption) => {
                  return (
                    <option key={`page-option-${pageOption}-${uniqueId}`}>
                      {pageOption}
                    </option>
                  );
                })}
              </select>
            </span>
          </span>
          <span
            key={`pagination-span-${uniqueId}`}
            className="page_info"
          >{`${PAGINATION_LABEL_SHOWING} ${pageInfo.startRecord} - ${pageInfo.endRecord} ${PAGINATION_LABEL_OF} ${pageInfo.totalRecord} ${pageinationInfoType}`}</span>
          <ul
            className={classnames("pagination-container", {
              [className]: className,
            })}
            key={`pagination-ul-${uniqueId}`}
          >
            <li
              className={classnames("pagination-item", {
                disabled: currentPage === 1,
              })}
              onClick={onPrevious}
              key={`pagination-li-${uniqueId}`}
            >
              <div className="arrow left" />
            </li>
            {paginationRange?.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <li
                    key={`pagination-page-${index + 1}-${uniqueId}`}
                    className="pagination-item dots"
                  >
                    &#8230;
                  </li>
                );
              }

              return (
                <>
                  {pageNumber === currentPage ? (
                    <li
                      key={`page-active-${uniqueId}`}
                      className={classnames("pagination-item", "selected")}
                    >
                      {pageNumber}
                    </li>
                  ) : (
                    <li
                      key={`page-active-${uniqueId}`}
                      className={classnames("pagination-item")}
                      onClick={() => onPageChange(pageNumber)}
                    >
                      {pageNumber}
                    </li>
                  )}
                </>
              );
            })}
            <li
              className={classnames("pagination-item", {
                disabled: currentPage === lastPage,
              })}
              key={`page-last-${uniqueId}`}
              onClick={onNext}
            >
              <div className="arrow right" />
            </li>
          </ul>
        </div>
      </>
    );
  }
};

export default Pagination;
