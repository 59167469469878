import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import {
  openBroadcastMsg,
  userLogout,
  usersInfoReceived,
} from "../../store/feature/landing/landingSlice";
import {
  HEADER_COMMISSION,
  HEADER_AUDIT,
  HEADER_GROUP_BLOCKS,
  HEADER_TRANSIENT_BATCHES,
  HEADER_TRANSIENT_TAXES,
  LANDING_LABELS,
  BATCH_SCHEDULED_MSG,
} from "../../utils/constants";
import { clearSession, removeHtml } from "../../utils/utils";

export default function Header({ activeRoute }) {
  const { userInfo, logOut, groupBatch, batchJobRunning } = useSelector(
    (state) => state.landing
  );
  const { broadcastMsg } = useSelector((state) => state.admin);
  const [commissionIsOpen, setCommissionIsOpen] = useState(false);
  const [profileIsOpen, setProfileIsOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const removedHtmlResult = broadcastMsg?.message
    ? removeHtml(broadcastMsg.message)
    : 0;
  const openAlerts = () => {
    dispatch(openBroadcastMsg());
  };
  const handleCommisionToggle = () => {
    setCommissionIsOpen(!commissionIsOpen);
  };
  const handleProfileToggle = () => {
    setProfileIsOpen(!profileIsOpen);
  };
  const handleLogOut = () => {
    clearSession();
    dispatch(usersInfoReceived(null));
    dispatch(userLogout());
  };
  return (
    <>
      {batchJobRunning ? (
        <div className="orange-bar p-2 text-center">
          <span>{BATCH_SCHEDULED_MSG}</span>
        </div>
      ) : null}
      <Navbar bg="light" expand="lg" className="app_header py-0">
        <Container fluid className="px-0 app_container">
          <Navbar.Brand className="bg-lightblack p-0 m-0">
            {logOut ? (
              <span className={"landing-logo-text"}>
                <span className="weight-700">{LANDING_LABELS.HEADER_MI}</span>{" "}
                {LANDING_LABELS.HEADER_COMMISSION_TOOL}
              </span>
            ) : (
              <NavLink to="/" className={"landing-logo-text"}>
                <span className="weight-700">{LANDING_LABELS.HEADER_MI}</span>{" "}
                {LANDING_LABELS.HEADER_COMMISSION_TOOL}
              </NavLink>
            )}
          </Navbar.Brand>
          {!logOut ? (
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <NavDropdown
                    onToggle={handleCommisionToggle}
                    title={
                      <>
                        <i className="commission-icon" />
                        {HEADER_COMMISSION}
                      </>
                    }
                    id="basic-nav-dropdown"
                    className={"font-20 " + (commissionIsOpen ? "bg-dark" : "")}
                  >
                    <NavDropdown.Item as="div" className="p-0 m-0">
                      <NavLink className="menu-item" to="/transient">
                        {HEADER_TRANSIENT_BATCHES}
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item as="div" className="p-0 m-0">
                      <NavLink className="menu-item" to="/transienttaxes">
                        {HEADER_TRANSIENT_TAXES}
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item as="div" className="p-0 m-0">
                      <NavLink className="menu-item" to="/groupblock">
                        {HEADER_GROUP_BLOCKS}
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item as="div" className="p-0 m-0">
                      <NavLink className="menu-item" to="/group_research_audit">
                        {HEADER_AUDIT}
                      </NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavLink to="/contactus" className="nav-link font-20">
                    <i className="contact-icon" />
                    {LANDING_LABELS.HEADER_CONTACT_US}
                  </NavLink>
                  {userInfo?.admin || userInfo?.ipc ? (
                    <NavLink to="/admin" className="nav-link font-20">
                      {"Admin"}
                    </NavLink>
                  ) : null}
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end">
                <Nav>
                  {!activeRoute && removedHtmlResult.length ? (
                    <Nav.Link onClick={openAlerts}>
                      <i className="alert-icon" />
                      <span>{LANDING_LABELS.HEADER_ALERTS}</span>
                    </Nav.Link>
                  ) : null}
                  <NavDropdown
                    onToggle={handleProfileToggle}
                    title={
                      <span className="user_text_overflow">
                        <i className="profile-icon" />
                        {`Hello, ${userInfo?.userFullName}`}
                      </span>
                    }
                    id="nav-dropdown-userlogin"
                    className={
                      "font-16 user-profile " + (profileIsOpen ? "bg-dark" : "")
                    }
                  >
                    <NavDropdown.Item
                      className="font-16 logout"
                      onClick={handleLogOut}
                    >
                      {LANDING_LABELS.HEADER_LOGOUT}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </>
          ) : null}
        </Container>
      </Navbar>
    </>
  );
}
