import { CURRENCY_CONST_USD } from "../../utils/config";
import { NOT_APPLICABLE } from "../../utils/constants";

export default function AmountDisplay({
  amountDetail,
  hasMultipleCurrency,
  currencyType,
}) {
  if (hasMultipleCurrency && currencyType !== CURRENCY_CONST_USD) {
    return <span>{NOT_APPLICABLE}</span>;
  } else {
    return <span>{amountDetail}</span>;
  }
}
