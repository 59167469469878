import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      //errorMessage: { info: TECHNICAL_ERROR },
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div className="container">
            <div className="text-center m-5 error_boundary">
              <h2 className="error_boundary_title">
                MI CMT is unable to process your request
              </h2>
              <p className="error_boundary_body dotted_line">
                We could not process your request due to a technical issue.
                Please close your browser and try again.
              </p>
              <p className="error_boundary_body">
                If the problem persists, please open a SNOW ticket using the{" "}
                <a href="https://marriott.service-now.com/msp">
                  Marriott Service Portal
                </a>{" "}
                (search for “CTAC” and select CTAC-HELP-DESK-Support) or contact
                the CTAC Help Desk by{" "}
                <a href="mailto:ctac.help@marriott.com">email</a>.
              </p>
            </div>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
