import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./loadingbar.scss";

export default function LoadingBar({ global }) {
  return (
    <div className={`loading-bar-container ${global ? "global" : ""}`}>
      <div className="loading-bar">
        <Spinner animation="border" variant="secondary" />
      </div>
    </div>
  );
}
