import Modal from "react-bootstrap/Modal";
import "./ModalDialog.scss";

function ModalDialog(props) {
  const {
    show,
    modalHeading = "",
    handleOnShowClose,
    size,
    ariaLabelledby,
    className,
    closeButton = true,
  } = props;

  const headerClass = modalHeading.length
    ? {}
    : { className: "only_close_btn" };
  return (
    <Modal
      show={show}
      onHide={handleOnShowClose}
      size={size}
      aria-labelledby={ariaLabelledby}
      dialogClassName={`${className} modal-90w`}
      centered
    >
      <Modal.Header closeButton={closeButton} {...headerClass}>
        {modalHeading.length ? modalHeading : ""}
      </Modal.Header>

      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
}

export default ModalDialog;
