import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../../utils/Api_URL";
import {
  IATA_API_ERROR,
  IATA_ERROR,
  TT_IATA_INACTIVE_ERROR,
  STAY_SAVE_DELETE_SUCCESS,
  STAY_EDITED,
} from "../../../utils/constants";
import { validateIsQ50 } from "../../../utils/utils";
import {
  DEFAULT_REVIEW_FILTER,
  INACTIVE_IATA_INDICATOR,
  ADD_STAY_SAVE_RESET,
  VOID_RESERVATION,
  ADD_STAY_REVIEW_RESET,
  REVIEW_STAY_ACTION_DETAILS,
} from "../../../utils/config";

const initialState = {
  noPaySaveFailedFlag: "",
  noPaySaveSuccessFlag: false,
  showStaySummaryLoader: false,
  staySummaryOverview: {},
  serverError: false,
  overviewInternalError: false,
  showStaySummaryDetailLoader: false,
  stayBatchDetail: {},
  staySummaryDetail: [],
  staySummaryDetailTotalCount: 1,
  staySummaryDetailInternalError: false,
  batchTableInternalError: false,
  stayTablePayload: {
    filterBy: {
      iataNumber: "",
      confoNumber: "",
      marketSegment: [],
      noPayReason: [],
      folioIndicator: [],
      invoiceIndicator: "",
      guestName: "",
      arrivalDate: "",
      adjustCode: [],
      modIndicator: [],
      marketCode: "",
    },
    sortBy: {
      sortColumn: "",
      sortValue: "",
    },
    batchNum1: "",
    batchNum2: "",
    pageNumber: 1,
    pageSize: "25",
    currencyType: "property",
  },
  batchTablePayload: {
    filterBy: {
      month: "",
      modIndicator: [],
      status: [...DEFAULT_REVIEW_FILTER],
      batchId: "",
      group: false,
    },
    sortBy: {
      sortColumn: "",
      sortValue: "",
    },
    pageNumber: 1,
    pageSize: "25",
    currencyType: "property",
  },
  iataUpdateFlag: false,
  isQ50Prop: false,
  travelAgencyName: "",
  filterOptions: {},
  noPayModifiedSaveData: {},
  payloadForAddStaySave: {
    userId: "",
    propertyCode: "",
    transientStay: true,
    userInitials: "",
    reviewStayActionDetails: {
      ...REVIEW_STAY_ACTION_DETAILS,
    },
    iataDetail: {},
    propDetail: {},
  },
  payloadForAddStayReview: {
    iataDetail: {},
    propDetail: {},
    iataNumber: "",
    confirmationNumber: "",
    guestName: "",
    arrivalDate: "",
    ratePlan: "",
    corporateId: "",
    marketCode: "",
    noPayReason: "",
    adjustCode: "",
    roomRateCtac: "",
    roomNights: "",
    propertyCode: "",
    batchNum1: "",
    batchNum2: "",
    bookedCurrency: "",
    propertyCurrency: "",
    bookedPrecision: 0,
    propertyPrecision: 0,
    marketPrefix: "",
    bookingSource: "",
    bookingDate: "",
    maxRoomNightsAllowed: 0,
    roomRateTax: 0,
    skipError: false,
    transientStay: true,
    suspenseIndicator: "",
    stayAction: 1,
    userInitials: "",
    userId: "",
  },
  payloadForEditSave: {
    userId: "",
    propertyCode: "",
    transientStay: true,
    userInitials: "",
    reviewStayActionDetails: {
      ...REVIEW_STAY_ACTION_DETAILS,
    },
    iataDetail: {},
    propDetail: {},
  },
  payloadForEditReview: {
    iataDetail: {},
    propDetail: {},
    iataNumber: "",
    confirmationNumber: "",
    guestName: "",
    arrivalDate: "",
    ratePlan: "",
    corporateId: "",
    marketCode: "",
    noPayReason: "",
    adjustCode: "",
    roomRateCtac: "",
    roomNights: "",
    propertyCode: "",
    batchNum1: "",
    batchNum2: "",
    bookedCurrency: "",
    propertyCurrency: "",
    bookedPrecision: 0,
    propertyPrecision: 0,
    marketPrefix: "",
    bookingSource: "",
    bookingDate: "",
    maxRoomNightsAllowed: 0,
    roomRateTax: 0,
    skipError: false,
    transientStay: true,
    suspenseIndicator: "",
    stayAction: 2,
    userInitials: "",
    userId: "",
  },
  addStaySaveStatus: {
    success: false,
    apiError: false,
    error: false,
    taxError: false,
    maxRoomRateError: false,
    message: "",
  },
  editStaySaveStatus: {
    success: false,
    apiError: false,
    error: false,
    taxError: false,
    maxRoomRateError: false,
    message: "",
  },
  masterData: {},
  propertyDetails: {},
  addStayReviewData: {},
  addStayReviewMode: false,
  addStayReviewStatus: {
    reviewStayActionDetails: {
      ...REVIEW_STAY_ACTION_DETAILS,
    },
    success: false,
    message: "",
    apiError: false,
  },
  editStayReviewMode: false,
  editStayReviewStatus: {
    reviewStayActionDetails: {
      ...REVIEW_STAY_ACTION_DETAILS,
    },
    success: false,
    message: "",
    apiError: false,
  },
  linkStayDetails: [],
};

export const staySummarySlice = createSlice({
  name: "staySummary",
  initialState,
  reducers: {
    updateStaySummaryLoading: (state, action) => {
      state.showStaySummaryLoader = action.payload;
    },
    staySummaryOverviewReceived: (state, action) => {
      state.staySummaryOverview = action.payload;
    },
    updateOverviewInternalError: (state, action) => {
      state.overviewInternalError = action.payload;
    },
    updateServerError: (state, action) => {
      state.serverError = action.payload;
    },
    updateStaySummaryDetailLoading: (state, action) => {
      state.showStaySummaryDetailLoader = action.payload;
    },
    updateStaySummaryDetailParams: (state, action) => {
      state.stayTablePayload = { ...state.stayTablePayload, ...action.payload };
    },
    staySummaryDetailsReceived: (state, action) => {
      state.staySummaryDetail = action.payload.stayDetails;
      state.staySummaryDetailTotalCount = action.payload.totalCount;
    },
    batchDetailsReceived: (state, action) => {
      state.stayBatchDetail = action.payload.batchDetails?.[0];
    },
    updateStaySummeryDetailsInternalError: (state, action) => {
      state.staySummaryDetailInternalError = action.payload;
    },
    updateBatchParam: (state, action) => {
      state.batchTablePayload.filterBy.batchId =
        action.payload.bNum1 + action.payload.bNum2;
      state.batchTablePayload.group = action.payload.group;
      state.stayTablePayload.batchNum1 = action.payload.bNum1;
      state.stayTablePayload.batchNum2 = action.payload.bNum2;

      state.payloadForAddStayReview.batchNum1 = action.payload.bNum1;
      state.payloadForAddStayReview.batchNum2 = action.payload.bNum2;

      state.payloadForAddStaySave.batchNum1 = action.payload.bNum1;
      state.payloadForAddStaySave.batchNum2 = action.payload.bNum2;

      state.payloadForEditReview.batchNum1 = action.payload.bNum1;
      state.payloadForEditReview.batchNum2 = action.payload.bNum2;

      state.payloadForEditSave.batchNum1 = action.payload.bNum1;
      state.payloadForEditSave.batchNum2 = action.payload.bNum2;
    },
    updateIataUpdateFlag: (state, action) => {
      state.iataUpdateFlag = action.payload[0];
      state.travelAgencyName = action.payload[1];
    },
    updateIsQ50Prop: (state, action) => {
      state.isQ50Prop = action.payload;
    },
    updateStaySummFilterOption: (state, action) => {
      state.filterOptions = { ...action.payload };
    },
    updateBatchTableInternalError: (state, action) => {
      state.batchTableInternalError = action.payload;
    },
    updateIATAResponse: (state, action) => {
      state.payloadForAddStayReview.iataDetail = action.payload;
      state.payloadForAddStayReview.iataNumber = action.payload.iataNumber;
      state.payloadForAddStayReview.taName = action.payload.name;
      state.payloadForAddStaySave.iataDetail = action.payload;
      state.payloadForAddStaySave.iataNumber = action.payload.iataNumber;

      state.payloadForEditReview.iataDetail = action.payload;
      state.payloadForEditReview.iataNumber = action.payload.iataNumber;
      state.payloadForEditReview.taName = action.payload.name;
      state.payloadForEditSave.iataDetail = action.payload;
      state.payloadForEditSave.iataNumber = action.payload.iataNumber;
    },
    updatePropDetailResponse: (state, action) => {
      state.propertyDetails = {
        ...action.payload,
      };
      state.payloadForAddStayReview.propDetail = action.payload;
      state.payloadForAddStayReview.propertyCode = action.payload.propCode;

      state.payloadForAddStaySave.propDetail = action.payload;
      state.payloadForAddStaySave.propertyCode = action.payload.propCode;

      state.payloadForEditSave.propDetail = action.payload;
      state.payloadForEditSave.propertyCode = action.payload.propCode;

      state.payloadForEditReview.propDetail = action.payload;
      state.payloadForEditReview.propertyCode = action.payload.propCode;
    },
    updateMasterData: (state, action) => {
      state.masterData = action.payload;
      state.payloadForAddStayReview.maxRoomNightsAllowed =
        action.payload.maxRoomNightsAllowed;
      // state.payloadForAddStaySave.maxRoomNightsAllowed =
      //   action.payload.maxRoomNightsAllowed;

      state.payloadForEditReview.maxRoomNightsAllowed =
        action.payload.maxRoomNightsAllowed;
      // state.payloadForEditSave.maxRoomNightsAllowed =
      //   action.payload.maxRoomNightsAllowed;
    },
    updateNoPayReason: (state, action) => {
      let updatedNopayData = { ...state.noPayModifiedSaveData };
      state.noPayModifiedSaveData = { ...updatedNopayData, ...action.payload };
    },
    clearNoPayReason: (state, action) => {
      state.noPayModifiedSaveData = {};
    },
    discardNoPayReason: (state, action) => {
      let updatedNopayData = { ...state.noPayModifiedSaveData };
      if (action.payload && updatedNopayData?.[action.payload]) {
        delete updatedNopayData[action.payload];
      } else {
        updatedNopayData = {};
      }
      state.noPayModifiedSaveData = { ...updatedNopayData };
    },
    updateAddStaySaveStatus: (state, action) => {
      state.addStaySaveStatus = {
        ...state.addStaySaveStatus,
        ...action.payload,
      };
    },
    updateAddStayReviewStatus: (state, action) => {
      state.addStayReviewStatus = {
        ...state.addStayReviewStatus,
        ...action.payload,
      };
      if (action.payload.success) {
        state.payloadForAddStaySave.reviewStayActionDetails =
          action.payload.reviewStayActionDetails;
      }
    },
    updateEditReviewStatus: (state, action) => {
      state.editStayReviewStatus = {
        ...state.editStayReviewStatus,
        ...action.payload,
      };
      if (action.payload.success) {
        state.payloadForEditSave.reviewStayActionDetails =
          action.payload.reviewStayActionDetails;
      }
    },
    enableAddStayReviewMode: (state, action) => {
      state.addStayReviewMode = action.payload;
    },
    enableEditStayReviewMode: (state, action) => {
      state.editStayReviewMode = action.payload;
    },
    updateEditSaveStatus: (state, action) => {
      let editStaySaveStatus = { ...state.editStaySaveStatus };
      state.editStaySaveStatus = {
        ...editStaySaveStatus,
        ...action.payload,
      };
    },
    noPaySaveSuccess: (state, action) => {
      state.noPaySaveSuccessFlag = action.payload;
    },
    noPaySaveFailed: (state, action) => {
      state.noPaySaveFailedFlag = action.payload;
    },
    linkStayDetailsReducer: (state, action) => {
      state.linkStayDetails = action.payload;
    },
  },
});

export const {
  staySummaryOverviewReceived,
  updateStaySummaryLoading,
  updateOverviewInternalError,
  updateServerError,
  updateStaySummaryDetailLoading,
  updateStaySummaryDetailParams,
  staySummaryDetailsReceived,
  updateStaySummeryDetailsInternalError,
  updateBatchTableInternalError,
  batchDetailsReceived,
  updateBatchParam,
  updateIataUpdateFlag,
  updateIsQ50Prop,
  updateStaySummFilterOption,
  updateIATAResponse,
  updatePropDetailResponse,
  updateMasterData,
  updateNoPayReason,
  clearNoPayReason,
  updateAddStaySaveStatus,
  updateAddStayReviewStatus,
  updateEditReviewStatus,
  discardNoPayReason,
  updateEditSaveStatus,
  noPaySaveFailed,
  noPaySaveSuccess,
  enableAddStayReviewMode,
  enableEditStayReviewMode,
  linkStayDetailsReducer,
} = staySummarySlice.actions;

export default staySummarySlice.reducer;

export const getPropertyDetails = (propCode) => async (dispatch) => {
  try {
    let response = {};
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(`${API_URL.GET_PROPERTY_DETAILS}`);
    } else {
      response = await axios.get(`${API_URL.GET_PROPERTY_DETAILS}/${propCode}`);
    }
    if (response?.data?.propertyList) {
      let IsQ50 = validateIsQ50(response.data.propertyList[0]);
      dispatch(updateIsQ50Prop(IsQ50));
      dispatch(updatePropDetailResponse(response.data.propertyList[0]));
    }
  } catch (error) {
    dispatch(updateIsQ50Prop(false));
    dispatch(updateServerError(true));
  }
};

export const fetchStaySummaryOverview =
  (propCode, summaryOverviewReqBody) => async (dispatch) => {
    try {
      dispatch(updateStaySummaryLoading(true));
      dispatch(updateOverviewInternalError(false));
      let response = {};
      if (process.env.REACT_APP_ENV === "local") {
        response = await axios.get(`${API_URL.GET_STAYSUMMARY_BATCH_OVERVIEW}`);
      } else {
        response = await axios.post(
          `${API_URL.GET_STAYSUMMARY_BATCH_OVERVIEW}/${propCode}`,
          summaryOverviewReqBody
        );
      }
      dispatch(staySummaryOverviewReceived(response.data));
      dispatch(updateStaySummaryLoading(false));
    } catch (error) {
      dispatch(updateStaySummaryLoading(false));
      if (error?.response?.status === 404) {
        dispatch(updateServerError(true));
      } else {
        dispatch(updateOverviewInternalError(true));
      }
    }
  };
export const fetchSummeryBlock =
  ({ payloadForBatchTable, propertyCode }) =>
  async (dispatch) => {
    try {
      dispatch(updateBatchTableInternalError(false));
      let response = {};
      if (process.env.REACT_APP_ENV === "local") {
        response = await axios.get(
          API_URL.GET_BATCH_DETAILS,
          payloadForBatchTable
        );
      } else {
        response = await axios.post(
          `${API_URL.GET_BATCH_DETAILS}/${propertyCode}`,
          payloadForBatchTable
        );
      }
      if (response?.data?.batchDetails) {
        dispatch(batchDetailsReceived(response.data));
      } else {
        throw response;
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        dispatch(updateServerError(true));
      } else {
        dispatch(updateBatchTableInternalError(true));
      }
    }
  };
export const fetchStaySummeryDetails =
  ({ payloadForStayTable, propertyCode }) =>
  async (dispatch) => {
    try {
      dispatch(updateIataUpdateFlag([false, ""]));
      dispatch(updateStaySummaryDetailLoading(true));
      dispatch(updateStaySummeryDetailsInternalError(false));
      let response = {};
      if (process.env.REACT_APP_ENV === "local") {
        response = await axios.get(
          API_URL.GET_STAY_SUMMARY_DETAILS,
          payloadForStayTable
        );
      } else {
        response = await axios.post(
          `${API_URL.GET_STAY_SUMMARY_DETAILS}/${propertyCode}`,
          payloadForStayTable
        );
      }
      if (response?.data?.stayDetails) {
        dispatch(staySummaryDetailsReceived(response.data));
        dispatch(updateStaySummaryDetailLoading(false));
      } else {
        throw response;
      }
    } catch (error) {
      dispatch(updateStaySummaryDetailLoading(false));
      if (error?.response?.status === 404) {
        dispatch(updateServerError(true));
      } else {
        dispatch(updateStaySummeryDetailsInternalError(true));
      }
    }
  };

export const fetchLinkedStayDetails =
  ({ payloadForStayTable, propertyCode }) =>
  async (dispatch) => {
    try {
      let response = {};
      if (process.env.REACT_APP_ENV === "local") {
        response = await axios.get(
          API_URL.GET_STAY_SUMMARY_DETAILS,
          payloadForStayTable
        );
      } else {
        response = await axios.post(
          `${API_URL.GET_STAY_SUMMARY_DETAILS}/${propertyCode}`,
          payloadForStayTable
        );
      }
      if (response?.data?.stayDetails) {
        dispatch(linkStayDetailsReducer(response?.data?.stayDetails));
      } else {
        throw response;
      }
    } catch (error) {
      return error?.response?.data;
    }
  };
export const validIATA = async (iataNum) => {
  try {
    if (iataNum.length > 3) {
      const iataResponse = await axios.get(
        `${API_URL.VALIDATE_IATA}/${iataNum}`
      );
      const indicator = iataResponse?.data?.indicator;
      const isValid =
        indicator === null || indicator === ""
          ? IATA_ERROR
          : indicator === INACTIVE_IATA_INDICATOR
          ? TT_IATA_INACTIVE_ERROR
          : true;
      return { isValid, iataDetails: iataResponse?.data };
    } else {
      return { isValid: false, iataDetails: {} };
    }
  } catch (error) {
    return { isValid: false, iataDetails: {} };
  }
};

export const validateIATA = (iataNum) => async (dispatch) => {
  try {
    const iataResponse = await axios.get(`${API_URL.VALIDATE_IATA}/${iataNum}`);
    const indicator = iataResponse?.data?.indicator;
    const isValid =
      indicator === null || indicator === ""
        ? IATA_ERROR
        : indicator === INACTIVE_IATA_INDICATOR
        ? TT_IATA_INACTIVE_ERROR
        : true;
    if (isValid === true) {
      dispatch(updateIATAResponse(iataResponse.data));
      dispatch(updateIataUpdateFlag([isValid, iataResponse?.data?.name]));
    } else {
      dispatch(updateIATAResponse({}));
      dispatch(updateIataUpdateFlag([false, ""]));
    }
    return isValid;
  } catch (error) {
    dispatch(updateIataUpdateFlag([false, ""]));
    return false;
  }
};

export const validateIATAAdd = (iataNum) => async (dispatch) => {
  try {
    let isValid = { isValid: false, iataDetails: {} };
    const iataResponse = await axios
      .get(`${API_URL.VALIDATE_IATA}/${iataNum}`)
      .then((iataResponse) => {
        const indicator = iataResponse?.data?.indicator;
        isValid =
          indicator === null || indicator === ""
            ? IATA_ERROR
            : indicator === INACTIVE_IATA_INDICATOR
            ? TT_IATA_INACTIVE_ERROR
            : true;
        if (isValid === true) {
          dispatch(updateIATAResponse(iataResponse.data));
          dispatch(updateIataUpdateFlag([isValid, iataResponse?.data?.name]));
          isValid = { isValid, iataDetails: iataResponse.data };
        } else {
          dispatch(updateIATAResponse({}));
          dispatch(updateIataUpdateFlag([false, ""]));
          isValid = { isValid: false, iataDetails: {} };
        }
      });
    return isValid;
  } catch (error) {
    dispatch(updateIataUpdateFlag([false, ""]));
    return { isValid: false, iataDetails: {} };
  }
  /*try {
      let isValid = { isValid: false, iataDetails: {} };
      if (iataNum.length > 3) {
        const iataResponse = axios.get(
          `${API_URL.VALIDATE_IATA}/${iataNum}`
        ).then(
          iataResponse => {
            //console.log('validateIATAAdd data '+JSON.stringify(objectValue));
          
             console.log('validateIATAAdd data '+iataResponse.data);
             const indicator = iataResponse?.data?.indicator;
             console.log('validateIATAAdd indicator '+indicator);
             isValid =
               indicator === null || indicator === ""
                 ? IATA_ERROR
                 : indicator === INACTIVE_IATA_INDICATOR
                 ? TT_IATA_INACTIVE_ERROR
                 : true;
                 return { isValid, iataDetails: iataResponse?.data };
      });
        
      } else {
        return { isValid: false, iataDetails: {} };
      }
    } catch (error) {
      return { isValid: false, iataDetails: {} };
    }*/
};

export const validateIATAFilter = (iataNum) => async (dispatch) => {
  try {
    const iataResponse = await axios.get(`${API_URL.VALIDATE_IATA}/${iataNum}`);
    const indicator = iataResponse?.data?.indicator;
    const isValid = indicator === null || indicator === "" ? IATA_ERROR : true;
    return isValid;
  } catch (error) {
    if (error?.response?.status === 400) {
      return IATA_ERROR;
    } else {
      dispatch(updateServerError(true));
      return IATA_API_ERROR;
    }
  }
};

export const fetchStaySummFilterOption =
  ({ payload, propertyCode }) =>
  async (dispatch) => {
    try {
      let response = {};
      if (process.env.REACT_APP_ENV === "local") {
        response = await axios.get(API_URL.GET_STAY_SUMMARY_FILTER_OPTION, {
          params: payload,
        });
      } else {
        response = await axios.get(
          `${API_URL.GET_STAY_SUMMARY_FILTER_OPTION}/${propertyCode}`,
          { params: payload }
        );
      }
      if (response?.data) {
        dispatch(updateStaySummFilterOption(response.data));
      } else {
        throw response;
      }
    } catch (error) {
      dispatch(updateStaySummFilterOption({}));
      dispatch(updateServerError(true));
    }
  };

export const getMasterData = (payload) => async (dispatch) => {
  try {
    let response = {};
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(API_URL.GET_MASTER_DATA);
    } else {
      response = await axios.get(API_URL.GET_MASTER_DATA, {
        params: payload,
      });
    }
    if (response?.data) {
      dispatch(updateMasterData(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const reviewAddStay = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(API_URL.REVIEW_ADD_STAY, payload);
    const data = response.data;
    if (response?.data) {
      dispatch(
        updateAddStayReviewStatus({
          ...data,
          reviewStayActionDetails: data.reviewStayActionDetails
            ? { ...data.reviewStayActionDetails }
            : { ...REVIEW_STAY_ACTION_DETAILS },
          success: !data.error,
          apiError: false,
          message: "",
        })
      );
      if (!data.error) {
        dispatch(enableAddStayReviewMode(true));
      }
    }
  } catch (error) {
    dispatch(
      updateAddStayReviewStatus({
        success: false,
        apiError: true,
        message: `${error?.response?.data?.message}`,
      })
    );
    console.log("error", error);
  }
};

export const saveStay = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(API_URL.SAVE_ADD_STAY, payload);
    if (response?.data) {
      dispatch(
        updateAddStaySaveStatus({
          ...response.data,
          success: !response.data.error,
          message: "",
        })
      );
    }
  } catch (error) {
    dispatch(
      updateAddStaySaveStatus({
        ...ADD_STAY_SAVE_RESET,
        apiError: true,
        message: `${error?.response?.data?.message}`,
      })
    );
    console.log("error", error);
  }
};

export const reviewEditStay = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(API_URL.REVIEW_EDIT_STAY, payload);
    if (response?.data) {
      const data = response.data;
      dispatch(
        updateEditReviewStatus({
          ...data,
          reviewStayActionDetails: data.reviewStayActionDetails
            ? { ...data.reviewStayActionDetails }
            : { ...REVIEW_STAY_ACTION_DETAILS },
          success: !data.error,
        })
      );
      if (!data.error) {
        dispatch(enableEditStayReviewMode(true));
      }
    }
  } catch (error) {
    dispatch(
      updateEditReviewStatus({
        success: false,
        apiError: true,
        message: `${error?.response?.data?.message}`,
      })
    );
    console.log("error", error);
  }
};

export const saveEditStay = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(API_URL.SAVE_EDIT_STAY, payload);
    if (response?.data) {
      dispatch(
        updateEditSaveStatus({
          ...response.data,
          success: !response.data.error,
          message: `${
            payload.noPayReason && payload.noPayReason === VOID_RESERVATION
              ? STAY_SAVE_DELETE_SUCCESS
              : STAY_EDITED
          }`,
        })
      );
    }
  } catch (error) {
    dispatch(
      updateEditSaveStatus({
        ...ADD_STAY_REVIEW_RESET,
        apiError: true,
        message: `${error?.response?.data?.message}`,
      })
    );
    console.log("error", error);
  }
};

export const saveNopayData = (payload) => async (dispatch) => {
  try {
    dispatch(updateStaySummaryLoading(true));
    const response = await axios.post(API_URL.EDIT_NOPAY, payload.saveData);
    dispatch(discardNoPayReason(payload.sequenceNumber));
    if (response.data && response.data.error !== true) {
      //Success case
      dispatch(noPaySaveSuccess(true));
    } else {
      throw response;
    }
    dispatch(updateStaySummaryLoading(false));
  } catch (error) {
    dispatch(discardNoPayReason(payload.sequenceNumber));
    dispatch(updateStaySummaryLoading(false));
    if (error?.response?.status === 401) {
      //dispatch(createSession());
    } else {
      dispatch(noPaySaveFailed(`${error?.response?.data?.message}`));
    }
  }
};
