import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTableServerSide from "../../../../components/datatableserverside/DataTableServerSide";
import GroupBlockDetailView from "../groupblockdetailsview/GroupBlockDetailView";
import {
  CANCELLED_TOOLTIP_LABEL,
  COMMISSION_TOOLTIP_LABEL,
  CURRENCY,
  CURRENCY_TYPE,
  FASTPAY_TOOLTIP_LABEL,
  GA_TABLE_HEADER_BLOCK_CODE,
  GA_TABLE_HEADER_BLOCK_NAME,
  GA_TABLE_HEADER_COM,
  GA_TABLE_HEADER_CXL,
  GA_TABLE_HEADER_END_DATE,
  GA_TABLE_HEADER_FP,
  GA_TABLE_HEADER_IATA,
  GA_TABLE_HEADER_LINK,
  GA_TABLE_HEADER_QUOTE,
  GA_TABLE_HEADER_START_DATE,
  MOD,
  MOD_TOOLTIP_LABEL,
  PROPERTY_CODE,
  TABLE_HEADER_PROPERTY_AMOUNT,
  YES,
} from "../../../../utils/constants";
import { renderCellPropAmount } from "../../../../utils/renderFunction";
import { formatDate } from "../../../../utils/utils";
import { updateGroupBlockTableParams } from "../../../../store/feature/groupblock/groupBlockSlice";
import { useLocation } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
const renderCellEventStartDate = ({ row }) =>
  row?.original?.eventStartDate
    ? formatDate(row?.original?.eventStartDate, "DDMMMYY").toUpperCase()
    : null;

const renderCellGroupIata = (row) =>
  row?.original.groupIataNumber ? (
    <OverlayTrigger
      delay={{ show: 50, hide: 400 }}
      placement="top"
      overlay={
        <Popover className="pop-over iata-name">
          <div className="font-12 text-center">
            <span>{row?.original.cmTaName}</span>
          </div>
        </Popover>
      }
    >
      <span>{row?.original.groupIataNumber}</span>
    </OverlayTrigger>
  ) : null;

const renderCellEventEndDate = ({ row }) =>
  row?.original?.eventEndDate
    ? formatDate(row?.original?.eventEndDate, "DDMMMYY").toUpperCase()
    : null;
export default function GroupTable({ data, expandIndex, filterChanged }) {
  const { currencyType } = useSelector((state) => state.landing);
  let { state: routeData } = useLocation();
  const { payloadForTable } = useSelector((state) => state.group);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState({});
  const [forcedClearExpand, setForcedClearExpand] = useState(0);

  useEffect(() => {
    setExpanded(expandIndex);
  }, [expandIndex]);

  useEffect(() => {
    setForcedClearExpand((state) => state + 1);
  }, [filterChanged]);

  const column = useMemo(
    () => [
      {
        accessorKey: "propertyCode",
        header: () => PROPERTY_CODE,
        width: 100,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "groupIataNumber",
        header: () => GA_TABLE_HEADER_IATA,
        cell: ({ row }) => renderCellGroupIata(row),
        width: 120,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "groupBlockCode",
        header: () => GA_TABLE_HEADER_BLOCK_CODE,
        width: 110,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "groupBlockName",
        header: () => GA_TABLE_HEADER_BLOCK_NAME,
        width: 200,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "eventStartDate",
        header: () => GA_TABLE_HEADER_START_DATE,
        width: 100,
        cell: renderCellEventStartDate,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "eventEndDate",
        header: () => GA_TABLE_HEADER_END_DATE,
        cell: renderCellEventEndDate,
        width: 100,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "quoteNumber",
        header: () => GA_TABLE_HEADER_QUOTE,
        width: 120,
        footer: (props) => props.column.id,
      },
      // {
      //   accessorKey: "groupBlockId",
      //   header: () => GA_TABLE_HEADER_BLOCK_ID,
      //   footer: (props) => props.column.id,
      // },
      {
        accessorKey: "propertyAmount",
        header: () => TABLE_HEADER_PROPERTY_AMOUNT,
        className: "header_right_align",
        cell: ({ row }) => renderCellPropAmount(row, currencyType),
        width: 130,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "currencyCode",
        header: () => CURRENCY,
        width: 80,
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <span>
            {currencyType === CURRENCY_TYPE.USD
              ? CURRENCY_TYPE.USD
              : row.original.currencyCode}
          </span>
        ),
      },
      {
        accessorKey: "modIndicator",
        header: () => MOD,
        tooltip: MOD_TOOLTIP_LABEL,
        footer: (props) => props.column.id,
        width: 60,
      },
      {
        accessorKey: "comIndicator",
        header: () => GA_TABLE_HEADER_COM,
        tooltip: COMMISSION_TOOLTIP_LABEL,
        footer: (props) => props.column.id,
        width: 60,
      },
      {
        accessorKey: "fpIndicator",
        header: () => GA_TABLE_HEADER_FP,
        footer: (props) => props.column.id,
        tooltip: FASTPAY_TOOLTIP_LABEL,
        width: 60,
      },
      {
        accessorKey: "delIndicator",
        header: () => GA_TABLE_HEADER_CXL,
        tooltip: CANCELLED_TOOLTIP_LABEL,
        footer: (props) => props.column.id,
        width: 60,
      },
      {
        accessorKey: "groupLinkId",
        header: () => GA_TABLE_HEADER_LINK,
        cell: ({ row }) => (
          <span>
            {row.original.groupLinkId && row.original.groupLinkId.trim().length
              ? YES
              : ""}
          </span>
        ),
        footer: (props) => props.column.id,
        width: 60,
      },
    ],
    [currencyType]
  );
  const applySort = (sortBy) => {
    // sortBy = sortBy || TB_DEFAULT_TABLE_PAYLOAD.sortBy;
    if (sortBy) {
      dispatch(
        updateGroupBlockTableParams({
          ...payloadForTable,
          ...{ sortBy: sortBy },
        })
      );
      setForcedClearExpand((state) => state + 1);
    }
  };
  return (
    <DataTableServerSide
      column={column}
      data={data}
      sortCallback={applySort}
      enableDetailView={true}
      getRowCanExpand={() => true}
      defaultExpandedRows={expanded}
      dynamicColumnWidth={50}
      fixedWidthColumn={true}
      multiOpen={false}
      childTable={(data, rowId) => (
        <GroupBlockDetailView row={data} rowid={rowId} />
      )}
      forcedClearExpand={forcedClearExpand}
    ></DataTableServerSide>
  );
}
