// FormPrompt.js

import { useEffect, useCallback, useRef } from "react";
import { useBeforeUnload, useBlocker } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { unsavedAlert } from "../../store/feature/landing/landingSlice";

const stepLinks = ["/"];

export const UnsavedChangeAlert = () => {
  const dispatch = useDispatch();
  const { hasUnsavedChanges } = useSelector((state) => state.landing);
  const onLocationChange = useCallback(
    ({ nextLocation }) => {
      let confirmAction = false;
      if (!stepLinks.includes(nextLocation.pathname) && hasUnsavedChanges) {
        confirmAction = !window.confirm(
          "You have unsaved changes. Are you sure you want to leave?"
        );
      }
      if (!confirmAction) {
        dispatch(unsavedAlert(false));
      }
      return confirmAction;
    },
    [hasUnsavedChanges]
  );

  usePrompt(onLocationChange, hasUnsavedChanges);
  useBeforeUnload(
    useCallback(
      (event) => {
        if (hasUnsavedChanges) {
          event.preventDefault();
          event.returnValue = "";
        }
      },
      [hasUnsavedChanges]
    ),
    { capture: true }
  );

  return null;
};

function usePrompt(onLocationChange, hasUnsavedChanges) {
  const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);
}
