import { BROADCAST_MESSAGE } from "../../utils/constants";

export default function BroadcastMessage({ message, onCloseBroadcastMsg }) {
  return (
    <div className="broadcast-container">
      <div className="broadcast-outer">
        <div className="alert-icon-img">
          <i className="alert-icon" />
        </div>
        <div
          className="alert-msg"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <div className="close">
          <i className="close-icon" onClick={onCloseBroadcastMsg} />
        </div>
      </div>
    </div>
  );
}
