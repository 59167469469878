import { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {
  PROMPT_BTN_LABEL_CANCEL,
  PROMPT_BTN_LABEL_OK,
} from "../../utils/constants";

function Prompt(props) {
  const {
    show,
    modalTitle = "",
    modalBody = "Are you sure?",
    handleOnShowClose,
    size,
    ariaLabelledby,
    className,
    actionCallBack,
    backdropClassName,
  } = props;
  const [visible, setVisible] = useState(show);
  const promptAction = (action) => {
    if (actionCallBack) {
      actionCallBack(action);
    }
    setVisible(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleOnShowClose}
      size={size}
      aria-labelledby={ariaLabelledby}
      dialogClassName={`${className} modal-90w`}
      backdropClassName={backdropClassName}
      centered
    >
      <Modal.Body>
        <div>
          <p>{modalBody}</p>
          <p>
            <Button
              className="m-1"
              onClick={() => {
                promptAction(true);
              }}
            >
              {PROMPT_BTN_LABEL_OK}
            </Button>
            <Button
              className="m-1"
              onClick={() => {
                promptAction(false);
              }}
            >
              {PROMPT_BTN_LABEL_CANCEL}
            </Button>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Prompt;
