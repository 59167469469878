import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { CURRENCY_CONST, CURRENCY_TYPE } from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { updateCurrancy } from "../../store/feature/landing/landingSlice";
import {
  CURRENCY_CONST_PROPERTY,
  CURRENCY_CONST_USD,
} from "../../utils/config";

export default function CurrencyButton({ showUSD = true }) {
  const { currencyType } = useSelector((state) => state.landing);
  const dispatch = useDispatch();
  const changeCurrancy = (type) => {
    dispatch(updateCurrancy(type));
  };
  return (
    <div className="currency_comp">
      <p className="m-0 px-1">{CURRENCY_CONST.CURRENCY_TITLE}</p>
      <ButtonGroup aria-label="currency button">
        <Button
          onClick={() => changeCurrancy(CURRENCY_CONST_PROPERTY)}
          className={`font-16 mx-1 ${
            currencyType === CURRENCY_CONST_PROPERTY
              ? "btn-primary "
              : "non_active"
          }`}
        >
          {CURRENCY_TYPE.PROPERTY}
        </Button>
        {showUSD ? (
          <Button
            onClick={() => changeCurrancy(CURRENCY_CONST_USD)}
            className={` btn font-16 mx-1 ${
              currencyType === CURRENCY_CONST_USD ? "btn-primary" : "non_active"
            }`}
          >
            {CURRENCY_TYPE.USD}
          </Button>
        ) : null}
      </ButtonGroup>
    </div>
  );
}
