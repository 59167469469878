import React from "react";
import { Container } from "react-bootstrap";
import pendingFundingIcon from "../../assets/access_time_filled.png";
import approveIcon from "../../assets/check_circle.png";
import reviewIcon from "../../assets/review.png";
import totalCommissionsIcon from "../../assets/total_commission.png";
import { BATCH_STATUS, CURRENCY_TYPE } from "../../utils/constants";
import CardDisplay from "../carddisplay/CardDisplay";

export default function TransientBatchOverview({
  data,
  isLanding,
  overviewLandingClasses,
  currencyType,
  className,
  column,
}) {
  const transientData = data;
  const currencyCodeText =
    currencyType === CURRENCY_TYPE.USD
      ? CURRENCY_TYPE.USD
      : transientData?.currencyCode;

  const transientReviewAmt =
    currencyType === CURRENCY_TYPE.USD
      ? transientData?.reviewAmountUsd
      : transientData?.reviewAmount;

  const transientPendingAmt =
    currencyType === CURRENCY_TYPE.USD
      ? transientData?.approvalAmountUsd
      : transientData?.approvalAmount;

  const transientFundAmt =
    currencyType === CURRENCY_TYPE.USD
      ? transientData?.pendingFundingAmountUsd
      : transientData?.pendingFundingAmount;

  const transientTotalCommissionAmt =
    currencyType === CURRENCY_TYPE.USD
      ? transientData?.totalCommissionsUsd
      : transientData?.totalCommissions;

  const cardDetal = [
    {
      icon: reviewIcon,
      statusDetail: `${transientData?.reviewCount || 0} ${
        transientData?.reviewCount?.toString() === "1"
          ? BATCH_STATUS.REVIEW.HEADING_BATCH
          : BATCH_STATUS.REVIEW.HEADING
      }`,
      amountDetail: `${
        isLanding ? transientReviewAmt.split(".")[0] : transientReviewAmt
      } ${currencyCodeText}`,
      fieldName: BATCH_STATUS.REVIEW.TITLE,
      linkTo: `transient/Review/${currencyType}`,
      imageClass: "icon-regular",
    },
    {
      icon: approveIcon,
      statusDetail: `${transientData?.approvalCount || 0}  ${
        transientData?.approvalCount?.toString() === "1"
          ? BATCH_STATUS.APPROVE.HEADING_APPROVE
          : BATCH_STATUS.APPROVE.HEADING
      }`,
      amountDetail: `${
        isLanding ? transientPendingAmt.split(".")[0] : transientPendingAmt
      } ${currencyCodeText}`,
      fieldName: BATCH_STATUS.APPROVE.TITLE,
      linkTo: `transient/Pending/${currencyType}`,
      imageClass: "icon-regular",
    },
    {
      icon: pendingFundingIcon,
      statusDetail: BATCH_STATUS.PENDING_FUNDING.HEADING,
      amountDetail: `${
        isLanding ? transientFundAmt.split(".")[0] : transientFundAmt
      } ${currencyCodeText}`,
      fieldName: BATCH_STATUS.PENDING_FUNDING.TITLE,
      linkTo: `transient/Fund/${currencyType}`,
      imageClass: "icon-regular",
    },
    {
      icon: totalCommissionsIcon,
      statusDetail: BATCH_STATUS.TOTAL_COMMISSION.HEADING,
      amountDetail: `${
        isLanding
          ? transientTotalCommissionAmt.split(".")[0]
          : transientTotalCommissionAmt
      } ${
        currencyType === CURRENCY_TYPE.USD
          ? CURRENCY_TYPE.USD
          : transientData?.currencyCode
      }`,
      fieldName: BATCH_STATUS.TOTAL_COMMISSION.TITLE,
      linkTo: `transient/All/${currencyType}`,
      imageClass: "icon-medium",
    },
  ];

  const classNames = {
    colClass: "p-0 mx-0",
    cardClass: "m-3 py-2",
    cardBodyClass: "py-4 px-0",
    divClass: "px-3",
  };
  return (
    <Container fluid className={className}>
      <div
        className={`${
          isLanding ? "row row-cols-2" : "row row-cols-4"
        } row-cols-${column}`}
      >
        {cardDetal.map((cardData, index) => {
          return (
            <CardDisplay
              key={`transientbatch-card-${index + 1}`}
              icon={cardData.icon}
              statusDetail={cardData.statusDetail}
              amountDetail={cardData.amountDetail}
              fieldName={cardData.fieldName}
              linkTo={cardData.linkTo}
              imageClass={cardData.imageClass}
              isLinkShown={isLanding}
              classNames={isLanding ? overviewLandingClasses : classNames}
              hasMultipleCurrency={transientData?.multipleCurrency}
              currencyType={currencyType}
            />
          );
        })}
      </div>
    </Container>
  );
}
