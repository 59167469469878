import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormStaySummary from "../../../components/formStaySummary/FormStaySummary";
import ModalDialog from "../../../components/modal/ModalDialog";
import {
  updateAddStayReviewStatus,
  updateAddStaySaveStatus,
  updateIATAResponse,
  updateIataUpdateFlag,
  validIATA,
  validateIATAAdd,
} from "../../../store/feature/staysummary/staySummarySlice";
import {
  ENTER_IATA,
  ENTER_NEW_STAY,
  IATA_ERROR,
  IATA_UPDATE_BTN,
  LABEL_IATA_NUMBER,
  TRAVEL_AGENCY_NAME,
  VALIDATE_IATA,
} from "../../../utils/constants";
import AddStayDetails from "./AddStayDetails";
import "./AddStay.scss";
import AddStayReview from "./AddStayReview";
import { ADD_STAY_SAVE_RESET } from "../../../utils/config";

export default function AddStayModal({ modalShow, handleAddStayClose }) {
  const {
    iataUpdateFlag,
    travelAgencyName,
    addStayReviewData,
    addStayReviewMode,
  } = useSelector((state) => state.staySummary);
  const [iataInfo, setIataInfo] = useState({ isValid: true });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateIataUpdateFlag([false, ""]));
    return () => {
      dispatch(
        updateAddStayReviewStatus({
          success: false,
          apiError: false,
          message: ``,
        })
      );
      dispatch(
        updateAddStaySaveStatus({
          ...ADD_STAY_SAVE_RESET,
        })
      );
    };
  }, []);
  const iataValidate = async (value) => {
    let result = true;
    if (value?.length) {
      result = await validIATA(value);
    }
    setIataInfo(result);
    if (result.isValid === false) {
      dispatch(updateIataUpdateFlag([false, ""]));
    }
    return result.isValid;
  };

  const iataValidateAdd = async (value) => {
    //dispatch(updateIataUpdateFlag([false, ""]));
    let result = true;
    if (value?.length) {
      result = await dispatch(validateIATAAdd(value));
      setIataInfo(result);
    }
    return result;
  };

  const config = {
    form: {
      iataNumber: {
        label: LABEL_IATA_NUMBER,
        type: "text",
        width: "175",
        rules: {
          required: true,
          message: IATA_ERROR,
        },
      },
    },
  };
  const configAddStay = {
    form: {
      iataNumber: {
        label: LABEL_IATA_NUMBER,
        type: "text",
        width: "175",
        rules: {
          required: true,
          message: IATA_ERROR,
        },
      },
      agencyName: {
        label: TRAVEL_AGENCY_NAME,
        type: "readonly",
        defaultValue: travelAgencyName,
        width: "300",
      },
    },
  };

  const handleSubmit = (data) => {
    iataValidateAdd(data.iataNumber);
  };
  return (
    <ModalDialog
      show={modalShow}
      handleOnShowClose={handleAddStayClose}
      size={iataUpdateFlag ? "lg" : "md"}
      ariaLabelledby="contained-modal-title-vcenter"
      className={`stay-overlay ${
        iataUpdateFlag ? "overlay-fixed-min-width" : "overlay-auto-min-width"
      }`}
    >
      <div className="add-stay">
        <div className={addStayReviewMode ? "showform" : "hideform"}>
          <AddStayReview handleAddStayClose={handleAddStayClose} />
        </div>
        <div className={!addStayReviewMode ? "showform" : "hideform"}>
          <h4 className="weight-700">{ENTER_NEW_STAY}</h4>
          <p className="weight-700 pt-1 pb-0 mb-0">{ENTER_IATA}</p>
          <FormStaySummary
            config={iataUpdateFlag ? configAddStay : config}
            submitCallBack={handleSubmit}
            applyBtnTitle={iataUpdateFlag ? IATA_UPDATE_BTN : VALIDATE_IATA}
            className={`modify-form-stay p-0 ${
              iataInfo?.isValid !== true ? "error-border" : ""
            } ${iataUpdateFlag ? "step-2-stay" : "step-1-stay"}`}
            handleAddStayClose={handleAddStayClose}
            hideCancel={iataUpdateFlag}
          />
          {iataInfo?.isValid !== true ? (
            <p
              class={
                iataUpdateFlag
                  ? "negative_margin_review form-error font-12"
                  : "negative_margin form-error font-12"
              }
            >
              {iataInfo?.isValid.length ? iataInfo?.isValid : IATA_ERROR}
            </p>
          ) : (
            ""
          )}
          {iataUpdateFlag ? (
            <AddStayDetails handleAddStayClose={handleAddStayClose} />
          ) : null}
        </div>
      </div>
    </ModalDialog>
  );
}
