import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import PropertyTitleBar from "./components/propertytitlebar/PropertyTitleBar";
import TabNavigation from "./components/tabnavigation/TabNavigation";
import {
  getActiveRoute,
  getSession,
  getSessionParam,
  gotoLogin,
} from "./utils/utils";
import PropertyInput from "./components/propertyinput/PropertyInput";
import ModalDialog from "./components/modal/ModalDialog";
import { useSelector } from "react-redux";
import { UnsavedChangeAlert } from "./components/unsavedchangealert/UnsavedChangeAlert";
import { ResizeContext } from "./utils/resizeContext";
import SessionTimeoutPopUp from "./components/sessiontimeoutpopup/SessionTimeoutPopUp";
import ErrorBoundary from "./components/errorboundary/ErrorBoundary";
import Logout from "./container/logout/Logout";

export default function Layout() {
  const { contextCMT } = useContext(ResizeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const activeRoute = getActiveRoute(location.pathname);
  const { propCode, groupBatch, userInfo, logOut } = useSelector(
    (state) => state.landing
  );

  useEffect(() => {
    const sessionID = getSession("x-session-id");
    let newSessionID = getSessionParam("sessionId");
    if (!userInfo && !sessionID && !newSessionID) {
      if (!logOut) {
        gotoLogin();
      }
    }
  }, [userInfo, logOut]);
  const windowWidth = window.innerWidth - (window.innerWidth * 0.1 + 30);

  return (
    <>
      <Header activeRoute={activeRoute} />
      <UnsavedChangeAlert />
      {!logOut ? <SessionTimeoutPopUp /> : null}
      <div
        className={`${
          contextCMT.tableWidth > windowWidth
            ? "app_container_fullwidth"
            : "app_container"
        }`}
      >
        <div className="marginHorizontal vh-body">
          {logOut ? (
            <Logout />
          ) : (
            <>
              {!propCode && userInfo ? (
                <ModalDialog closeButton={false} show={true} size={"sm"}>
                  <PropertyInput />
                </ModalDialog>
              ) : (
                <>
                  {userInfo ? (
                    <>
                      {" "}
                      <PropertyTitleBar />
                      <div
                        className={`${
                          activeRoute
                            ? activeRoute + "_screen"
                            : "landing_screen"
                        } `}
                      >
                        {activeRoute &&
                        activeRoute !== "contactus" &&
                        activeRoute !== "admin" ? (
                          <TabNavigation
                            groupBatch={groupBatch}
                            activeKey={activeRoute}
                          >
                            <ErrorBoundary>
                              <Outlet />
                            </ErrorBoundary>
                          </TabNavigation>
                        ) : (
                          <ErrorBoundary>
                            <Outlet />
                          </ErrorBoundary>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <ErrorBoundary>
                        <Outlet />
                      </ErrorBoundary>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
