import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  TB_FILTER_TITLE,
  TB_FILTER_PLACEHOLDER_STATUS,
  TB_FILTER_TITLE_MONTH,
  TB_FILTER_TITLE_STATUS,
  FILTER_TITLE_MOD,
  FILTER_MOD_PLACEHOLDER,
} from "../../../../utils/constants";
import Form from "../../../../components/form/Form";
import {
  MONTH_NUMBER,
  BATCH_STATUS_TYPE,
  MONTH_FILTER_OPTION,
  MOD_FILTER_OPTION,
  FILTER_WAIT,
  FILTER_EXTRACTED,
  TRANS_STATUS_FILTER_OPTION,
} from "../../../../utils/config";
import { convertObjToArrayMultiSelect } from "../../../../utils/utils";

export default function TransientFilter({ filterCallback }) {
  const { payloadForTable } = useSelector((state) => state.transient);
  const handleSubmit = (filterData) => {
    if (filterData?.month) {
      filterData.month = MONTH_NUMBER[filterData.month];
    }
    if (filterData?.status) {
      filterData.status = filterData.status.map((status) =>
        Object.keys(BATCH_STATUS_TYPE).find(
          (statusKey) => BATCH_STATUS_TYPE[statusKey] === status
        )
      );
      filterData.status.includes(FILTER_WAIT) &&
        filterData.status.push(FILTER_EXTRACTED);
      filterData.status = filterData.status.filter((e) => e);
    }
    if (filterCallback) {
      filterData = filterData
        ? filterData
        : { modIndicator: [], month: "", status: [] };
      filterCallback(filterData);
    }
  };
  const modDefault = payloadForTable.filterBy.modIndicator;
  const statusDefault = payloadForTable.filterBy.status
    .map((data) => BATCH_STATUS_TYPE[data])
    .filter((item) => typeof item === "string");

  const monthDefault = Object.keys(MONTH_NUMBER).find(
    (k) => MONTH_NUMBER[k] === payloadForTable.filterBy.month
  );

  const config = useMemo(() => {
    return {
      form: {
        month: {
          label: TB_FILTER_TITLE_MONTH,
          type: "select",
          defaultValue: monthDefault,
          fieldWidth: 300,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
          options: MONTH_FILTER_OPTION,
        },
        status: {
          label: TB_FILTER_TITLE_STATUS,
          placeholder: TB_FILTER_PLACEHOLDER_STATUS,
          type: "multiselect",
          options: TRANS_STATUS_FILTER_OPTION,
          defaultValue: statusDefault,
          fieldWidth: 300,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        modIndicator: {
          label: FILTER_TITLE_MOD,
          placeholder: FILTER_MOD_PLACEHOLDER,
          fieldWidth: 300,
          fieldClassName: "flex-none",
          type: "multiselectv2",
          options: convertObjToArrayMultiSelect(MOD_FILTER_OPTION, "keyValue"),
          defaultValue: modDefault,
          rules: {
            required: false,
          },
        },
      },
    };
  }, [payloadForTable.filterBy]);

  return (
    <div className="mx-2">
      <h3 className="font-30 weight-700">{TB_FILTER_TITLE}</h3>
      <Form
        config={config}
        submitCallBack={handleSubmit}
        className={`transient-filters filter-form m-0 g-0`}
      />
    </div>
  );
}
