import axios from "axios";
import { getSession, setSession, gotoLogin, removeSession } from "./utils";
import { API_URL } from "./Api_URL";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    //const base64data = btoa('USER123' + ':' + 'USER123');
    //config.headers["Authorization"] = `Basic ${base64data}`;
    // let req = new XMLHttpRequest();
    // req.open("GET", document.location, false);
    // req.send(null);
    // let headers = req.getAllResponseHeaders().toLowerCase();
    // headers = headers.split(/\n|\r|\r\n/g).reduce(function (a, b) {
    //   if (b.length) {
    //     let [key, value] = b.split(": ");
    //     a[key] = value;
    //   }
    //   return a;
    // }, {});

    if (config.url.includes("/token")) {
      const sessionID = getSession("x-session-id");
      if (sessionID) {
        config.headers["x-session-id"] = sessionID;
      }
    }
    if (config.url.includes("/v1/ctac")) {
      const sessionID = getSession("x-session-id");
      const accessToken = getSession("accessToken");
      if (sessionID) {
        config.headers["x-session-id"] = sessionID;
      }

      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        let response = {};
        let token = "";
        let accessToken = getSession("accessToken");
        if (accessToken) {
          removeSession("accessToken");
          let existingSessionID = getSession("x-session-id");
          if (existingSessionID) {
            response = await axios.get(
              `${API_URL.GET_SESSION}?sessionId=${existingSessionID}`
            );

            if (response?.data?.sessionId) {
              token = response.data.accessToken;

              setSession("x-session-id", response.data.sessionId);
              setSession("accessToken", response.data.accessToken);
            } else {
              removeSession("x-session-id");
              //setSession("accessToken", 'error');
              gotoLogin();
            }
            // Retry the original request with the new token
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          } else {
            gotoLogin();
            throw response;
          }
        } else {
          const delayRetryRequest = new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 1500);
          });
          return delayRetryRequest.then(() => axios(originalRequest));
        }
      } catch (error) {
        console.error(error);
        if (error?.response?.data?.message === "User session Expired") {
          gotoLogin();
        }
      }
    }

    return Promise.reject(error);
  }
);
