import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TB_FILTER_TITLE,
  TT_FILTER_TITLE_IATA,
  TT_FILTER_TITLE_CONFIRMATION,
  TT_FILTER_TITLE_GUEST_NAME,
  TT_GUEST_NAME_ERROR,
  SS_FILTER_TITLE_MARKET_SEGMENT,
  SS_FILTER_TITLE_NO_PAY_REASON,
  SS_FILTER_TITLE_ARRIVAL_DATE,
  SS_FILTER_TITLE_ADJUSTMENT_CODE,
  SS_FILTER_TITLE_FOLIO,
  SS_FILTER_TITLE_INVOICE_INDICATOR,
  SS_FILTER_TITLE_MOD,
  SS_FILTER_SELECTED,
} from "../../../../utils/constants";
import Form from "../../../../components/form/Form";
import {
  FOLIO_FILTER_OPTION,
  MOD_FILTER_OPTION,
  STAY_SUMMERY_DEFAULT_TABLE_PAYLOAD,
} from "../../../../utils/config";
import {
  convertObjToArray,
  convertObjToArrayMultiSelect,
} from "../../../../utils/utils";
import { validateIATAFilter } from "../../../../store/feature/landing/landingSlice";

export default function SummaryFilter({ filterCallback }) {
  const { filterOptions, stayTablePayload } = useSelector(
    (state) => state.staySummary
  );
  const dispatch = useDispatch();

  const handleSubmit = (filterData) => {
    if (filterCallback) {
      filterData =
        filterData && Object.keys(filterData)?.length
          ? filterData
          : STAY_SUMMERY_DEFAULT_TABLE_PAYLOAD.filterBy;
      filterCallback(filterData);
    }
  };

  const modDefault = stayTablePayload.filterBy.modIndicator;
  const noPayDefault = stayTablePayload.filterBy.noPayReason;
  const adjustCodeDefault = stayTablePayload.filterBy.adjustCode;
  const folioDefault = stayTablePayload.filterBy.folioIndicator;
  const segmentDefault = stayTablePayload.filterBy.marketSegment;

  const today = new Date();
  const maxDate = today.toISOString().slice(0, 10);
  const marketSegOptions = convertObjToArray(
    filterOptions["marketSegments"]
  ).sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
  const config = {
    form: {
      iataNumber: {
        label: TT_FILTER_TITLE_IATA,
        type: "text",
        maxLength: 8,
        fieldWidth: 200,
        fieldClassName: "flex-none",
        rules: {
          required: false,
          blurMode: true,
          validate: (value) => {
            let result = true;
            if (value?.length) {
              result = dispatch(validateIATAFilter(value));
            }
            return result;
          },
        },
      },
      confoNumber: {
        label: TT_FILTER_TITLE_CONFIRMATION,
        type: "text",
        maxLength: 10,
        fieldWidth: 200,
        fieldClassName: "flex-none",
        rules: {
          required: false,
        },
      },
      marketSegment: {
        label: SS_FILTER_TITLE_MARKET_SEGMENT,
        type: "multiselectv2",
        placeholder: SS_FILTER_SELECTED,
        defaultValue: segmentDefault,
        options: marketSegOptions,
        rules: {
          required: false,
        },
      },
      noPayReason: {
        label: SS_FILTER_TITLE_NO_PAY_REASON,
        type: "multiselectv2",
        placeholder: SS_FILTER_SELECTED,
        defaultValue: noPayDefault,
        options: convertObjToArrayMultiSelect(
          filterOptions["noPayReasons"],
          "keyValue"
        ),
        rules: {
          required: false,
        },
      },
      folioIndicator: {
        label: SS_FILTER_TITLE_FOLIO,
        type: "multiselectv2",
        placeholder: SS_FILTER_SELECTED,
        defaultValue: folioDefault,
        options: convertObjToArrayMultiSelect(FOLIO_FILTER_OPTION, "keyValue"),
        rules: {
          required: false,
        },
      },
      invoiceIndicator: {
        label: SS_FILTER_TITLE_INVOICE_INDICATOR,
        type: "checkbox",
        fieldClassName: "invoice_filter",
        rules: {
          required: false,
        },
      },
      guestName: {
        label: TT_FILTER_TITLE_GUEST_NAME,
        type: "text",
        fieldWidth: 300,
        fieldClassName: "flex-none",
        rules: {
          required: false,
          minLength: 2,
          message: TT_GUEST_NAME_ERROR,
        },
      },
      arrivalDate: {
        label: SS_FILTER_TITLE_ARRIVAL_DATE,
        type: "date",
        maxDate: maxDate,
        fieldWidth: 275,
        fieldClassName: "flex-none",
      },
      adjustCode: {
        label: SS_FILTER_TITLE_ADJUSTMENT_CODE,
        type: "multiselectv2",
        placeholder: SS_FILTER_SELECTED,
        defaultValue: adjustCodeDefault,
        options: convertObjToArrayMultiSelect(
          filterOptions["adjustmentCodes"],
          "keyValue"
        ),
        fieldWidth: 275,
        fieldClassName: "flex-none",
        rules: {
          required: false,
        },
      },
      modIndicator: {
        label: SS_FILTER_TITLE_MOD,
        type: "multiselectv2",
        placeholder: SS_FILTER_SELECTED,
        options: convertObjToArrayMultiSelect(MOD_FILTER_OPTION, "keyValue"),
        defaultValue: modDefault,
        fieldWidth: 275,
        fieldClassName: "flex-none",
        rules: {
          required: false,
        },
      },
    },
  };

  return (
    <div className="mx-2">
      <h3 className="font-30 weight-700">{TB_FILTER_TITLE}</h3>
      <Form
        config={config}
        submitCallBack={handleSubmit}
        className={`transient-summary-filters filter-form m-0 g-0`}
      />
    </div>
  );
}
