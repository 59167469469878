import React from "react";

export default function SortIcon({ sortBy }) {
  return (
    <i className={`sorticon ${sortBy}`}>
      <i className="arrow up"></i>
      <i className="arrow down"></i>
    </i>
  );
}
