import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalDialog from "../modal/ModalDialog";
import { Button } from "react-bootstrap";
import {
  extendSession,
  resetSessionTimer,
  userLogout,
  usersInfoReceived,
} from "../../store/feature/landing/landingSlice";
import { clearSession, getSession } from "../../utils/utils";

function SessionTimeoutPopUp() {
  const [sessionExpired, setSessionExpired] = useState(false);
  const { sessionReset, logOut, sessionDuration } = useSelector(
    (state) => state.landing
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let timer, fulltimeTimer, autoTimer;

  const initCustomScripts = () => {
    document.addEventListener("click", function () {
      dispatch(resetSessionTimer());
    });
  };

  useEffect(() => {
    initCustomScripts();
  }, []);

  const handleLogOut = () => {
    setSessionExpired(false);
    clearSession();
    dispatch(usersInfoReceived(null));
    dispatch(userLogout());
  };

  const timeOutValue = useMemo(() => {
    const sessionDuration = Number(getSession("sessionDuration"));
    return {
      fiveMinBefore: sessionDuration
        ? (sessionDuration - 6) * 60000
        : 10 * 60000,
      autoExtendTime: sessionDuration
        ? (sessionDuration - 4) * 60000
        : 12 * 60000,
      timeExpired: sessionDuration
        ? (sessionDuration - 2) * 60000
        : (15 - 2) * 60000,
    };
  }, [sessionDuration]);

  useEffect(() => {
    clearTimeout(timer);
    if (timeOutValue.fiveMinBefore) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setTimeout(() => {
        setSessionExpired(true);
      }, timeOutValue.fiveMinBefore);
      if (logOut) {
        clearTimeout(timer);
      }
    }

    clearTimeout(fulltimeTimer);
    if (timeOutValue.timeExpired) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      fulltimeTimer = setTimeout(() => {
        handleLogOut();
      }, timeOutValue.timeExpired);
      if (logOut) {
        clearTimeout(fulltimeTimer);
      }
    }
    return () => {
      clearTimeout(timer);
      clearTimeout(fulltimeTimer);
    };
  }, [sessionReset, logOut, sessionDuration]);

  useEffect(() => {
    clearInterval(autoTimer);
    if (timeOutValue.autoExtendTime && !logOut) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      autoTimer = setInterval(() => {
        const now = new Date().getTime();
        const expiryVal = getSession("sessionExpiry");
        const expiryTime = new Date(expiryVal).getTime() - 3 * 60000;
        if (expiryVal && now >= expiryTime) {
          dispatch(extendSession());
        }
      }, 30000);
      if (logOut) {
        clearInterval(autoTimer);
      }
    }
    return () => {
      clearInterval(autoTimer);
    };
  }, [logOut, sessionDuration]);

  const handleExtendSession = () => {
    dispatch(extendSession());
    setSessionExpired(false);
    dispatch(resetSessionTimer());
  };

  return (
    <>
      {sessionExpired ? (
        <ModalDialog show={true} closeButton={false}>
          <p className="mt-5">
            Your session will expire in 5 minutes due to inactivity. Select the
            Extend button to continue browsing the site or Log Off button if
            finished.
          </p>
          <p className="align-right">
            <Button
              className="m-1"
              variant="dark"
              onClick={handleExtendSession}
            >
              {"Extend"}
            </Button>
            <Button className="m-1" onClick={handleLogOut}>
              {"Log Off"}
            </Button>
          </p>
        </ModalDialog>
      ) : null}
    </>
  );
}

export default SessionTimeoutPopUp;
