/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchBatchDetails,
  updateCurrancy,
  closeBroadcastMsg,
} from "../../store/feature/landing/landingSlice";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CurrencyButton from "../../components/currencybutton/CurrencyButton";
import TransientBatchOverview from "../../components/transientbatchoverview/TransientBatchOverview";
import GroupBatchOverview from "../../components/groupbatchoverview/GroupBatchOverview";
import BroadcastMessage from "../../components/broadcastmessage/BroadcastMessage";
import {
  LANDING_NO_DATA_FOUND,
  WELCOME_TITLE,
  WELCOME_MESSAGE,
  LANDING_LABELS,
  AUDIT_RESEARCH,
  AUDIT_IATA,
  AUDIT_DESC_START,
  AUDIT_DESC_END,
  AUDIT_CONTROL_OFFICE_MSG,
  CURRENCY_TYPE,
} from "../../utils/constants";
import LoadingBar from "../../components/loadingbar/LoadingBar";
import {
  CURRENCY_CONST_PROPERTY,
  GROUP_EVENT_AUDIT_DAYS,
  transientTaxTextColor,
} from "../../utils/config";
import AmountDisplay from "../../components/amountdisplay/AmountDisplay";
import { fetchBroadcastMsg } from "../../store/feature/admin/adminSlice";
import { removeHtml } from "../../utils/utils";

export default function Landing() {
  const {
    overviewLoading,
    userInfo,
    transientBatch,
    groupBatch,
    researchAudit,
    transientTax,
    showBroadcastMsg,
    currencyType,
    propCode,
  } = useSelector((state) => state.landing);
  const { broadcastMsg } = useSelector((state) => state.admin);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const showTransientTax =
    transientTax?.list?.filter((element) => parseInt(element?.count) > 0)
      ?.length > 0
      ? true
      : false;
  useEffect(() => {
    if (!overviewLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [overviewLoading]);

  useEffect(() => {
    dispatch(fetchBroadcastMsg());
  }, []);

  useEffect(() => {
    if (userInfo && propCode?.length) {
      const overviewReqBody = {
        currencyType: currencyType.toLowerCase(),
        type: "all",
        filterBy: {
          gsaRangeDays: GROUP_EVENT_AUDIT_DAYS,
        },
      };
      dispatch(fetchBatchDetails(propCode, overviewReqBody));
    }
  }, []);

  useEffect(() => {
    dispatch(updateCurrancy(CURRENCY_CONST_PROPERTY));
  }, [propCode]);

  const renderViewAll = (path, buttonTitle = "View All") => {
    return (
      <Link
        className="btn btn-primary float-end my-3 curved-corner px-5 ft-primary"
        to={path}
      >
        {buttonTitle}
      </Link>
    );
  };

  const overviewLandingClasses = {
    flexColClass: "mx-2",
    cardClass: "my-0",
    imageClass: "p-2",
    colClass: "p-1",
  };
  const removedHtmlResult = broadcastMsg?.message
    ? removeHtml(broadcastMsg.message)
    : "";
  return (
    <>
      {loading ? <LoadingBar global /> : null}
      {showBroadcastMsg && removedHtmlResult.length > 0 ? (
        <BroadcastMessage
          message={broadcastMsg.message}
          onCloseBroadcastMsg={() => dispatch(closeBroadcastMsg())}
        />
      ) : null}
      <h2 className="mt-5 weight-700">{WELCOME_TITLE}</h2>
      <p className="mt-3">{WELCOME_MESSAGE}</p>

      <div className="flex-box-row flex-align-end mx-5">
        <CurrencyButton />
      </div>

      <Container fluid className="mt-5">
        <Row>
          <Col className="p-0">
            <h2 className="weight-700">{LANDING_LABELS.TANSIENT_OVERVIEW}</h2>
            {(transientBatch &&
              transientBatch !== null &&
              Object.keys(transientBatch).length && (
                <TransientBatchOverview
                  data={transientBatch}
                  isLanding
                  overviewLandingClasses={overviewLandingClasses}
                  currencyType={currencyType}
                  column={groupBatch && Object.keys(groupBatch).length ? 2 : 4}
                />
              )) || <p className="mt-3"> {LANDING_NO_DATA_FOUND} </p>}
            {renderViewAll(`/transient/All/PROPERTY`)}
          </Col>
          {groupBatch && Object.keys(groupBatch).length ? (
            <>
              <Col lg={1}></Col>
              <Col>
                <h2 className="weight-700">{LANDING_LABELS.GROUP_OVERVIEW}</h2>
                {groupBatch?.noBatch ? (
                  <p className="mt-3"> {LANDING_NO_DATA_FOUND} </p>
                ) : (
                  <GroupBatchOverview
                    data={groupBatch}
                    isLanding
                    overviewLandingClasses={overviewLandingClasses}
                    currencyType={currencyType}
                  />
                )}
                {renderViewAll(`/groupblock/All/PROPERTY`)}
              </Col>
            </>
          ) : null}
        </Row>
        <Row className="mt-5">
          {showTransientTax ? (
            <>
              <Col className="ps-0">
                <Row>
                  <h2 className="text-start weight-700 mb-4">
                    {LANDING_LABELS.TRANSIENT_TAXES}
                  </h2>
                  {transientTax?.list.length ? (
                    <Row className="pe-0">
                      {transientTax.list.map((transientTaxData, key) => {
                        return (
                          <Col
                            className={`transient-tax ${
                              key === transientTax.list.length - 1 ? "pe-0" : ""
                            }`}
                            key={`landing-tt-card-${key + 1}`}
                          >
                            <div className="curved-box">
                              <div className="px-2 pt-3 pb-2 text-center">
                                <h6 className="paragraph-wrap">
                                  {transientTaxData.duration}
                                </h6>
                                <p
                                  className="font-22 mb-2"
                                  style={{
                                    color:
                                      transientTaxData.count.toString() === "0"
                                        ? transientTaxTextColor[0]?.color
                                        : transientTaxTextColor[
                                            transientTaxData?.duration
                                          ]?.color,
                                  }}
                                >
                                  <b>{transientTaxData.count}</b>
                                </p>
                                <h6 className="transient-tax-value">
                                  <AmountDisplay
                                    amountDetail={`${
                                      currencyType === CURRENCY_TYPE.USD
                                        ? transientTaxData?.amountUsd.split(
                                            "."
                                          )[0]
                                        : transientTaxData?.amount.split(".")[0]
                                    } ${
                                      currencyType === CURRENCY_TYPE.USD
                                        ? CURRENCY_TYPE.USD
                                        : transientTax?.currencyCode
                                    }`}
                                    hasMultipleCurrency={
                                      transientTax.multipleCurrency
                                    }
                                    currencyType={currencyType}
                                  />
                                </h6>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <p className="mt-3"> {LANDING_NO_DATA_FOUND} </p>
                  )}
                  <div className="mt-3">{renderViewAll("/transienttaxes")}</div>
                </Row>
              </Col>
              <Col lg={1}></Col>
            </>
          ) : (
            <>
              <Col></Col>
              <Col lg={1}></Col>
            </>
          )}
          <Col className="pr-0">
            {groupBatch &&
            Object.keys(groupBatch).length &&
            researchAudit &&
            Object.keys(researchAudit).length ? (
              <Row>
                <h2 className="text-start weight-700">
                  {LANDING_LABELS.GROUP_EVENT_AUDIT}
                </h2>
                <>
                  {researchAudit.displayMsg === "" ? (
                    <p className="mt-3">
                      {AUDIT_DESC_START} <b>{researchAudit.groupsCount}</b>{" "}
                      {AUDIT_DESC_END}
                    </p>
                  ) : (
                    <p>{AUDIT_CONTROL_OFFICE_MSG}</p>
                  )}
                  <p className="mb-0 ps-5">{AUDIT_IATA}</p>
                  <p className="ps-5 mb-10">{AUDIT_RESEARCH}</p>

                  <div>
                    {renderViewAll("/group_research_audit", "Audit Now")}
                  </div>
                </>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
}
