import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { formatDate } from "../../utils/utils";

export default function DatePicker({
  maxDate,
  onChange,
  value,
  minDate,
  disabled = false,
  format = "YYYY-MM-DD",
  width,
}) {
  const [displayVal, setDisplayVal] = useState();
  const inputElement = useRef(null);
  useEffect(() => {
    setDisplayVal(value?.length ? formatDate(value, format) : "");
  }, [value]);

  return (
    <div className="date-picker" style={{ width: width }}>
      <Form.Group controlId="date-range">
        <Form.Control
          placeholder={format}
          readOnly
          type="text"
          defaultValue={displayVal}
          min={minDate || ""}
          onClick={() => {
            inputElement.current.showPicker();
          }}
          disabled={disabled}
        />
        <span
          onClick={() => {
            inputElement.current.showPicker();
          }}
          className="date-icon no-border"
        ></span>
        <input
          ref={inputElement}
          type="date"
          className="date-hidden no-border"
          value={displayVal}
          onChange={(e) => {
            setDisplayVal(
              e.target.value?.length ? formatDate(e.target.value, format) : ""
            );
            onChange(e.target.value);
          }}
          max={maxDate}
          min={minDate || ""}
          disabled={disabled}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        ></input>
      </Form.Group>
    </div>
  );
}
