import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TB_FILTER_TITLE,
  TB_FILTER_PLACEHOLDER_STATUS,
  TT_FILTER_TITLE_IATA,
  TT_FILTER_TITLE_CONFIRMATION,
  TT_FILTER_TITLE_ARRIVAL_FROM,
  TT_FILTER_TITLE_ARRIVAL_TO,
  TT_FILTER_TITLE_GUEST_NAME,
  FILTER_TITLE_STATUS,
  TT_GUEST_NAME_ERROR,
} from "../../../../utils/constants";
import Form from "../../../../components/form/Form";
import {
  TT_STATUS_FILTER_OPTION,
  TT_STATUS_TYPE,
  TT_TABLE_RESET_FILTERBY_PAYLOAD,
} from "../../../../utils/config";
import { validateIATA } from "../../../../store/feature/transienttax/transientTaxSlice";

export default function TransientTaxFilter({ filterCallback }) {
  const [arrivalFromSelectedDate, setArrivalFromSelectedDate] = useState(null);
  const [arrivalToSelectedDate, setArrivalToSelectedDate] = useState(null);

  const { payloadForTable, minArrivalFrom } = useSelector(
    (state) => state.transientTax
  );
  const dispatch = useDispatch();

  const handleSubmit = (filterData) => {
    if (filterData === undefined) {
      setArrivalToSelectedDate(null);
      setArrivalFromSelectedDate(null);
    }
    if (filterData?.status) {
      filterData.status = filterData.status.map((element) =>
        Object.keys(TT_STATUS_TYPE).find((k) => TT_STATUS_TYPE[k] === element)
      );
    }

    if (filterCallback) {
      filterData =
        filterData && Object.keys(filterData)?.length
          ? filterData
          : TT_TABLE_RESET_FILTERBY_PAYLOAD;
      filterCallback(filterData);
    }
  };

  let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const maxDate = yesterday.toISOString().slice(0, 10);

  const statusDefault = payloadForTable.filterBy.status.map(
    (data) => TT_STATUS_TYPE[data]
  );

  const config = useMemo(() => {
    return {
      form: {
        iata: {
          label: TT_FILTER_TITLE_IATA,
          type: "text",
          maxLength: 8,
          fieldWidth: 200,
          fieldClassName: "flex-none",
          rules: {
            required: false,
            blurMode: true,
            validate: (value) => {
              let result = true;
              if (value?.length) {
                result = dispatch(validateIATA(value));
              }
              return result;
            },
          },
        },
        confirmationNumber: {
          label: TT_FILTER_TITLE_CONFIRMATION,
          type: "text",
          fieldWidth: 200,
          maxLength: 10,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        arrivalFrom: {
          label: TT_FILTER_TITLE_ARRIVAL_FROM,
          type: "date",
          minDate: minArrivalFrom,
          maxDate: arrivalToSelectedDate || maxDate,
          fieldWidth: 275,
          fieldClassName: "flex-none",
          rules: {
            validate: (value) => {
              setArrivalFromSelectedDate(value);
              return true;
            },
          },
        },
        arrivalTo: {
          label: TT_FILTER_TITLE_ARRIVAL_TO,
          type: "date",
          minDate: arrivalFromSelectedDate,
          maxDate: maxDate,
          fieldWidth: 275,
          fieldClassName: "flex-none",
          rules: {
            validate: (value) => {
              setArrivalToSelectedDate(value);
              return true;
            },
          },
        },
        guestName: {
          label: TT_FILTER_TITLE_GUEST_NAME,
          type: "text",
          fieldWidth: 300,
          fieldClassName: "flex-none",
          rules: {
            required: false,
            minLength: 2,
            message: TT_GUEST_NAME_ERROR,
          },
        },
        status: {
          label: FILTER_TITLE_STATUS,
          placeholder: TB_FILTER_PLACEHOLDER_STATUS,
          type: "multiselect",
          defaultValue: statusDefault,
          options: TT_STATUS_FILTER_OPTION,
          fieldClassName: "flex-none",
          fieldWidth: 225,
          rules: {
            required: false,
          },
        },
      },
    };
  }, [
    payloadForTable.filterBy,
    arrivalToSelectedDate,
    arrivalFromSelectedDate,
  ]);

  return (
    <div className="mx-2">
      <h3 className="font-30 weight-700">{TB_FILTER_TITLE}</h3>
      <Form
        config={config}
        submitCallBack={handleSubmit}
        className={`transient-tax-filters filter-form m-0 g-0`}
      />
    </div>
  );
}
