import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../../utils/Api_URL";

const initialState = {
  loading: true,
  broadcastMsg: {
    message: "",
    error: false,
  },
  saveSuccess: false,
  saveFailure: false,
  serverError: false,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    saveBroadcastMsgSuccess: (state, action) => {
      state.loading = false;
      state.saveSuccess = true;
    },
    updateBroadcastMsg: (state, action) => {
      state.loading = false;
      state.broadcastMsg.message = action.payload;
      state.saveSuccess = false;
    },
    saveBroadcastMsgError: (state, action) => {
      state.broadcastMsg.message = "";
      state.saveFailure = true;
      state.loading = false;
    },
    saveBroadcastMsgInprogress: (state, action) => {
      state.loading = true;
    },
    fetchBroadcastMsgFailure: (state, action) => {
      state.broadcastMsg.message = "";
      state.serverError = true;
    },
    updateSaveStatus: (state, action) => {
      state.saveSuccess = action.payload;
    },
  },
});

export const {
  saveBroadcastMsgSuccess,
  saveBroadcastMsgError,
  saveBroadcastMsgInprogress,
  updateBroadcastMsg,
  fetchBroadcastMsgFailure,
  updateSaveStatus,
} = adminSlice.actions;

export default adminSlice.reducer;

export const saveBroadcastMsg = (payload) => async (dispatch) => {
  try {
    dispatch(saveBroadcastMsgInprogress());
    const response = await axios.post(
      API_URL.SAVE_BROADCAST_MSG,
      encodeURIComponent(payload)
    );
    dispatch(saveBroadcastMsgSuccess(response.data));
  } catch (error) {
    dispatch(saveBroadcastMsgError());
  }
};

export const fetchBroadcastMsg = () => async (dispatch) => {
  try {
    dispatch(saveBroadcastMsgInprogress());
    const response = await axios.get(API_URL.GET_BROADCAST_MSG);
    let data = decodeURIComponent(response.data).split("+").join(" ").trim();
    if (data.endsWith("=")) {
      data = data.slice(0, -1);
    }
    dispatch(updateBroadcastMsg(data));
  } catch (error) {
    dispatch(fetchBroadcastMsgFailure());
  }
};
