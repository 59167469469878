import React from "react";

export default function NoMatch() {
  return (
    <>
      <div className="container">
        <div className="text-center m-5 error_boundary"></div>
      </div>
    </>
  );
}
