import React from "react";

export default function ContactUs() {
  return (
    <>
      <div className="marginHorizontal vh-body">
        <div className="contactus mb-5">
          <div className="w-100 my-5 bg p-2">
            <h1 className="text-center mx-5 mb-4 bold"> Contact Us</h1>
            <h2 className="text-center mx-5">We are here to help</h2>
          </div>
          <div>
            <p>
              For <b>CTAC Application Support</b>, open a SNOW ticket using the{" "}
              <a
                target="_blank"
                className="btn-link"
                href="https://marriott.service-now.com/msp"
                rel="noreferrer"
              >
                Marriott Service Portal
              </a>{" "}
              (search for “CTAC” and select CTAC-HELP-DESK-Support) or contact
              the CTAC Help Desk by{" "}
              <a href="mailto:ctac.help@marriott.com" className="btn-link">
                email
              </a>
              .{" "}
            </p>
            <p>
              For <b>commission inquiry support</b>, our Intermediary Partner
              Care team is available Monday-Friday, 7:30 am-6:00 pm CT, and can
              assist you with the following:{" "}
            </p>
            <div className="mx-5">
              <p>
                {" "}
                For <b>Group Commissions:</b>{" "}
              </p>
              <ul>
                <li>
                  <a
                    className="btn-link"
                    href="mailto:group.commissions@marriott.com"
                  >
                    <i className="email-icon icon_pos" />
                    group.commissions@marriott.com
                  </a>{" "}
                </li>
                <li>
                  <i className="phone-icon icon_pos" />
                  +1 (800) 445-1551, Option 2{" "}
                </li>
                <li>
                  {" "}
                  <i className="phone-icon icon_pos" />
                  +1 (402) 390-1651 (Outside the US/Canada)
                </li>
              </ul>
              <p>
                For <b>Transient Commissions:</b>{" "}
              </p>
              <ul>
                <li>
                  <a className="btn-link" href="mailto:ta.help@marriott.com">
                    <i className="email-icon icon_pos" />
                    ta.help@marriott.com
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <i className="phone-icon icon_pos" />
                  +1 (800) 831-3100, Option 3{" "}
                </li>
                <li>
                  {" "}
                  <i className="phone-icon icon_pos" />
                  +1 (402) 390-1651 (Outside the US/Canada)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
