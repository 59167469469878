import { useDispatch, useSelector } from "react-redux";
import ModalDialog from "../../../components/modal/ModalDialog";
import "./AddStay.scss";
import { useEffect, useState } from "react";
import { STAY_SUMMERY_DEFAULT_TABLE_PAYLOAD } from "../../../utils/config";
import { Button } from "react-bootstrap";
import {
  fetchLinkedStayDetails,
  linkStayDetailsReducer,
} from "../../../store/feature/staysummary/staySummarySlice";
import {
  ARRIVAL_DATE_LINK_STAY_LABEL,
  CANCEL_BTN_LABEL_LINK_STAY,
  CONFO_LINK_STAY_LABEL,
  CONTINUE_BTN_LABEL_LINK_STAY,
  GUEST_NAME_LINK_STAY_LABEL,
  LINK_STAY_MSG,
  ROOM_NIGHTS_LINK_STAY_LABEL,
} from "../../../utils/constants";
import { formatDate } from "../../../utils/utils";

export default function LinkedStayModel({
  modalShow,
  closeLinkStay,
  editRow,
  stayBatchDetail,
  continueEditCallback,
}) {
  const dispatch = useDispatch();
  const { linkStayDetails } = useSelector((state) => state.staySummary);
  const [radioSequenceNumber, setRadioSequenceNumber] = useState(
    editRow.sequenceNumber
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (editRow) {
      dispatch(
        fetchLinkedStayDetails({
          payloadForStayTable: {
            ...STAY_SUMMERY_DEFAULT_TABLE_PAYLOAD,
            filterBy: {
              ...STAY_SUMMERY_DEFAULT_TABLE_PAYLOAD.filterBy,
              confoNumber: editRow.confirmationNumber,
            },
            batchNum1: stayBatchDetail.batchNum1,
            batchNum2: stayBatchDetail.batchNum2,
          },
          propertyCode: stayBatchDetail.propertyCode,
        })
      );
    }
    return () => {
      dispatch(linkStayDetailsReducer({}));
    };
  }, [editRow]);

  const onOptionChange = (e) => {
    setRadioSequenceNumber(e.target.value);
  };

  const onContinueAction = () => {
    const selectedStay = linkStayDetails.find(
      (stay) => stay.sequenceNumber === radioSequenceNumber
    );
    if (continueEditCallback && selectedStay) {
      continueEditCallback({
        ...selectedStay,
        enableEdit: true,
        enableSave: false,
      });
    }
    closeLinkStay();
  };
  const onCancelAction = () => {
    closeLinkStay();
  };

  return (
    <ModalDialog
      show={modalShow}
      handleOnShowClose={closeLinkStay}
      ariaLabelledby="contained-modal-title-vcenter"
      className={`linked-stay-overlay`}
      size={"lg"}
    >
      <div className="linked-stay">
        <h4 className="weight-700">{"Linked Stays"}</h4>
      </div>
      <p className="mt-3">{LINK_STAY_MSG}</p>
      <div className="data-table-server">
        <table>
          <tr>
            <th style={{ width: "30px" }}></th>
            <th style={{ width: "200px" }}>{GUEST_NAME_LINK_STAY_LABEL}</th>
            <th style={{ width: "80px" }}>{CONFO_LINK_STAY_LABEL}</th>
            <th style={{ width: "100px" }}>{ARRIVAL_DATE_LINK_STAY_LABEL}</th>
            <th style={{ width: "110px" }}>{ROOM_NIGHTS_LINK_STAY_LABEL}</th>
          </tr>

          {linkStayDetails?.length &&
            linkStayDetails.map((stay) => (
              <tr>
                <td>
                  <input
                    type="radio"
                    name="selectedLinkedStay"
                    checked={stay.sequenceNumber === radioSequenceNumber}
                    value={stay.sequenceNumber}
                    onChange={onOptionChange}
                  />
                </td>
                <td>{stay.guestName}</td>
                <td>{stay.confirmationNumber}</td>
                <td>{formatDate(stay.arrivalDate, "DDMMMYY").toUpperCase()}</td>
                <td>{stay.roomNights}</td>
              </tr>
            ))}
        </table>
      </div>
      <diiv className={"flex-box-row flex-align-center mt-4"}>
        <Button variant={"primary"} onClick={onContinueAction}>
          {CONTINUE_BTN_LABEL_LINK_STAY}
        </Button>
        <Button
          variant="link"
          className="text-decoration-none"
          onClick={onCancelAction}
        >
          {CANCEL_BTN_LABEL_LINK_STAY}
        </Button>
      </diiv>
    </ModalDialog>
  );
}
