import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../../utils/Api_URL";

let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
let toDate = new Date(new Date().setDate(new Date().getDate() + 31));

const initialState = {
  loadingAuditBlock: true,
  auditBlock: [],
  auditTotalCount: 1,
  payloadForTable: {
    filterBy: {
      eventStartDateFrom: tomorrow.toISOString().slice(0, 10),
      eventStartDateTo: toDate.toISOString().slice(0, 10),
      status: [],
      groupBlockName: "",
      groupBlockCode: "",
      suspsnseInd: [],
      fpIndicator: [],
      actIndicator: [],
      comIndicator: [],
      groupIataNumber: "",
      quoteNumber: "",
      blankIATA: false,
    },
    sortBy: {
      sortColumn: "",
      sortValue: "",
    },
    pageNumber: 1,
    pageSize: 25,
  },
  auditInternalError: false,
  serverError: false,
};

export const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    updateAuditBlockLoading: (state, action) => {
      state.loadingAuditBlock = action.payload;
    },
    updateAuditTableParams: (state, action) => {
      state.payloadForTable = { ...state.payloadForTable, ...action.payload };
    },
    auditBlockReceived: (state, action) => {
      state.auditBlock = action.payload.events;
      state.auditTotalCount = action.payload.totalCount;
      state.loadingAuditBlock = false;
    },
    updateAuditInternalError: (state, action) => {
      state.auditInternalError = action.payload;
    },
    updateServerError: (state, action) => {
      state.serverError = action.payload;
    },
  },
});

export const {
  updateAuditBlockLoading,
  updateAuditTableParams,
  auditBlockReceived,
  updateAuditInternalError,
  updateServerError,
} = auditSlice.actions;

export default auditSlice.reducer;

export const fetchAuditBlock = (payload) => async (dispatch) => {
  try {
    dispatch(updateAuditBlockLoading(true));
    dispatch(updateAuditInternalError(false));
    let response = {};
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(
        API_URL.GET_AUDITBLOCK,
        payload.payloadForTable
      );
    } else {
      response = await axios.post(
        `${API_URL.GET_AUDITBLOCK}/${payload.propCode}`,
        payload.payloadForTable
      );
    }
    if (response?.data?.events) {
      dispatch(auditBlockReceived(response.data));
      dispatch(updateAuditBlockLoading(false));
    } else {
      throw response;
    }
  } catch (error) {
    dispatch(updateAuditBlockLoading(false));
    if (error?.response?.status === 404) {
      dispatch(updateServerError(true));
    } else {
      dispatch(updateAuditInternalError(true));
    }
  }
};
