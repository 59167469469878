import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./GroupResearchAndAudit.scss";
import DataTableServerSide from "../../components/datatableserverside/DataTableServerSide";
import AuditFilter from "./component/auditFilter/AuditFilter";
import Pagination from "../../components/pagination/Pagination";
import PayDetail from "./component/detailView/PayDetail";
import {
  fetchAuditBlock,
  updateAuditTableParams,
} from "../../store/feature/audit/auditSlice";
import {
  GA_DEFAULT_TABLE_PAYLOAD,
  GRA_LINK,
  GRA_TABLE_NAME,
} from "../../utils/config";
import {
  ACTIVATION_TOOLTIP_LABEL,
  CANCELLED_TOOLTIP_LABEL,
  COMMISSION_TOOLTIP_LABEL,
  DOWNLOAD_EXPORT_LABEL,
  FASTPAY_TOOLTIP_LABEL,
  GA_CONTENT_FIRST_PART,
  GA_CONTENT_SECOND_PART,
  GA_PROGRAM_PROCEDURE,
  GA_TABLE_HEADER_ACT,
  GA_TABLE_HEADER_BLOCK_CODE,
  GA_TABLE_HEADER_BLOCK_NAME,
  GA_TABLE_HEADER_COM,
  GA_TABLE_HEADER_CXL,
  GA_TABLE_HEADER_END_DATE,
  GA_TABLE_HEADER_FP,
  GA_TABLE_HEADER_IATA,
  GA_TABLE_HEADER_QUOTE,
  GA_TABLE_HEADER_SP,
  GA_TABLE_HEADER_START_DATE,
  GROUP_AUDIT_CONTROL_OFFICE_MSG,
  LANDING_NO_DATA_FOUND,
  PAGINATION_LABEL_AUDIT,
  STATUS,
  SUSPENSE_TOOLTIP_LABEL,
} from "../../utils/constants";
import LoadingBar from "../../components/loadingbar/LoadingBar";
import InfoBar from "../../components/infobar/InfoBar";
import {
  buildExportURL,
  formatDate,
  getGRAStatusBG,
  scrollToTop,
} from "../../utils/utils";
import { API_URL } from "../../utils/Api_URL";
import { getDownloadFile } from "../../store/feature/landing/landingSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlertMsg } from "../../utils/context/alertMessageContext";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const renderCellGroupIata = (row) =>
  row?.original.groupIataNumber ? (
    <OverlayTrigger
      delay={{ show: 50, hide: 400 }}
      placement="top"
      overlay={
        <Popover className="pop-over iata-name">
          <div className="font-12 text-center">
            <span>{row?.original.cmTaName}</span>
          </div>
        </Popover>
      }
    >
      <span>{row?.original.groupIataNumber}</span>
    </OverlayTrigger>
  ) : null;

const renderCellEndDate = ({ row }) =>
  row?.original?.eventEndDate
    ? formatDate(row?.original?.eventEndDate, "DDMMMYY").toUpperCase()
    : null;

const renderCellStartDate = ({ row }) =>
  row?.original?.eventStartDate
    ? formatDate(row?.original?.eventStartDate, "DDMMMYY").toUpperCase()
    : null;

export default function GroupResearchAndAudit() {
  const {
    loadingAuditBlock,
    auditBlock,
    payloadForTable,
    auditTotalCount,
    auditInternalError,
    serverError,
  } = useSelector((state) => state.audit);
  const { alertContextDispatch } = useAlertMsg();
  let { state: routeData } = useLocation();
  const navigate = useNavigate();
  const [forcedClearExpand, setForcedClearExpand] = useState(0);
  const [expanded, setExpanded] = useState({});
  const [pendingGroupBlockId, setPendingGroupBlockId] = useState("");
  const { propCode, isRespGroup } = useSelector((state) => state.landing);
  const dispatch = useDispatch();

  const updateGAListParam = (params) => {
    dispatch(updateAuditTableParams({ ...payloadForTable, ...params }));
  };

  const applySort = (sortBy) => {
    if (sortBy) {
      updateGAListParam({ sortBy: sortBy });
    }
  };

  useEffect(() => {
    let defaultFilter = {
      ...GA_DEFAULT_TABLE_PAYLOAD,
    };
    updateGAListParam({ ...defaultFilter });
  }, []);

  useEffect(() => {
    if (routeData) {
      let defaultFilter = {
        ...GA_DEFAULT_TABLE_PAYLOAD,
      };
      setPendingGroupBlockId(routeData.groupBlockId);
      defaultFilter = { ...routeData.filterFromRoute };
      routeData && navigate("/group_research_audit", { replace: true });
      updateGAListParam({ ...defaultFilter });
    }
  }, [routeData]);

  let auditDataTimer;
  useEffect(() => {
    clearTimeout(auditDataTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    auditDataTimer = setTimeout(() => {
      if (auditBlock.length) {
        if (pendingGroupBlockId.length) {
          const blockIndex = auditBlock.findIndex(
            (item) => item.groupBlockId === pendingGroupBlockId
          );
          setExpanded({ [blockIndex]: true });
          const elem = document.getElementById(
            `pay-history-${pendingGroupBlockId}`
          );
          setPendingGroupBlockId("");
          if (elem) {
            elem.scrollIntoView();
          }
        } else {
          setExpanded({});
        }
      }
    }, 1000);

    setForcedClearExpand((state) => state + 1);
    return () => {
      clearTimeout(auditDataTimer);
    };
  }, [auditBlock]);

  let dataChangeTimer;
  useEffect(() => {
    if (dataChangeTimer) {
      clearTimeout(dataChangeTimer);
    }
    dataChangeTimer = setTimeout(() => {
      if (propCode) {
        dispatch(fetchAuditBlock({ payloadForTable, propCode }));
      }
    }, 50);
    return () => {
      if (dataChangeTimer) {
        clearTimeout(dataChangeTimer);
      }
    };
  }, [payloadForTable, propCode]);

  const renderCellStatus = ({ row }) => {
    const statusBG = getGRAStatusBG(
      row?.original?.status,
      row?.original.partiallyProcessedFlag
    );
    return (
      <div
        id={`pay-history-${row.original.groupBlockId}`}
        className={`table-button font-12  
              ${statusBG ? statusBG : "m-0 p-0"}`}
        onClick={
          row.getCanExpand() && statusBG.length
            ? row.getToggleExpandedHandler()
            : () => {}
        }
      >
        {row?.original?.status || ""}
      </div>
    );
  };

  const column = useMemo(
    () => [
      {
        accessorKey: "groupIataNumber",
        header: () => GA_TABLE_HEADER_IATA,
        footer: (props) => props.column.id,
        cell: ({ row }) => renderCellGroupIata(row),
        width: 120,
      },
      {
        accessorKey: "groupBlockCode",
        header: () => GA_TABLE_HEADER_BLOCK_CODE,
        footer: (props) => props.column.id,
        width: 120,
      },
      {
        accessorKey: "groupBlockName",
        header: () => GA_TABLE_HEADER_BLOCK_NAME,
        sortDescFirst: false,
        footer: (props) => props.column.id,
        width: 200,
      },
      {
        accessorKey: "eventStartDate",
        header: () => GA_TABLE_HEADER_START_DATE,
        footer: (props) => props.column.id,
        width: 100,
        cell: renderCellStartDate,
      },
      {
        accessorKey: "eventEndDate",
        header: () => GA_TABLE_HEADER_END_DATE,
        footer: (props) => props.column.id,
        width: 100,
        cell: renderCellEndDate,
      },
      {
        accessorKey: "quoteNumber",
        header: () => GA_TABLE_HEADER_QUOTE,
        footer: (props) => props.column.id,
        width: 120,
      },
      {
        accessorKey: "status",
        header: () => STATUS,
        footer: (props) => props.column.id,
        cell: renderCellStatus,
        width: 140,
      },
      {
        accessorKey: "susPayIndicator",
        header: () => GA_TABLE_HEADER_SP,
        tooltip: SUSPENSE_TOOLTIP_LABEL,
        footer: (props) => props.column.id,
        width: 60,
      },
      {
        accessorKey: "actIndicator",
        header: () => GA_TABLE_HEADER_ACT,
        tooltip: ACTIVATION_TOOLTIP_LABEL,
        footer: (props) => props.column.id,
        width: 60,
      },
      {
        accessorKey: "comIndicator",
        header: () => GA_TABLE_HEADER_COM,
        tooltip: COMMISSION_TOOLTIP_LABEL,
        footer: (props) => props.column.id,
        width: 60,
      },
      {
        accessorKey: "fpIndicator",
        header: () => GA_TABLE_HEADER_FP,
        tooltip: FASTPAY_TOOLTIP_LABEL,
        footer: (props) => props.column.id,
        width: 60,
      },
      {
        accessorKey: "delIndicator",
        header: () => GA_TABLE_HEADER_CXL,
        tooltip: CANCELLED_TOOLTIP_LABEL,
        footer: (props) => props.column.id,
        width: 60,
      },
    ],
    []
  );

  const applyFilter = (filterBy) => {
    let filterByReceived = { ...filterBy };
    if (
      "status" in filterByReceived &&
      filterByReceived.status[0] === undefined
    ) {
      filterByReceived.status = [];
    }
    updateGAListParam({
      filterBy: {
        ...GA_DEFAULT_TABLE_PAYLOAD.filterBy,
        ...filterByReceived,
      },
      pageNumber: 1,
    });
  };

  const downloadXLS = () => {
    let fileURL = buildExportURL({
      url: `${API_URL.GET_AUDIT_EXPORT}/${propCode}`,
      filterBy: payloadForTable.filterBy,
    });
    const fileName = `Group Audit -${propCode}-${formatDate(
      payloadForTable.filterBy.eventStartDateFrom,
      "DDMMMYY"
    ).toUpperCase()}-${formatDate(
      payloadForTable.filterBy.eventStartDateTo,
      "DDMMMYY"
    ).toUpperCase()}`;
    dispatch(getDownloadFile(fileURL, fileName));
  };

  useEffect(() => {
    if (serverError) {
      alertContextDispatch({
        type: "groupResearchAuditServerErrorShow",
      });
    }
  }, [serverError]);

  useEffect(() => {
    if (auditInternalError) {
      alertContextDispatch({
        type: "groupResearchAuditInternalErrorShow",
      });
    }
  }, [auditInternalError]);

  return (
    <>
      {loadingAuditBlock ? <LoadingBar global /> : null}
      {isRespGroup ? (
        <InfoBar variant="dark" enableTimerClose={false} showIcon={false}>
          {GROUP_AUDIT_CONTROL_OFFICE_MSG}
        </InfoBar>
      ) : null}
      <p className="mt-0">
        <span>{GA_CONTENT_FIRST_PART}</span>{" "}
        <a href={GRA_LINK} className="view-stays-link" target="blank">
          {GA_PROGRAM_PROCEDURE}
        </a>{" "}
        <span>{GA_CONTENT_SECOND_PART}</span>
      </p>
      <AuditFilter filterCallback={applyFilter} />
      <hr />
      <div className="d-flex justify-content-end">
        <button
          onClick={downloadXLS}
          className="download-btn btn btn-link text-decoration-none font-12 mt-auto py-0"
        >
          <i className="download-icon" /> <span>{DOWNLOAD_EXPORT_LABEL}</span>
        </button>
      </div>
      {auditBlock?.length ? (
        <div className="mb-2">
          <Pagination
            className="pagination-bar"
            showOnlyInfo={true}
            currentPage={payloadForTable.pageNumber}
            totalCount={auditTotalCount}
            pageSize={payloadForTable.pageSize}
            pageinationInfoType={PAGINATION_LABEL_AUDIT}
          />
        </div>
      ) : null}
      <DataTableServerSide
        className="gra_table"
        data={[...auditBlock]}
        tableName={GRA_TABLE_NAME}
        defaultExpandedRows={expanded}
        column={column}
        sortCallback={applySort}
        dynamicColumnWidth={30}
        enableDetailView={true}
        fixedWidthColumn={true}
        getRowCanExpand={() => true}
        childTable={(data) => <PayDetail data={data} />}
        forcedClearExpand={forcedClearExpand}
      ></DataTableServerSide>
      {auditBlock?.length ? (
        <div>
          <Pagination
            className="pagination-bar"
            currentPage={payloadForTable.pageNumber}
            totalCount={auditTotalCount}
            pageSize={payloadForTable.pageSize}
            onItemPerPageChange={(pageSize, resetPage) => {
              scrollToTop(500);
              if (resetPage) {
                updateGAListParam({
                  pageNumber: resetPage,
                  pageSize,
                });
              } else {
                updateGAListParam({
                  pageNumber: payloadForTable.pageNumber,
                  pageSize,
                });
              }
            }}
            onPageChange={(page) => {
              scrollToTop(500);
              updateGAListParam({
                pageNumber: page,
                pageSize: payloadForTable.pageSize,
              });
            }}
          />
        </div>
      ) : (
        <p className="ms-2">{LANDING_NO_DATA_FOUND}</p>
      )}
    </>
  );
}
