/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef, useState, useEffect, useContext } from "react";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  flexRender,
} from "@tanstack/react-table";
import SortIcon from "../SortIcon";
import editIcon from "../../assets/edit.png";
import viewIcon from "../../assets/view.png";
import { Button } from "react-bootstrap";
import { SAVE_CHANGES } from "../../utils/constants";
import { GRA_TABLE_NAME } from "../../utils/config";
import { ResizeContext } from "../../utils/resizeContext";

export default function DataTableServerSide({
  tableName = "",
  className = "",
  data,
  column,
  getRowCanExpand,
  selectedRowCallback,
  saveActionCallback,
  sortCallback,
  enableDetailView = false,
  enableCheckboxSelection = false,
  enableRowEdit = false,
  enableRowSave = false,
  children,
  isSelectAllDisabled,
  resetSelection,
  dynamicColumnWidth = 0,
  saveColumnWidth = 80,
  fixedWidthColumn = false,
  actionButtons = { start: [], end: [] },
  childTable,
  multiOpen = true, // multiple accordion will be opened if set true
  saveButtonIndex = 0,
  rowEditCallback,
  defaultExpandedRows,
  forcedClearExpand = 0,
}) {
  const { contextCMT, setContextCMT } = useContext(ResizeContext);
  const [sorting, setSorting] = useState(null);
  const [saveRow, setSaveRow] = useState({
    row: {},
    changeDetection: false,
  });

  const [size, setSize] = useState(null);
  let ref = useRef();

  const updateDimensions = () => {
    if (ref.current) setSize(ref.current.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    setSize(ref.current.clientWidth);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [ref?.current?.clientWidth]);

  useEffect(() => {
    setContextCMT({ ...contextCMT, tableWidth: size });
  }, [size]);

  useEffect(() => {
    if (saveActionCallback) {
      saveActionCallback(saveRow.row);
    }
  }, [saveRow]);

  const renderDetailViewCell = ({ row }) => (
    <div
      style={{
        paddingLeft: `${row.depth * 2}rem`,
      }}
    >
      {row.getCanExpand() ? (
        <div
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: "pointer" },
          }}
        >
          {row.getIsExpanded() ? (
            <i className="accordian arrow-up"></i>
          ) : (
            <i className="accordian arrow-down"></i>
          )}
        </div>
      ) : (
        "🔵"
      )}
    </div>
  );

  const renderPayHistoryExpandCell = ({ row }) => {
    return row.original.payHistory?.length ? (
      <div
        style={{
          paddingLeft: `${row.depth * 2}rem`,
        }}
      >
        {row.getCanExpand() ? (
          <div
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: { cursor: "pointer" },
            }}
          >
            {row.getIsExpanded() ? (
              <i className="accordian arrow-up"></i>
            ) : (
              <i className="accordian arrow-down"></i>
            )}
          </div>
        ) : (
          "🔵"
        )}
      </div>
    ) : (
      <div
        className="disable_opcity"
        style={{
          paddingLeft: `${row.depth * 2}rem`,
        }}
      >
        <i className="accordian arrow-down"></i>
      </div>
    );
  };

  const renderEditHeader = ({ table }) => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

  const renderExpandHeader = () => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

  const renderEditCell = ({ row }) => (
    <Button
      variant="link"
      style={{
        paddingLeft: `${row.depth * 4}rem`,
      }}
      onClick={() => {
        if (rowEditCallback) {
          rowEditCallback(row.original);
        }
      }}
    >
      {row.original.enableEdit ? (
        <img width="18" height="18" alt="Edit" src={editIcon} />
      ) : (
        <img width="18" height="18" alt="View Stay" src={viewIcon} />
      )}
    </Button>
  );

  const renderSaveHeader = ({ table }) => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

  const renderSaveCell = ({ row }) => (
    <div
      style={{
        paddingLeft: `${row.depth * 4}rem`,
      }}
    >
      <Button
        variant="link"
        disabled={!row.original?.enableSave}
        onClick={() => {
          setSaveRow((prevState) => ({
            row: row.original,
            changeDetection: !prevState.changeDetection,
          }));
        }}
        className="table-body-text ps-4 save_all_icon save_all_stay"
      >
        {SAVE_CHANGES}
      </Button>
    </div>
  );

  const renderCheckboxHeader = ({ table }) => (
    <IndeterminateCheckbox
      disabled={isSelectAllDisabled}
      {...{
        checked: table.getIsAllRowsSelected() && !isSelectAllDisabled,
        indeterminate: table.getIsSomeRowsSelected() && !isSelectAllDisabled,
        onChange: table.getToggleAllRowsSelectedHandler(),
      }}
    />
  );

  const renderCheckboxCell = ({ row }) => (
    <div
      style={{
        paddingLeft: `${row.depth * 2}rem`,
      }}
    >
      <IndeterminateCheckbox
        disabled={row.original.isDisabled}
        {...{
          id: row.original.uniqueId,
          checked: row.getIsSelected() && !row.original.isDisabled,
          indeterminate: row.getIsSomeSelected() && !row.original.isDisabled,
          onChange: !row.original.isDisabled
            ? row.getToggleSelectedHandler()
            : () => {
                return;
              },
        }}
      />
    </div>
  );

  const columns = useMemo(() => {
    const updatedColumn = [...column];
    if (enableDetailView) {
      updatedColumn.unshift({
        accessorKey: "expander",
        header: renderExpandHeader,
        enableSorting: false,
        width: dynamicColumnWidth,
        cell:
          tableName === GRA_TABLE_NAME
            ? renderPayHistoryExpandCell
            : renderDetailViewCell,
      });
    }
    if (enableRowEdit) {
      updatedColumn.unshift({
        accessorKey: "edit",
        header: renderEditHeader,
        enableSorting: false,
        width: dynamicColumnWidth,
        cell: renderEditCell,
      });
    }

    if (enableCheckboxSelection) {
      updatedColumn.unshift({
        accessorKey: "rowselector",
        width: dynamicColumnWidth,
        header: renderCheckboxHeader,
        enableSorting: false,
        cell: renderCheckboxCell,
      });
    }

    if (enableRowSave) {
      updatedColumn.splice(saveButtonIndex, 0, {
        accessorKey: "enableSave",
        header: renderSaveHeader,
        enableSorting: false,
        width: saveColumnWidth,
        cell: renderSaveCell,
      });
    }
    return updatedColumn;
  }, [column, isSelectAllDisabled]);

  const [expanded, setExpanded] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand,
    state: {
      expanded,
      rowSelection,
      sorting,
    },
    onRowSelectionChange: setRowSelection,
    onExpandedChange: multiOpen
      ? setExpanded
      : (callBack) => {
          let expandedData = callBack();
          if (Object.keys(expandedData)[0] === Object.keys(expanded)[0]) {
            expandedData = {};
          }
          setExpanded(expandedData);
        },
    onSortingChange: setSorting,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    //debugTable: true,
  });

  let expandTableTimer;
  useEffect(() => {
    clearTimeout(expandTableTimer);
    expandTableTimer = setTimeout(() => {
      if (defaultExpandedRows) {
        setExpanded({ ...defaultExpandedRows });
      }
      if (expandTableTimer) {
        clearTimeout(expandTableTimer);
      }
    }, 50);
    return () => {
      clearTimeout(expandTableTimer);
    };
  }, [defaultExpandedRows]);

  useEffect(() => {
    if (forcedClearExpand) {
      setExpanded({});
    }
  }, [forcedClearExpand]);

  useEffect(() => {
    if (!expanded) {
      if (defaultExpandedRows) {
        setExpanded({ ...defaultExpandedRows });
      }
    }
  }, [data]);

  useEffect(() => {
    if (selectedRowCallback) {
      selectedRowCallback(
        table.getSelectedRowModel().flatRows.map((row) => row.original)
      );
    }
  }, [rowSelection]);

  useEffect(() => {
    table.resetRowSelection();
  }, [resetSelection]);

  useEffect(() => {
    if (sortCallback && sorting) {
      const formatedSort = () => {
        if (sorting?.length) {
          const item = sorting[0];
          return {
            sortColumn: item.id,
            sortValue: item.desc ? "DESC" : "ASC",
          };
        } else {
          return {};
        }
      };
      sortCallback(formatedSort());
    }
  }, [sorting]);

  return (
    <div className={`data-table-server ${className}`}>
      <div className="h-2" />
      <table
        ref={ref}
        className={`${fixedWidthColumn ? "fixed-width-table" : ""}`}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <>
              <tr key={`serverside-tr-head-unique-${headerGroup.id}`}>
                {headerGroup.headers.map((header, index) => {
                  const title = header.column.columnDef?.tooltip
                    ? {
                        title: header.column.columnDef.tooltip,
                      }
                    : {};
                  return (
                    <th
                      key={`serverside-th-head-${header.id}`}
                      colSpan={header.colSpan}
                      {...{
                        style: header.column.columnDef.width
                          ? {
                              width: header.column.columnDef.width + "px",
                            }
                          : {},
                        ...title,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          key={`serverside-th-head-sort-${header.id}`}
                          {...{
                            className: header.column.getCanSort()
                              ? `cursor-pointer select-none text-style sorting_enabled ${header.column.columnDef.className}`
                              : `text-style ${header.column.columnDef.className}`,
                            onClick:
                              header.column.id !== "rowselector"
                                ? header.column.getToggleSortingHandler()
                                : null,
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {
                            {
                              asc: <SortIcon sortBy="asc"></SortIcon>,
                              desc: <SortIcon sortBy="desc"></SortIcon>,
                            }[header.column.getIsSorted()]
                          }
                          {header.column.getCanSort() &&
                          !header.column.getIsSorted() ? (
                            <SortIcon />
                          ) : null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
              {actionButtons.start.length || actionButtons.end.length ? (
                <tr className="action-button">
                  {actionButtons.start.length
                    ? actionButtons.start.map((actionItem, index) => {
                        return (
                          <th
                            colSpan={3}
                            key={`${actionItem?.label}${index + 1}`}
                          >
                            <Button
                              variant="link"
                              onClick={
                                actionItem?.disabled
                                  ? () => {
                                      return false;
                                    }
                                  : actionItem?.actionEvent
                              }
                              disabled={actionItem?.disabled}
                              className={`action_btn ${actionItem?.className}`}
                            >
                              {actionItem?.label}
                            </Button>
                          </th>
                        );
                      })
                    : null}
                  <th
                    colSpan={
                      headerGroup.headers.length -
                      (actionButtons.start.length * 3 +
                        actionButtons.end.length * 3)
                    }
                  ></th>

                  {actionButtons.end.length
                    ? actionButtons.end.map((actionItem, index) => {
                        return (
                          <th
                            colSpan={3}
                            key={`${actionItem?.label}${index + 1}`}
                          >
                            <Button
                              variant="link"
                              onClick={
                                actionItem?.disabled
                                  ? () => {
                                      return false;
                                    }
                                  : actionItem?.actionEvent
                              }
                              disabled={actionItem?.disabled}
                              className={`float-right action_btn ps-4 ${actionItem?.className}`}
                            >
                              {actionItem?.label}
                            </Button>
                          </th>
                        );
                      })
                    : null}
                </tr>
              ) : null}
            </>
          ))}
        </thead>
        <tbody>
          {table.getCoreRowModel().rows.map((row) => {
            // const childrenWithProps = children
            //   ? React.Children.map(children, (child) => {
            //       if (React.isValidElement(child)) {
            //         return React.cloneElement(child, { row: row.original });
            //       }
            //       return child;
            //     })
            //   : null;
            return (
              <>
                <tr key={`serverside-tr-body-${row.id}`}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        className={`table-body-text ${
                          cell.column.getCanSort() ? "sorting_enabled" : ""
                        }`}
                        key={`serverside-tr-td-body-${cell.id}`}
                        colSpan={1}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
                {enableDetailView && row.getIsExpanded() && (
                  <tr>
                    {/* 2nd row is a custom 1 cell row */}
                    <td colSpan={row.getVisibleCells().length}>
                      {childTable(row.original, row.id)}
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
    </div>
  );
}

function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
  const ref = useRef();

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}
