import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function RichTextEditor({
  defaultValue,
  onChange,
  style = { width: "50%", height: "300px" },
}) {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  return (
    <ReactQuill
      style={{ ...style }}
      theme="snow"
      value={value}
      onChange={handleChange}
    />
  );
}
