import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../../utils/Api_URL";
import {
  GB_BATCH_DEFAULT_TABLE_PAYLOAD,
  GB_DEFAULT_TABLE_PAYLOAD,
  INACTIVE_IATA_INDICATOR,
} from "../../../utils/config";
import { validateIsQ50 } from "../../../utils/utils";
import {
  COMM_NON_COMM_SUCCESS_MSG,
  DEFER_PAYMENT_SUCCESS_MSG,
  SAVE_FAILED,
  IATA_ERROR,
  TT_IATA_INACTIVE_ERROR,
} from "../../../utils/constants";

const initialState = {
  loading: false,
  groupDetail: [],
  groupRowDetail: {},
  groupTotalCount: 1,
  payloadForTable: GB_DEFAULT_TABLE_PAYLOAD,
  payloadForGroupBatch: { ...GB_BATCH_DEFAULT_TABLE_PAYLOAD },
  propertyInfo: {},
  adjustmentReviewDetails: {},
  addAdjustmentSuccess: false,
  addAdjustmentFailed: "",
  reviewAdjustmentFailed: "",
  iataDetail: {},
  disableAdjustmentSave: false,
  reviewApproveSuccess: false,
  reviewApproveFailed: false,
  groupBlockIndex: "",
  deferPaymentSuccess: false,
  deferPaymentSaveFailed: false,
  commNonCommSuccess: false,
  userAction: "",
};

export const groupBlockSlice = createSlice({
  name: "groupBlock",
  initialState,
  reducers: {
    updateGroupBlockLoading: (state, action) => {
      state.loadingGroupBlock = action.payload;
    },
    updateGroupOverviewLoading: (state, action) => {
      state.loadingGroupOverview = action.payload;
    },
    groupBlockReceived: (state, action) => {
      state.groupDetail = action.payload.groupBlockDetails;
      state.groupTotalCount = action.payload.count;
      state.loading = false;
    },
    groupBlockDetailLoading: (state) => {
      state.groupRowDetailLoading = true;
    },
    groupBlockDetailReceived: (state, action) => {
      state.groupRowDetail = {
        ...state.groupRowDetail,
        [action.payload.mhSeqNum]: {
          innerBlock: action.payload.groupRowDetail?.insideList,
          outerBlock: action.payload.groupRowDetail?.outsideList,
          batches: action.payload.batchDetails,
          batchesTotalCount: action.payload.totalCount,
          groupRowDetailLoading: false,
          getBatchError: action.payload.getBatchError,
          hasData: true,
        },
      };
    },
    groupBatchOverviewReceived: (state, action) => {
      state.groupBatchOverview = action.payload?.groupBatch;
    },
    groupBlockDetailClear: (state, action) => {
      // state.groupRowDetail = {
      //     innerBlock: [],
      //     outerBlock: [],
      // };
      state.groupRowDetailLoading = false;
    },
    updateGroupBlockTableParams: (state, action) => {
      state.payloadForTable = { ...state.payloadForTable, ...action.payload };
    },
    updateOverviewInternalError: (state, action) => {
      state.overviewInternalError = action.payload;
    },
    updateTableInternalError: (state, action) => {
      state.tableInternalError = action.payload;
    },
    updateServerError: (state, action) => {
      state.serverError = action.payload;
    },
    updateAdjustmentReview: (state, action) => {
      state.adjustmentReviewDetails = { ...action.payload };
    },
    reviewAdjustmentError: (state, action) => {
      state.reviewAdjustmentFailed = action.payload;
    },
    saveAdjustmentSuccess: (state, action) => {
      if (action.payload.reviewDetails) {
        state.reviewDetails = action.payload.reviewDetails;
      }
      state.addAdjustmentSuccess = action.payload.addAdjustmentSuccess;
    },
    saveAdjustmentFailed: (state, action) => {
      state.addAdjustmentFailed = action.payload.addAdjustmentFailed;
    },
    updatePropDetailResponse: (state, action) => {
      state.propertyInfo[action.payload?.propertyDetail?.propCode] = {
        propertyDetail: action.payload.propertyDetail,
        IsQ50: action.payload.IsQ50,
      };
    },
    updateIATAResponse: (state, action) => {
      state.iataDetail = action.payload;
    },
    disableAdjustmentAction: (state, action) => {
      state.disableAdjustmentSave = action.payload;
    },
    gBReviewApproveSuccess: (state, action) => {
      state.reviewApproveSuccess = action.payload;
    },
    gBReviewApproveFailed: (state, action) => {
      state.reviewApproveFailed = action.payload;
    },
    updateGroupBlockIndex: (state, action) => {
      state.groupBlockIndex = action.payload;
    },
    updateGroupBatchTableParams: (state, action) => {
      state.payloadForGroupBatch = {
        ...state.payloadForGroupBatch,
        ...action.payload,
      };
    },
    updateDeferPayment: (state, action) => {
      state.groupRowDetail[action.payload.blockId] = {
        ...state.groupRowDetail[action.payload.blockId],
        deferPaymentInd: action.payload.deferPaymentInd,
        deferPaymentDetails: { ...action.payload },
      };
    },
    groupBatchReviewApproveSuccess: (state, action) => {
      state.reviewApproveSuccess = action.payload.reviewApproveSuccess;
      state.userAction = action.payload.userAction;
    },
    groupBatchReviewApproveFailed: (state, action) => {
      state.reviewApproveFailed = action.payload;
    },
    updateCommNonCommStatus: (state, action) => {
      state.commNonCommSuccess = action.payload;
    },
  },
});

export const {
  updateGroupBlockLoading,
  updateGroupOverviewLoading,
  groupBlockReceived,
  groupBlockDetailLoading,
  groupBlockDetailReceived,
  groupBatchOverviewReceived,
  groupBlockDetailClear,
  updateGroupBlockTableParams,
  updateOverviewInternalError,
  updateTableInternalError,
  updateServerError,
  updateAdjustmentReview,
  updatePropDetailResponse,
  saveAdjustmentSuccess,
  saveAdjustmentFailed,
  updateIATAResponse,
  reviewAdjustmentError,
  disableAdjustmentAction,
  gBReviewApproveSuccess,
  gBReviewApproveFailed,
  updateGroupBlockIndex,
  updateGroupBatchTableParams,
  updateDeferPayment,
  groupBatchReviewApproveSuccess,
  groupBatchReviewApproveFailed,
  updateCommNonCommStatus,
} = groupBlockSlice.actions;

export default groupBlockSlice.reducer;

export const fetchGroupBlock = (payload, propCode) => async (dispatch) => {
  try {
    dispatch(updateGroupBlockLoading(true));
    dispatch(updateTableInternalError(false));
    let response = {};
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(API_URL.GET_GROUPBLOCK, payload.tablePayload);
    } else {
      response = await axios.post(
        `${API_URL.GET_GROUPBLOCK}/${payload.propCode}`,
        payload.tablePayload
      );
    }
    if (response?.data?.groupBlockDetails) {
      dispatch(groupBlockReceived(response.data));
      dispatch(updateGroupBlockLoading(false));
    } else {
      throw response;
    }
  } catch (error) {
    console.error(error);
    dispatch(updateGroupBlockLoading(false));
    if (error?.response?.status === 404) {
      dispatch(updateServerError(true));
    } else {
      dispatch(updateTableInternalError(true));
    }
  }
};

export const fetchGroupBatchOverview =
  (propertyCode, transientOverviewReqBody) => async (dispatch) => {
    try {
      dispatch(updateGroupOverviewLoading(true));
      dispatch(updateOverviewInternalError(false));
      dispatch(updateServerError(false));
      let response = {};
      if (process.env.REACT_APP_ENV === "local") {
        response = await axios.get(
          `${API_URL.GET_BATCH_OVERVIEW}`,
          transientOverviewReqBody
        );
      } else {
        response = await axios.post(
          `${API_URL.GET_BATCH_OVERVIEW}/${propertyCode}`,
          transientOverviewReqBody
        );
      }
      dispatch(updateGroupOverviewLoading(false));
      dispatch(groupBatchOverviewReceived(response?.data));
    } catch (error) {
      dispatch(updateGroupOverviewLoading(false));
      if (error?.response?.status === 404) {
        dispatch(updateServerError(true));
      } else {
        dispatch(updateOverviewInternalError(true));
      }
    }
  };

export const fetchGroupBlockRowDetails = (payload) => async (dispatch) => {
  let response = {};
  try {
    dispatch(groupBlockDetailLoading());
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(API_URL.GET_GROUPBLOCK_DETAILS);
    } else {
      response = await axios.post(
        `${API_URL.GET_GROUPBLOCK_DETAILS}${payload.propertyCode}/batches/${payload.mhSeqNum}`,
        payload.params
      );
    }
    dispatch(
      groupBlockDetailReceived({
        ...response.data,
        mhSeqNum: payload.mhSeqNum,
        groupRowDetail: payload.groupRowDetail,
        getBatchError: false,
      })
    );
  } catch (error) {
    dispatch(
      groupBlockDetailReceived({
        batchDetails: [],
        mhSeqNum: payload.mhSeqNum,
        groupRowDetail: payload.groupRowDetail,
        getBatchError: true,
      })
    );
    if (error?.response?.status === 404) {
      dispatch(updateServerError(true));
    }
    console.error(error);
  }
};

export const getPropertyDetails = (propCode) => async (dispatch) => {
  try {
    let response = {};
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(`${API_URL.GET_PROPERTY_DETAILS}`);
    } else {
      response = await axios.get(`${API_URL.GET_PROPERTY_DETAILS}/${propCode}`);
    }
    if (response?.data?.propertyList) {
      const IsQ50 = validateIsQ50(response.data.propertyList[0]);
      dispatch(
        updatePropDetailResponse({
          propertyDetail: response.data.propertyList[0],
          IsQ50,
        })
      );
    }
  } catch (error) {
    dispatch(updateServerError(true));
  }
};

export const reviewAdjustment = (payload) => async (dispatch) => {
  let response = {};
  try {
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(API_URL.REVIEW_ADJUSTMENT);
    } else {
      response = await axios.post(
        `${API_URL.REVIEW_ADJUSTMENT}/${payload.propertyCode}`,
        payload.requestParam
      );
    }
    dispatch(
      updateAdjustmentReview({
        reviewDetails: { ...response.data },
        mhSeqNum: payload.requestParam.mhSequenceNumber,
        groupRowDetail: payload.groupRowDetail,
      })
    );
    dispatch(reviewAdjustmentError(""));
  } catch (error) {
    dispatch(updateAdjustmentReview({}));
    dispatch(reviewAdjustmentError(error?.response?.data?.message));
    console.error(error);
  }
  dispatch(disableAdjustmentAction(false));
};

export const saveAdjustment = (payload) => async (dispatch) => {
  try {
    if (process.env.REACT_APP_ENV === "local") {
      await axios.get(API_URL.SAVE_ADJUSTMENT);
    } else {
      await axios.post(
        `${API_URL.SAVE_ADJUSTMENT}/${payload.propertyCode}`,
        payload.requestParam
      );
    }
    dispatch(
      saveAdjustmentSuccess({
        reviewDetails: {},
        addAdjustmentSuccess: true,
      })
    );
    dispatch(
      saveAdjustmentFailed({
        addAdjustmentFailed: "",
      })
    );
  } catch (error) {
    dispatch(
      saveAdjustmentSuccess({
        addAdjustmentSuccess: false,
      })
    );
    dispatch(
      saveAdjustmentFailed({
        addAdjustmentFailed: error?.response?.data?.message,
      })
    );
    console.error(error);
  }
  dispatch(disableAdjustmentAction(false));
};

export const validateIATA = (iataNum) => async (dispatch) => {
  try {
    const iataResponse = await axios.get(`${API_URL.VALIDATE_IATA}/${iataNum}`);
    const indicator = iataResponse?.data?.indicator;
    const isValid =
      indicator === null || indicator === ""
        ? IATA_ERROR
        : indicator === INACTIVE_IATA_INDICATOR
        ? TT_IATA_INACTIVE_ERROR
        : true;

    if (isValid) {
      dispatch(updateIATAResponse(iataResponse.data));
    } else {
      dispatch(updateIATAResponse({}));
    }
    return isValid;
  } catch (error) {
    dispatch(updateIATAResponse({}));
    return false;
  }
};

export const deferPaymentSave = async (payload) => {
  try {
    await axios.post(
      `${API_URL.SAVE_DEFER_PAYMENT}/${payload.deferPayment}`,
      payload.requestParam
    );
    return { success: true, message: DEFER_PAYMENT_SUCCESS_MSG };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `${SAVE_FAILED}, ${
        error.response.data?.message
          ? "Reason:" + error.response.data.message + ""
          : ""
      } `,
    };
  }
};

export const getDeferPayment = (payload) => async (dispatch) => {
  let response = {};
  try {
    const url = API_URL.GET_DEFER_PAYMENT.replace(
      "@property",
      payload.propertyCode
    );
    // if (process.env.REACT_APP_ENV === "local") {
    //   response = await axios.get(url);
    // } else {
    response = await axios.get(`${url}/${payload.blockId}`);
    // }
    dispatch(updateDeferPayment({ ...response.data[0] }));
  } catch (error) {
    console.error(error);
  }
};

export const saveBlockStatus = async (payload) => {
  try {
    await axios.post(
      `${API_URL.SAVE_BLOCK_STATUS}${payload.propertyCode}`,
      payload.requestParam
    );
    return { success: true, message: COMM_NON_COMM_SUCCESS_MSG };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `${SAVE_FAILED}, ${
        error.response.data?.message
          ? "Reason:" + error.response.data.message + ""
          : ""
      } `,
    };
  }
};

export const updateGroupBatchStatus =
  (actionStatus, updateTBReqBody) => async (dispatch) => {
    try {
      dispatch(updateGroupBlockLoading(true));
      dispatch(updateServerError(false));
      dispatch(groupBatchReviewApproveFailed(false));
      let response = {};
      response = await axios.post(
        `${API_URL.PUT_BATCH_REVIEW_APPROVE}/${actionStatus}`,
        updateTBReqBody
      );
      dispatch(updateGroupBlockLoading(false));
      if (response.data.status && response.data.status === true) {
        //Success case
        dispatch(
          groupBatchReviewApproveSuccess({
            reviewApproveSuccess: true,
            userAction: actionStatus,
          })
        );
      } else {
        //Error case
        dispatch(groupBatchReviewApproveFailed(true));
      }
    } catch (error) {
      dispatch(updateGroupBlockLoading(false));
      //Error case
      if (error?.response?.status === 404) {
        dispatch(updateServerError(true));
      } else {
        dispatch(groupBatchReviewApproveFailed(true));
      }
    }
  };
