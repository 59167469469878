import React from "react";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import AmountDisplay from "../amountdisplay/AmountDisplay";
import { VIEW_ALL } from "../../utils/constants";

export default function CardDisplay({
  icon,
  duration,
  statusDetail,
  amountDetail,
  stayDetail,
  statusDetailLine,
  linkTo,
  imageClass,
  isLinkShown,
  classNames,
  hasMultipleCurrency,
  currencyType,
  styleApplied,
}) {
  return (
    <Col className={classNames?.colClass}>
      <Card className={classNames?.cardClass}>
        <Card.Body className={classNames?.cardBodyClass}>
          <div className="d-flex justify-content-center text-center">
            <div className="flex-column ">
              {duration && <p className="fw-bold my-0 py-0">{duration}</p>}
              <div>
                <h4 className="fw-bold font-20 text-wrap-next-line">
                  {icon && (
                    <img
                      alt="cardIcon"
                      className={`${imageClass} me-2`}
                      src={icon}
                    />
                  )}
                  {amountDetail && (
                    <AmountDisplay
                      amountDetail={amountDetail}
                      hasMultipleCurrency={hasMultipleCurrency}
                      currencyType={currencyType}
                    />
                  )}
                  {stayDetail && <span>{stayDetail}</span>}
                </h4>
              </div>
              <div className="my-0">
                <span style={{ color: `${styleApplied}` }}>{statusDetail}</span>
                {statusDetailLine}
              </div>
              {isLinkShown && (
                <Link className="card-link btn-link-color" to={linkTo}>
                  {VIEW_ALL}
                </Link>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
