import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  validatePropCode,
  updatePropertyCodeError,
  changedPropertyCode,
  updatePropertyCode,
  usersInfoReceived,
} from "../../store/feature/landing/landingSlice";
import { getActiveRoute, getSession, parseJwt } from "../../utils/utils";
import "./PropertyInput.scss";
import FormField from "../formfield/FormField";
import { PLACEHOLDER_CHANGE_PROPERTY } from "../../utils/constants";

export default function PropertyInput() {
  const {
    propCode,
    isInvalidPropertyCodeError,
    propertyErrorMsg,
    propCodeChanged,
    userInfo,
  } = useSelector((state) => state.landing);
  const dispatch = useDispatch();

  const [propertyCode, setPropertyCode] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const componentName = getActiveRoute(location.pathname);

  useEffect(() => {
    if (propCodeChanged) {
      changedPropertyCode(false);
      navigate(`/${componentName}`);
      navigate(0);
    }
  }, [propCode]);

  const validatePropertyCode = async (propCodeParam) => {
    if (propCodeParam?.length === 5 && propCodeParam.match(/^[0-9a-zA-Z]+$/)) {
      const validateAPI = await validatePropCode(propCodeParam);
      if (validateAPI.status === 200) {
        const propCodeChanged = true;
        const accessToken = getSession("accessToken");
        const userInfo_data = parseJwt(accessToken);
        dispatch(updatePropertyCode(propCodeParam));
        dispatch(changedPropertyCode(propCodeChanged));
        dispatch(usersInfoReceived(userInfo_data, propCodeParam));
      } else {
        dispatch(updatePropertyCodeError(true));
      }
    } else {
      dispatch(updatePropertyCodeError(true));
    }
  };

  const handleKeyDown = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      validatePropertyCode(event?.target?.value);
    }
  };

  const updateTextboxPropertyChange = (e) => {
    setPropertyCode(e?.target?.value?.toUpperCase());
    if (isInvalidPropertyCodeError) {
      dispatch(updatePropertyCodeError(false));
    }
  };

  const updateSelectboxPropertyChange = (value) => {
    setPropertyCode(value);
    validatePropertyCode(value);
    if (isInvalidPropertyCodeError) {
      dispatch(updatePropertyCodeError(false));
    }
  };

  const propList = userInfo?.propertyList
    ? ["", ...userInfo.propertyList]
    : [""];

  return (
    <div className="property_title_bar change_property_box">
      {userInfo?.abovePropertyUser ? (
        <>
          <h6>Enter a Property</h6>
          <div className="input-container">
            <input
              className={
                "input-field " +
                (isInvalidPropertyCodeError ? "error-border" : "")
              }
              type="text"
              maxLength="5"
              placeholder={PLACEHOLDER_CHANGE_PROPERTY}
              value={propertyCode}
              onChange={updateTextboxPropertyChange}
              onKeyDown={handleKeyDown}
            />
            <i
              className="icon-property"
              onClick={() => validatePropertyCode(propertyCode)}
            ></i>
          </div>
        </>
      ) : (
        <>
          <h6>Select a Property</h6>
          <div className="form-item">
            <FormField
              type="select"
              options={propList}
              value={propertyCode}
              onChange={updateSelectboxPropertyChange}
            />
          </div>
        </>
      )}
      <p className={isInvalidPropertyCodeError ? "error-message" : "hidden"}>
        {propertyErrorMsg || "Invalid Property Code"}
      </p>
    </div>
  );
}
