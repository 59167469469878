import { createSlice } from "@reduxjs/toolkit";
import { updateMultiCurrencyFlag } from "../landing/landingSlice";
import { API_URL } from "../../../utils/Api_URL";
import axios from "axios";
import { IATA_API_ERROR, IATA_ERROR } from "../../../utils/constants";

const initialState = {
  showTaxLoader: true,
  transientTaxOverview: {},
  taxDetail: [],
  transientTaxTotalCount: 1,
  minArrivalFrom: "",
  payloadForTable: {
    filterBy: {
      iata: "",
      confirmationNumber: "",
      arrivalFrom: "",
      arrivalTo: "",
      guestName: "",
      status: ["O"],
    },
    sortBy: {
      sortColumn: "arrivalDate",
      sortValue: "ASC",
    },
    pageNumber: "1",
    pageSize: "25",
  },
  taxTableInternalError: false,
  taxOverviewInternalError: false,
  taxServerError: false,
  tTaxStatusUpdateSuccess: false,
  tTaxStatusUpdateFailed: false,
};

export const transientTaxSlice = createSlice({
  name: "transientTax",
  initialState,
  reducers: {
    transientTaxOverviewReceived: (state, action) => {
      state.transientTaxOverview = action.payload;
    },
    updateTransientTaxParams: (state, action) => {
      state.payloadForTable = { ...state.payloadForTable, ...action.payload };
    },
    updateTransientTaxLoading: (state, action) => {
      state.showTaxLoader = action.payload;
    },
    transientTaxBlockReceived: (state, action) => {
      state.taxDetail = action.payload.taxDetails;
      state.transientTaxTotalCount = action.payload.totalCount;
      state.minArrivalFrom = action?.payload?.minArrivalDate || "";
      state.loading = false;
    },
    updateTaxOverviewInternalError: (state, action) => {
      state.taxOverviewInternalError = action.payload;
    },
    updateTaxTableInternalError: (state, action) => {
      state.taxTableInternalError = action.payload;
    },
    updateServerError: (state, action) => {
      state.taxServerError = action.payload;
    },
    tTaxBatchActionSuccess: (state, action) => {
      state.tTaxStatusUpdateSuccess = action.payload;
    },
    tTaxBatchActionFailed: (state, action) => {
      state.tTaxStatusUpdateFailed = action.payload;
    },
  },
});

export const {
  transientTaxOverviewReceived,
  updateTransientTaxParams,
  transientTaxBlockReceived,
  updateTransientTaxLoading,
  updateServerError,
  updateTaxOverviewInternalError,
  updateTaxTableInternalError,
  tTaxBatchActionSuccess,
  tTaxBatchActionFailed,
} = transientTaxSlice.actions;

export default transientTaxSlice.reducer;

export const fetchTransientTaxOverview =
  (propCode, transientTaxOverviewReqBody) => async (dispatch) => {
    try {
      dispatch(updateTransientTaxLoading(true));
      dispatch(updateTaxOverviewInternalError(false));
      dispatch(updateServerError(false));
      const response = await axios.post(
        `${API_URL.GET_BATCH_OVERVIEW}/${propCode}`,
        transientTaxOverviewReqBody
      );
      dispatch(updateTransientTaxLoading(false));
      if (response?.data) {
        dispatch(transientTaxOverviewReceived(response.data));
        dispatch(updateMultiCurrencyFlag(response?.data?.multipleCurrency));
      } else {
        dispatch(updateMultiCurrencyFlag(false));
        throw response;
      }
    } catch (error) {
      dispatch(updateTransientTaxLoading(false));
      if (error?.response?.status === 404) {
        dispatch(updateServerError(true));
      } else {
        dispatch(updateTaxOverviewInternalError(true));
      }
    }
  };

export const fetchTransientTaxBlock = (payload) => async (dispatch) => {
  try {
    dispatch(updateTransientTaxLoading(true));
    dispatch(updateTaxTableInternalError(false));
    let response = {};
    if (process.env.REACT_APP_ENV === "local") {
      response = await axios.get(
        API_URL.GET_TRANSIENT_TAX_DETAILS,
        payload.tablePayload
      );
    } else {
      response = await axios.post(
        `${API_URL.GET_TRANSIENT_TAX_DETAILS}/${payload.propCode}`,
        payload.tablePayload
      );
    }
    dispatch(updateTransientTaxLoading(false));
    if (response?.data?.taxDetails) {
      dispatch(transientTaxBlockReceived(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    dispatch(updateTransientTaxLoading(false));
    if (error?.response?.status === 404) {
      dispatch(updateServerError(true));
    } else {
      dispatch(updateTaxTableInternalError(true));
    }
  }
};

export const validateIATA = (iataNum) => async (dispatch) => {
  try {
    dispatch(updateServerError(false));
    const iataResponce = await axios.get(`${API_URL.VALIDATE_IATA}/${iataNum}`);
    const indicator = iataResponce?.data?.indicator;
    const isValid = indicator === null || indicator === "" ? IATA_ERROR : true;
    return isValid;
  } catch (error) {
    if (error?.response?.status === 400) {
      return IATA_ERROR;
    } else {
      dispatch(updateServerError(true));
      return IATA_API_ERROR;
    }
  }
};

export const updateTTaxStatus =
  (actionStatus, updateTBReqBody) => async (dispatch) => {
    try {
      dispatch(updateTransientTaxLoading(true));
      dispatch(tTaxBatchActionFailed(false));
      dispatch(updateServerError(false));
      let response = {};
      response = await axios.post(
        `${API_URL.PUT_TTAX_UPDATESTATUS}/${actionStatus}`,
        updateTBReqBody
      );
      dispatch(updateTransientTaxLoading(false));
      if (response.data.status && response.data.status === true) {
        //Success case
        dispatch(tTaxBatchActionSuccess(true));
      } else {
        //Error case
        dispatch(tTaxBatchActionFailed(true));
      }
    } catch (error) {
      dispatch(updateTransientTaxLoading(false));
      //Error case
      if (error?.response?.status === 404) {
        dispatch(updateServerError(true));
      } else {
        dispatch(tTaxBatchActionFailed(true));
      }
    }
  };
