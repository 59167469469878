import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import LoadingBar from "../../components/loadingbar/LoadingBar";
import RichTextEditor from "../../components/richtexteditor/RichTextEditor";
import InfoBar from "../../components/infobar/InfoBar";
import ErrorText from "../../components/ErrorText";
import {
  saveBroadcastMsg,
  fetchBroadcastMsg,
  updateSaveStatus,
} from "../../store/feature/admin/adminSlice";
import {
  SAVE_BTN_LABEL,
  SAVE_FAILED,
  ADMIN,
  BROADCAST_MESSAGES_TITLE,
  MAX_LENGTH_ERROR_MESSAGE,
  BROADCAST_SAVE_SUCCESS,
} from "../../utils/constants";
import { MAX_CHAR_COUNT } from "../../utils/config";
import { removeHtml } from "../../utils/utils";
import OverlayInfobar from "../../components/overlayInfobar/overlayInfobar";

export default function Admin() {
  const [broadcastMsgLocalState, setBroadcastMsgLocalState] = useState("");
  const [messageLength, setMessageLength] = useState(0);
  const [broadcastMsgModified, setBroadcastMsgModified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { broadcastMsg, loading, saveSuccess, saveFailure, serverError } =
    useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    setMessageLength(removeHtml(broadcastMsg.message).length);
  }, [broadcastMsg]);

  const updateMessage = (value) => {
    const text = removeHtml(value);
    if (saveSuccess) {
      dispatch(updateSaveStatus(false));
    }
    setMessageLength(text.length);
    if (text.length <= MAX_CHAR_COUNT) {
      setBroadcastMsgLocalState(value);
      setErrorMessage("");
      setBroadcastMsgModified(true);
    } else {
      setErrorMessage(MAX_LENGTH_ERROR_MESSAGE);
      setBroadcastMsgModified(false);
    }
  };

  const handleBroadcastMsg = () => {
    setBroadcastMsgModified(false);
    dispatch(saveBroadcastMsg(broadcastMsgLocalState));
  };

  useEffect(() => {
    dispatch(fetchBroadcastMsg());
  }, []);

  return (
    <>
      {loading ? <LoadingBar global /> : null}
      <div className="marginHorizontal vh-body">
        <div className="contactus mb-5">
          <div className="w-100 my-5 bg p-2">
            <h1 className="text-center mx-5 mb-4 bold">{ADMIN}</h1>
          </div>
          {serverError ? (
            <OverlayInfobar variant="danger">
              <ErrorText />
            </OverlayInfobar>
          ) : null}
          <div className="broadcast_msg_block">
            <h3>{BROADCAST_MESSAGES_TITLE}</h3>
            <RichTextEditor
              defaultValue={broadcastMsg.message}
              onChange={updateMessage}
              style={{ width: "100%", height: "200px" }}
            ></RichTextEditor>
            <div className="mt-5">
              <Button
                disabled={!broadcastMsgModified}
                onClick={
                  broadcastMsgModified
                    ? handleBroadcastMsg
                    : () => {
                        return false;
                      }
                }
              >
                {SAVE_BTN_LABEL}
              </Button>
              <div
                className={`float-end ${
                  messageLength <= MAX_CHAR_COUNT ? "" : "error_color"
                }`}
              >
                {messageLength} / {MAX_CHAR_COUNT} Characters Entered
              </div>
              {saveSuccess ? (
                <p className="mt-2 font-12 view-stays ">
                  {BROADCAST_SAVE_SUCCESS}
                </p>
              ) : null}
              {saveFailure ? (
                <p className="mt-2 font-12 error_color">{SAVE_FAILED}</p>
              ) : null}
              {errorMessage.length ? (
                <p className="mt-2 font-12 error_color">{errorMessage}</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
