import React from "react";
import { Container } from "react-bootstrap";
import CardDisplay from "../../components/carddisplay/CardDisplay";
import { transientTaxTextColor } from "../../utils/config";
import {
  CURRENCY_TYPE,
  RECORDS_TO_REVIEW,
  RECORD_TO_REVIEW,
} from "../../utils/constants";

export default function TransientTaxOverview({
  overviewData,
  currencyType,
  hasMultipleCurrency,
  isLanding,
  className,
}) {
  const transientTaxOverviewData = overviewData?.transientTax?.list;

  const classNames = {
    colClass: "p-0 mx-0 tax-overview ",
    cardClass: "m-3 py-0 tax-card",
    cardBodyClass: "px-3",
    divClass: "px-3",
  };
  return (
    <Container fluid className={className}>
      <div className={isLanding ? "row row-cols-2" : "row row-cols-4"}>
        {transientTaxOverviewData?.map((cardData, index) => {
          return (
            <CardDisplay
              key={`transientbatch-card-${index + 1}`}
              duration={cardData.duration}
              statusDetail={cardData.count}
              statusDetailLine={
                cardData?.count?.toString() === "1"
                  ? `${RECORD_TO_REVIEW}`
                  : `${RECORDS_TO_REVIEW}`
              }
              amountDetail={`${
                currencyType === CURRENCY_TYPE.USD
                  ? cardData.amountUsd
                  : cardData.amount
              } ${
                currencyType === CURRENCY_TYPE.USD
                  ? CURRENCY_TYPE.USD
                  : overviewData?.transientTax?.currencyCode
              }`}
              fieldName={cardData.fieldName}
              linkTo={cardData.linkTo}
              imageClass={cardData.imageClass}
              isLinkShown={isLanding}
              classNames={classNames}
              hasMultipleCurrency={hasMultipleCurrency}
              currencyType={currencyType}
              styleApplied={
                cardData?.count?.toString() === "0"
                  ? transientTaxTextColor[0]
                  : transientTaxTextColor[cardData.duration].color
              }
            />
          );
        })}
      </div>
    </Container>
  );
}
