import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./GroupBlock.scss";
import returnToBatch from "../../assets/return_to_batch.png";
import GroupBatchOverview from "../../components/groupbatchoverview/GroupBatchOverview";
import GroupFilter from "./component/groupfilter/GroupFilter";
import CurrencyButton from "../../components/currencybutton/CurrencyButton";
import GroupTable from "./component/grouptable/GroupTable";
import {
  fetchGroupBatchOverview,
  fetchGroupBlock,
  groupBatchReviewApproveFailed,
  groupBatchReviewApproveSuccess,
  saveAdjustmentSuccess,
  updateCommNonCommStatus,
  updateGroupBlockTableParams,
} from "../../store/feature/groupblock/groupBlockSlice";
import {
  BATCH_OVERVIEW_TYPE,
  CTAC_SUPPORT,
  CURRENCY_TYPE,
  DOWNLOAD_EXPORT_LABEL,
  ERROR_MESSAGE,
  LANDING_NO_DATA_FOUND,
  PAGINATION_LABEL_AUDIT,
  RETURN_TO_GRA,
} from "../../utils/constants";
import {
  CURRENCY_CONST_PROPERTY,
  CURRENCY_CONST_USD,
  DEFAULT_REVIEW_FILTER,
  GB_DEFAULT_TABLE_PAYLOAD,
} from "../../utils/config";
import Pagination from "../../components/pagination/Pagination";
import LoadingBar from "../../components/loadingbar/LoadingBar";
import { buildExportURL, formatDate } from "../../utils/utils";
import { API_URL } from "../../utils/Api_URL";
import {
  getDownloadFile,
  setPendingLinkBlockID,
  updateCurrancy,
} from "../../store/feature/landing/landingSlice";
import { Dropdown } from "react-bootstrap";
import { useAlertMsg } from "../../utils/context/alertMessageContext";

const Loader = ({ show }) => {
  return <>{show ? <LoadingBar global /> : null}</>;
};

export default function GroupBlock() {
  const {
    currencyType,
    propCode,
    propertyDetails,
    pendingLinkBlockID,
    userInfo,
  } = useSelector((state) => state.landing);
  const {
    alertContextState: {
      CommNonComm,
      reviewApproveFailContext,
      reviewApproveSuccessContext,
      groupAddAdjSuccess,
    },
    alertContextDispatch,
  } = useAlertMsg();
  const navigate = useNavigate();
  const {
    groupDetail,
    groupBatchOverview,
    payloadForTable,
    loadingGroupOverview,
    loadingGroupBlock,
    groupTotalCount,
    overviewInternalError,
    tableInternalError,
    serverError,
    addAdjustmentSuccess,
    reviewApproveSuccess,
    reviewApproveFailed,
    userAction,
    commNonCommSuccess,
    groupBlockIndex,
  } = useSelector((state) => state.group);
  const auditSlice = useSelector((state) => state.audit);
  const { type, currencyCode } = useParams();
  let { state: routeData } = useLocation();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState({});
  const [enablePendingLink, setEnablePendingLink] = useState("");
  const updateGBListParam = (params) => {
    dispatch(updateGroupBlockTableParams({ ...payloadForTable, ...params }));
  };
  const [batchDownloadWInProgress, setBatchDownloadWInProgress] =
    useState(false);
  const [blockDownloadWInProgress, setBlockDownloadWInProgress] =
    useState(false);
  const [filterChanged, setFilterChanged] = useState(0);
  useEffect(() => {
    setEnablePendingLink(pendingLinkBlockID);
  }, [pendingLinkBlockID]);

  useEffect(() => {
    return () => {
      alertContextDispatch({
        type: "reviewApproveFailHide",
      });

      alertContextDispatch({
        type: "reviewApproveSuccessHide",
      });

      alertContextDispatch({
        type: "groupAddAdjSuccessHide",
      });

      alertContextDispatch({
        type: "groupScreenServerErrorHide",
      });

      alertContextDispatch({
        type: "GroupTableInternalErrorHide",
      });

      alertContextDispatch({
        type: "CommNonCommHide",
      });

      alertContextDispatch({
        type: "groupBatchListFailedHide",
      });
    };
  }, []);

  useEffect(() => {
    if (!CommNonComm.show) {
      dispatch(updateCommNonCommStatus(false));
    }
  }, [CommNonComm]);

  // Show ReviewApprove Failed Msg
  useEffect(() => {
    if (reviewApproveFailed) {
      alertContextDispatch({
        type: "reviewApproveFailShow",
        payload: {
          message: `${ERROR_MESSAGE} ${CTAC_SUPPORT} `,
          variant: "danger",
        },
      });
    }
  }, [reviewApproveFailed]);

  const hideReviewApproveError = () => {
    dispatch(groupBatchReviewApproveFailed(false));
  };

  useEffect(() => {
    if (!reviewApproveFailContext.show) {
      hideReviewApproveError();
    }
  }, [reviewApproveFailContext]);

  // Show Review Approve success Msg
  useEffect(() => {
    if (reviewApproveSuccess) {
      alertContextDispatch({
        type: "reviewApproveSuccessShow",
        payload: {
          userAction,
        },
      });
    }
  }, [reviewApproveSuccess]);

  const hideReviewApproveSuccess = () => {
    dispatch(
      groupBatchReviewApproveSuccess({
        reviewApproveSuccess: false,
        userAction: "",
      })
    );
  };

  useEffect(() => {
    if (!reviewApproveSuccessContext.show) {
      hideReviewApproveSuccess();
    }
  }, [reviewApproveSuccessContext]);

  // Show Add Adjustment info bar

  useEffect(() => {
    if (addAdjustmentSuccess) {
      alertContextDispatch({
        type: "groupAddAdjSuccessShow",
      });
    }
  }, [addAdjustmentSuccess]);

  // Hide Add Adjustment Info bar
  const hideAddAdjustmentSuccess = () => {
    dispatch(saveAdjustmentSuccess({ addAdjustmentSuccess: false }));
  };

  useEffect(() => {
    if (!groupAddAdjSuccess.show) {
      hideAddAdjustmentSuccess();
    }
  }, [groupAddAdjSuccess]);

  useEffect(() => {
    if (!currencyCode) {
      dispatch(updateCurrancy(CURRENCY_CONST_PROPERTY));
    } else if (currencyCode === CURRENCY_CONST_USD) {
      dispatch(updateCurrancy(CURRENCY_CONST_USD));
    }
  }, [propCode, currencyCode]);

  let batchTableTimer;
  const refreshGBTableData = () => {
    let tablePayload = {
      ...payloadForTable,
      currencyType: currencyType,
    };
    if (propCode) {
      dispatch(fetchGroupBlock({ tablePayload, propCode }));
      if (batchTableTimer) {
        clearTimeout(batchTableTimer);
      }
    }
  };

  useEffect(() => {
    if (batchTableTimer) {
      clearTimeout(batchTableTimer);
    }
    batchTableTimer = setTimeout(() => {
      refreshGBTableData();
    }, 100);
    return () => {
      if (batchTableTimer) {
        clearTimeout(batchTableTimer);
      }
    };
  }, [payloadForTable, propCode, userInfo]);

  const refreshGBOverview = () => {
    const groupOverviewReqBody = {
      currencyType: currencyType.toLowerCase(),
      type: BATCH_OVERVIEW_TYPE.GROUP_BATCH,
      filterBy: payloadForTable.filterBy,
    };
    if (propCode) {
      dispatch(fetchGroupBatchOverview(propCode, groupOverviewReqBody));
    }
  };

  let batchOverviewTimer;
  useEffect(() => {
    if (batchOverviewTimer) {
      clearTimeout(batchOverviewTimer);
    }
    batchOverviewTimer = setTimeout(() => {
      refreshGBOverview();
    }, 100);
    return () => {
      if (batchOverviewTimer) {
        clearTimeout(batchOverviewTimer);
      }
    };
  }, [propCode, payloadForTable.filterBy]);

  useEffect(() => {
    if (addAdjustmentSuccess || reviewApproveSuccess || commNonCommSuccess) {
      refreshGBTableData();
      refreshGBOverview();
    }
  }, [addAdjustmentSuccess, reviewApproveSuccess, commNonCommSuccess]);

  useEffect(() => {
    if (type) {
      const defaultFilter = {
        ...GB_DEFAULT_TABLE_PAYLOAD,
        filterBy: {
          ...GB_DEFAULT_TABLE_PAYLOAD.filterBy,
          status: [type?.toUpperCase()],
        },
      };
      updateGBListParam(defaultFilter);
    }
  }, [type]);

  useEffect(() => {
    let defaultFilter = {
      ...GB_DEFAULT_TABLE_PAYLOAD,
      filterBy: {
        ...GB_DEFAULT_TABLE_PAYLOAD.filterBy,
        status: [...DEFAULT_REVIEW_FILTER],
      },
    };
    if (pendingLinkBlockID?.length) {
      setEnablePendingLink(pendingLinkBlockID);
      defaultFilter = {
        ...defaultFilter,
        filterBy: {
          ...defaultFilter.filterBy,
          status: [],
          groupBlockId: pendingLinkBlockID,
        },
      };
    }
    if (type) {
      defaultFilter = {
        ...defaultFilter,
        filterBy: {
          ...defaultFilter.filterBy,
          status: [type?.toUpperCase()],
        },
      };
    }
    if (routeData) {
      defaultFilter = {
        ...GB_DEFAULT_TABLE_PAYLOAD,
        ...{ filterBy: routeData.filterFromRoute },
      };
    }
    updateGBListParam(defaultFilter);

    return () => {
      if (pendingLinkBlockID?.length) {
        dispatch(setPendingLinkBlockID(""));
      }
    };
  }, []);

  useEffect(() => {
    setExpand({});
    setTimeout(() => {
      if (groupDetail?.length) {
        if (addAdjustmentSuccess || commNonCommSuccess) {
          setExpand({ [groupBlockIndex]: true });
        } else if (routeData?.groupBlockId) {
          const batchIndex = groupDetail.findIndex(
            (record) => record.groupBlockId === routeData.groupBlockId
          );
          routeData && navigate("/groupblock", { replace: true });
          setExpand({ [batchIndex]: true });
        } else if (pendingLinkBlockID?.length) {
          setExpand({ 0: true });
        }
      }
    }, 1500);
  }, [groupDetail]);

  useEffect(() => {
    setTimeout(() => {
      if (groupDetail?.length) {
        if (addAdjustmentSuccess || commNonCommSuccess) {
          setExpand({ [groupBlockIndex]: true });
        }
      }
    }, 1500);
  }, [addAdjustmentSuccess, commNonCommSuccess]);

  const applyFilter = (filterBy) => {
    let filterByReceived = { ...filterBy };
    if (
      "status" in filterByReceived &&
      filterByReceived.status[0] === undefined
    ) {
      filterByReceived.status = [];
    }
    updateGBListParam({
      filterBy: {
        ...GB_DEFAULT_TABLE_PAYLOAD.filterBy,
        ...filterByReceived,
      },
      pageNumber: 1,
    });
    dispatch(setPendingLinkBlockID(""));
    setFilterChanged((state) => state + 1);
  };

  const downloadXLS = async (groupBatches) => {
    if (groupBatches) {
      setBatchDownloadWInProgress(true);
    } else {
      setBlockDownloadWInProgress(true);
    }
    let fileURL = buildExportURL({
      url: `${API_URL.GROUP_BLOCK_EXPORT}/${propCode}`,
      filterBy: { ...payloadForTable.filterBy, groupBatches },
      currencyType: currencyType,
    });
    const fileName = `Group ${
      groupBatches ? "Batches" : "Blocks"
    }-${propCode}-${formatDate(new Date(), "DDMMMYY")}`;
    const { success } = await dispatch(getDownloadFile(fileURL, fileName));
    if (groupBatches) {
      setBatchDownloadWInProgress(false);
    } else {
      setBlockDownloadWInProgress(false);
    }
  };

  useEffect(() => {
    if (serverError || overviewInternalError) {
      alertContextDispatch({
        type: "groupScreenServerErrorShow",
      });
    }
  }, [serverError, overviewInternalError]);

  useEffect(() => {
    if (tableInternalError) {
      alertContextDispatch({
        type: "GroupTableInternalErrorShow",
      });
    }
  }, [tableInternalError]);

  return (
    <>
      <Loader show={loadingGroupOverview} />

      {groupBatchOverview &&
      groupBatchOverview !== null &&
      Object.keys(groupBatchOverview).length ? (
        <GroupBatchOverview
          data={groupBatchOverview}
          currencyType={currencyType}
          hasMultipleCurrency={groupBatchOverview?.multipleCurrency}
          className={"gb_summery_card"}
        />
      ) : (
        <p className="mt-3 ms-2"> {LANDING_NO_DATA_FOUND} </p>
      )}
      <GroupFilter filterCallback={applyFilter} />
      <hr />
      {enablePendingLink?.length ? (
        <span
          className="card-link btn-link-color cursor-pointer returnto"
          onClick={() => {
            navigate("/group_research_audit", {
              state: {
                filterFromRoute: auditSlice.payloadForTable,
                groupBlockId: enablePendingLink,
              },
            });
          }}
        >
          <img
            alt={RETURN_TO_GRA}
            className=" m-2 icon-cls"
            src={returnToBatch}
          />
          {RETURN_TO_GRA}
        </span>
      ) : null}
      <div className="d-flex justify-content-end">
        <Dropdown className="mx-2">
          <Dropdown.Toggle
            variant="link"
            className="download-btn btn btn-link text-decoration-none font-12 mt-auto py-0"
          >
            <i className="download-icon" /> <span>{DOWNLOAD_EXPORT_LABEL}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              as="button"
              disabled={blockDownloadWInProgress}
              onClick={() => downloadXLS(false)}
            >
              Block Level
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              disabled={batchDownloadWInProgress}
              onClick={() => downloadXLS(true)}
            >
              Batch Level
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="group-currency">
          <CurrencyButton
            showUSD={propertyDetails.currencyCode !== CURRENCY_TYPE.USD}
          />
        </div>
      </div>

      {groupDetail?.length ? (
        <div className="mb-2">
          <Pagination
            className="pagination-bar"
            showOnlyInfo={true}
            currentPage={payloadForTable.pageNumber}
            totalCount={groupTotalCount}
            pageSize={payloadForTable.pageSize}
            pageinationInfoType={PAGINATION_LABEL_AUDIT}
          />
        </div>
      ) : null}
      <div className="group-batch-table">
        {loadingGroupBlock ? <LoadingBar /> : null}
        <GroupTable
          status={type}
          expandIndex={expand}
          filterChanged={filterChanged}
          data={groupDetail}
        />
      </div>
      {groupDetail?.length ? (
        <div>
          <Pagination
            className="pagination-bar"
            currentPage={payloadForTable.pageNumber}
            totalCount={groupTotalCount}
            pageSize={payloadForTable.pageSize}
            onItemPerPageChange={(pageSize, resetPage) => {
              setFilterChanged((state) => state + 1);
              if (resetPage) {
                updateGBListParam({
                  pageNumber: resetPage,
                  pageSize,
                });
              } else {
                updateGBListParam({
                  pageNumber: payloadForTable.pageNumber,
                  pageSize,
                });
              }
            }}
            onPageChange={(page) => {
              updateGBListParam({
                pageNumber: page,
                pageSize: payloadForTable.pageSize,
              });
              setFilterChanged((state) => state + 1);
            }}
          />
        </div>
      ) : (
        <p className="ms-2">{LANDING_NO_DATA_FOUND}</p>
      )}
    </>
  );
}
