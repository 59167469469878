import React, { useState } from "react";
import SuggestionList from "./SuggestionList";

export default function Autocomplete({
  suggestions = [],
  onChange,
  value,
  placeholder,
  isError,
  disabled = false,
}) {
  const [state, setState] = useState({
    // The active selection's index
    activeSuggestion: 0,
    // The suggestions that match the user's input
    filteredSuggestions: [],
    // Whether or not the suggestion list is shown
    showSuggestions: false,
    // What the user has entered
    userInput: value || "",
  });
  //const [inputVal, setInputVal] = useState( value || "")

  // useEffect(() => {
  //     if(state.userInput !== inputVal) {
  //         setInputVal(state.userInput)
  //     }
  // }, [state])

  const onChangeHandler = (e) => {
    const userInput = e.currentTarget.value;
    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    onChange(e.currentTarget.value);
    setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  const onClickHandler = (e) => {
    onChange(e.currentTarget.innerText);
    setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    });
  };

  const onKeyDownHandler = (e) => {
    const { activeSuggestion, filteredSuggestions } = state;
    // User pressed the enter key
    if (e.keyCode === 13) {
      onChange(filteredSuggestions[activeSuggestion]);
      setState({
        ...state,
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setState({ ...state, activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setState({ ...state, activeSuggestion: activeSuggestion + 1 });
    }
  };

  return (
    <>
      <input
        type="text"
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />
      <SuggestionList
        {...state}
        value={value}
        isError={isError}
        onClickFn={onClickHandler}
      />
    </>
  );
}
