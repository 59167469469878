/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import success from "../../assets/success_icon.png";
import danger from "../../assets/danger_icon.png";
import info from "../../assets/info_icon.png";
import "./overlayInfobar.scss";

export default function OverlayInfobar({
  variant,
  enableCloseButton = true,
  enableTimerClose = true,
  timeoutSecs = 10000,
  onCloseCallback,
  disableAutoCloseCallback = false,
  children,
  showIcon = true,
  show,
}) {
  const [showInfoBar, setShow] = useState(false);

  useEffect(() => {
    setShow(show);
  }, [show]);

  useEffect(() => {
    if (!showInfoBar && onCloseCallback) {
      onCloseCallback();
    }
    // return () => {
    //   if (showInfoBar) {
    //     console.log("in -2");
    //     onCloseCallback();
    //   }
    // };
  }, [showInfoBar]);

  const icons = { success, danger, info };
  const IconToShow = icons[variant] ? icons[variant] : danger;

  let timeId;
  useEffect(() => {
    if (showInfoBar) {
      if (enableTimerClose) {
        if (timeId) {
          clearTimeout(timeId);
        }
        timeId = setTimeout(() => {
          setShow(false);
        }, timeoutSecs);

        return () => {
          clearTimeout(timeId);
        };
      }
    }
  }, [showInfoBar]);
  return (
    showInfoBar && (
      <div className="overlay_info-bar-comp">
        <Alert
          variant={variant}
          className={`${variant}-infobar alert-bar py-0 px-2`}
          onClose={() => setShow(false)}
          dismissible={enableCloseButton}
        >
          {showIcon ? (
            <img src={IconToShow} alt={variant} className="img-class m-2 " />
          ) : null}
          {showIcon ? (
            <span className="infobar_text p-2">{children}</span>
          ) : (
            <p className="infobar_text m-2">{children}</p>
          )}
        </Alert>
      </div>
    )
  );
}
