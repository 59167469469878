import React, { useMemo, useState } from "react";
import Form from "../../../../components/form/Form";
import {
  GA_FILTER_LABEL_BLOCK_CODE,
  GA_FILTER_LABEL_BLOCK_NAME,
  GA_FILTER_LABEL_IATA,
  GA_FILTER_LABEL_QUOTE,
  GB_FILTER_LABEL_EVENT_START_DATE_TO,
  GB_FILTER_LABEL_EVENT_START_DATE_FROM,
  TB_FILTER_PLACEHOLDER_STATUS,
  TB_FILTER_TITLE,
  FILTER_TITLE_STATUS,
  FILTER_TITLE_MOD,
  FILTER_MOD_PLACEHOLDER,
  GA_FILTER_LABEL_SHOW_CXLD_BLOCKS,
} from "../../../../utils/constants";
import {
  BATCH_STATUS_TYPE,
  FILTER_EXTRACTED,
  FILTER_WAIT,
  GB_MOD_FILTER_OPTION,
  STATUS_FILTER_OPTION,
} from "../../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { validateIATAFilter } from "../../../../store/feature/landing/landingSlice";
import { convertObjToArrayMultiSelect } from "../../../../utils/utils";

export default function GroupFilter({ filterCallback }) {
  const dispatch = useDispatch();
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const { payloadForTable } = useSelector((state) => state.group);

  const groupIataNumberDefault = payloadForTable.filterBy.groupIataNumber;
  const groupBlockCodeDefault = payloadForTable.filterBy.groupBlockCode;
  const groupBlockNameDefault = payloadForTable.filterBy.groupBlockName;
  const eventStartDateFromDefault = payloadForTable.filterBy.eventStartDateFrom;
  const eventStartDateToDefault = payloadForTable.filterBy.eventStartDateTo;
  const quoteNumberDefault = payloadForTable.filterBy.quoteNumber;
  const statusDefault = payloadForTable.filterBy.status
    .map((data) => BATCH_STATUS_TYPE[data])
    .filter((item) => typeof item === "string");
  const modDefault = payloadForTable.filterBy.modIndicator;
  const showCXLDBlockDefault = payloadForTable.filterBy.cancelledBlock;
  const config = useMemo(() => {
    return {
      form: {
        groupIataNumber: {
          label: GA_FILTER_LABEL_IATA,
          type: "text",
          maxLength: 8,
          defaultValue: groupIataNumberDefault,
          fieldWidth: 200,
          fieldClassName: "flex-none",
          rules: {
            required: false,
            blurMode: true,
            validate: (value) => {
              let result = true;
              if (value?.length) {
                result = dispatch(validateIATAFilter(value));
              }
              return result;
            },
          },
        },
        groupBlockCode: {
          label: GA_FILTER_LABEL_BLOCK_CODE,
          type: "text",
          defaultValue: groupBlockCodeDefault,
          fieldWidth: 200,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        groupBlockName: {
          label: GA_FILTER_LABEL_BLOCK_NAME,
          type: "text",
          defaultValue: groupBlockNameDefault,
          fieldWidth: 400,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        status: {
          label: FILTER_TITLE_STATUS,
          placeholder: TB_FILTER_PLACEHOLDER_STATUS,
          type: "multiselect",
          multiple: true,
          options: STATUS_FILTER_OPTION,
          defaultValue: statusDefault,
          // fieldClassName: "flex-60",
          // width: 275,
          fieldWidth: 300,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        eventStartDateFrom: {
          label: GB_FILTER_LABEL_EVENT_START_DATE_FROM,
          type: "date",
          defaultValue: eventStartDateFromDefault,
          minDate: "",
          maxDate: eventEndDate,
          width: 275,
          fieldClassName: "flex-none",
          rules: {
            validate: (value) => {
              setEventStartDate(value);
              return true;
            },
          },
        },
        eventStartDateTo: {
          label: GB_FILTER_LABEL_EVENT_START_DATE_TO,
          type: "date",
          defaultValue: eventStartDateToDefault,
          minDate: eventStartDate,
          maxDate: "",
          width: 275,
          fieldClassName: "flex-none",
          rules: {
            validate: (value) => {
              setEventEndDate(value);
              return true;
            },
          },
        },
        quoteNumber: {
          label: GA_FILTER_LABEL_QUOTE,
          defaultValue: quoteNumberDefault,
          fieldWidth: 275,
          fieldClassName: "flex-none",
          type: "text",
          rules: {
            required: false,
          },
        },

        modIndicator: {
          label: FILTER_TITLE_MOD,
          placeholder: FILTER_MOD_PLACEHOLDER,
          type: "multiselectv2",
          options: convertObjToArrayMultiSelect(
            GB_MOD_FILTER_OPTION,
            "keyValue"
          ),
          defaultValue: modDefault,
          fieldClassName: "flex-none-275",
          rules: {
            required: false,
          },
        },
        cancelledBlock: {
          label: GA_FILTER_LABEL_SHOW_CXLD_BLOCKS,
          type: "checkbox",
          defaultValue: showCXLDBlockDefault,
          fieldClassName: "show_cancel_blocks",
          rules: {
            required: false,
          },
        },
      },
    };
  }, [payloadForTable.filterBy]);

  const handleSubmit = (filterData) => {
    if (filterData === undefined) {
      setEventEndDate(null);
      setEventStartDate(null);
    }
    if (filterData?.status) {
      filterData.status = filterData.status.map((status) =>
        Object.keys(BATCH_STATUS_TYPE).find(
          (statusKey) => BATCH_STATUS_TYPE[statusKey] === status
        )
      );
      filterData.status.includes(FILTER_WAIT) &&
        filterData.status.push(FILTER_EXTRACTED);
      filterData.status = filterData.status.filter((e) => e);
    }

    if (filterCallback) {
      filterData =
        filterData && Object.keys(filterData)?.length
          ? filterData
          : { status: [] };
      filterCallback(filterData);
    }
  };

  return (
    <div className="mx-2">
      <h3 className="font-30 weight-700">{TB_FILTER_TITLE}</h3>
      <Form
        config={config}
        submitCallBack={handleSubmit}
        className={`group-block-filters filter-form m-0 g-0`}
      />
    </div>
  );
}
