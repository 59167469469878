import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { removeEmpty } from "../../utils/utils";
import FormField from "../formfield/FormField";
import { ACTION_BAR_BTN_NAME } from "../../utils/constants";

const Error = ({ children }) => {
  return <p className="form-error font-12">{children}</p>;
};

export default function AddAdjustmentForm({
  config = { form: {}, secondaryBlock: {} },
  submitCallBack,
  ignoreEmpty = true,
  className = "",
  applyBtnTitle,
  handleAddStayClose,
  hideCancel,
  mode = "onSubmit",
  alertMessage,
  errorMessage,
  warningMessage,
  disableSubmitAction,
  formErrorCallback,
  acrs,
  readOnly,
  type = "adjustment",
  isDirtyCallback,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    watch,
    clearErrors,
    resetField,
  } = useForm({ mode: mode, reValidateMode: mode });

  useEffect(() => {
    resetField("roomNights", { defaultValue: "" });
    resetField("adjustRate", { defaultValue: "" });
    resetField("advCommissionAmt", { defaultValue: "" });
    resetField("noPayReason", { defaultValue: "" });
  }, [watch("adjustCode")]);

  const formRef = useRef(null);
  const [primaryConfig, setPrimaryConfig] = useState({});
  const [secondaryConfig, setSecondaryConfig] = useState({});
  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    setPrimaryConfig(config.form);
    setSecondaryConfig(config.secondaryBlock);
  }, [config]);

  useEffect(() => {
    if (formErrorCallback) {
      formErrorCallback(errors);
    }
  }, [errors]);

  const formBuilder = (dynamicConfig) => {
    return (
      dynamicConfig &&
      Object.keys(dynamicConfig).map((fieldName) => {
        const {
          rules,
          label,
          type = "",
          fieldClassName = "",
          disabled,
          defaultValue,
          presetion,
          width,
          sectionClassName,
        } = dynamicConfig[fieldName];
        return (
          <>
            {type === "hr" ? (
              <hr className="separator" />
            ) : (
              <section
                key={fieldName}
                className={`${sectionClassName} ${fieldClassName} ${type} ${
                  disabled ? "disabled" : ""
                } form-item form-item-summary`}
                style={width ? { width: `${width}px` } : {}}
              >
                <label className={rules?.required ? "required" : ""}>
                  {label}
                </label>
                <Controller
                  name={fieldName}
                  control={control}
                  rules={rules}
                  defaultValue={defaultValue}
                  render={({ field }) => (
                    <div
                      className={`${
                        errors[fieldName] ? "error-border" : ""
                      } ${fieldName.toLowerCase()}-div`}
                    >
                      <FormField
                        key={`field-${fieldName}`}
                        name={fieldName}
                        value={field.value}
                        isError={errors?.[fieldName]}
                        presetion={presetion}
                        onBlur={(val) => {
                          field.onBlur(val);
                          setDirty(true);
                        }}
                        onChange={(val) => {
                          clearErrors(fieldName);
                          field.onChange(val);
                          setDirty(true);
                        }}
                        onKeyup={(val) => {
                          if (field.onKeyup) {
                            field.onKeyup(val);
                          }
                        }}
                        {...dynamicConfig[fieldName]}
                      />
                    </div>
                  )}
                />
                {errors[fieldName] && (
                  <Error>
                    {errors[fieldName]?.message
                      ? errors[fieldName]?.message
                      : rules?.message
                      ? rules?.message
                      : ""}
                  </Error>
                )}
              </section>
            )}
          </>
        );
      })
    );
  };

  const primaryFields = formBuilder(primaryConfig);
  const secondaryFields = formBuilder(secondaryConfig);

  const onSubmit = (data) => {
    if (ignoreEmpty) {
      data = removeEmpty(data);
    }
    submitCallBack(data);
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };
  const isDisableSubmit = () => {
    if (disableSubmitAction) {
      return true;
    } else if (!dirty) {
      return true;
    } else if (Object.keys(errors).length) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isDirtyCallback) {
      isDirtyCallback(dirty);
    }
  }, [isDirty]);

  return (
    <Container className={`${className} form-component`}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div className="flex-fill flex-box-row primary-fields">
          {primaryFields}
        </div>
        {Object.keys(secondaryFields).length ? (
          <div className="flex-fill flex-box-row  secondary-fields">
            {secondaryFields}
          </div>
        ) : null}
        {warningMessage ? (
          <div className="d-flex warning_color">{warningMessage}</div>
        ) : null}
        {!Object.keys(errors).length && alertMessage ? (
          <div className="d-flex error_color">{alertMessage}</div>
        ) : null}
        {!Object.keys(errors).length && errorMessage ? (
          <div className="d-flex error_color py-2">{errorMessage}</div>
        ) : null}
        {!readOnly ? (
          <div className="flex-box-row flex-align-start">
            <Button
              className={hideCancel ? "apply-btn-update" : "apply-btn-validate"}
              disabled={isDisableSubmit()}
              type="submit"
              variant={hideCancel ? "outline-primary" : "primary"}
            >
              {applyBtnTitle}
            </Button>
            {!hideCancel && (
              <Button
                variant="link"
                className="text-decoration-none"
                onClick={handleAddStayClose}
              >
                {ACTION_BAR_BTN_NAME.CANCEL}
              </Button>
            )}
          </div>
        ) : null}
      </form>
    </Container>
  );
}
