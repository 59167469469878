import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  validatePropCode,
  updatePropertyCodeError,
  changedPropertyCode,
  updatePropertyCode,
  usersInfoReceived,
} from "../../store/feature/landing/landingSlice";
import { getActiveRoute, getSession, parseJwt } from "../../utils/utils";
import FormField from "../formfield/FormField";
import { PLACEHOLDER_CHANGE_PROPERTY } from "../../utils/constants";

export default function PropertyTitleBar() {
  const {
    propertyName,
    propCode,
    isInvalidPropertyCodeError,
    propertyErrorMsg,
    propCodeChanged,
    userInfo,
  } = useSelector((state) => state.landing);
  const dispatch = useDispatch();

  const [propertyCode, setPropertyCode] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const componentName = getActiveRoute(location.pathname);

  useEffect(() => {
    if (propCodeChanged) {
      changedPropertyCode(false);
      navigate(`/${componentName}`);
      navigate(0);
    }
  }, [propCode]);

  const validatePropertyCode = async (propCodeParam) => {
    if (propCodeParam.length === 5 && propCodeParam.match(/^[0-9a-zA-Z]+$/)) {
      const validateAPI = await validatePropCode(propCodeParam);
      if (validateAPI.status === 200) {
        const propCodeChanged = true;
        const accessToken = getSession("accessToken");
        const userInfo_data = parseJwt(accessToken);
        dispatch(updatePropertyCode(propCodeParam));
        dispatch(changedPropertyCode(propCodeChanged));
        dispatch(usersInfoReceived(userInfo_data, propCodeParam));
      } else {
        dispatch(updatePropertyCodeError(true));
      }
    } else {
      dispatch(updatePropertyCodeError(true));
    }
  };

  const handleKeyDown = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      validatePropertyCode(event?.target?.value);
    }
  };
  const updateTextboxPropertyChange = (e) => {
    setPropertyCode(e?.target?.value?.toUpperCase());
    if (isInvalidPropertyCodeError) {
      dispatch(updatePropertyCodeError(false));
    }
  };

  const updateSelectboxPropertyChange = (value) => {
    setPropertyCode(value);
    validatePropertyCode(value);
    if (isInvalidPropertyCodeError) {
      dispatch(updatePropertyCodeError(false));
    }
  };

  return (
    <Row className="marginTitle property_title_bar">
      <Col>
        {propCode && propertyName ? (
          <h1 className="weight-700">{`${propertyName}  (${propCode})`}</h1>
        ) : null}
      </Col>
      {userInfo?.abovePropertyUser || userInfo?.propertyList?.length > 1 ? (
        <Col lg={2}>
          <div className="float-end">
            <h6>Change Property</h6>
            <div className="input-container">
              {userInfo?.abovePropertyUser ? (
                <>
                  <input
                    className={
                      "input-field " +
                      (isInvalidPropertyCodeError ? "error-border" : "")
                    }
                    type="text"
                    placeholder={PLACEHOLDER_CHANGE_PROPERTY}
                    maxLength="5"
                    value={propertyCode}
                    onChange={updateTextboxPropertyChange}
                    onKeyDown={handleKeyDown}
                  />
                  <i
                    className="icon-property"
                    onClick={() => validatePropertyCode(propertyCode)}
                  ></i>
                </>
              ) : (
                <div className="form-item">
                  <FormField
                    type="select"
                    options={userInfo.propertyList}
                    value={userInfo.propCode}
                    onChange={updateSelectboxPropertyChange}
                  />
                </div>
              )}
            </div>
            <p
              className={
                isInvalidPropertyCodeError ? "error-message" : "hidden"
              }
            >
              {propertyErrorMsg || "Invalid Property Code"}
            </p>
          </div>
        </Col>
      ) : null}
    </Row>
  );
}
