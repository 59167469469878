import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Form from "../../../../components/form/Form";
import {
  GA_FILTER_LABEL_ACTIVATION_INDICATOR,
  GA_FILTER_LABEL_BLANK_IATA,
  GA_FILTER_LABEL_BLOCK_CODE,
  GA_FILTER_LABEL_BLOCK_NAME,
  GA_FILTER_LABEL_COMMISSION_INDICATOR,
  GA_FILTER_LABEL_EVENT_START_FROM,
  GA_FILTER_LABEL_EVENT_START_TO,
  GA_FILTER_LABEL_FAST_PAY_INDICATOR,
  GA_FILTER_LABEL_IATA,
  GA_FILTER_LABEL_QUOTE,
  GA_FILTER_LABEL_SUSPENSION_INDICATOR,
  SS_FILTER_SELECTED,
  TB_FILTER_TITLE,
  FILTER_TITLE_STATUS,
  GA_FILTER_LABEL_SHOW_CXLD_BLOCKS,
} from "../../../../utils/constants";
import {
  GA_ACT_FILTER_OPTIONS,
  GA_COM_FILTER_OPTIONS,
  GA_DEFAULT_TABLE_PAYLOAD,
  GA_PAY_FILTER_OPTIONS,
  GA_STATUS_FILTER_OPTIONS,
  GA_STATUS_TYPE,
  GA_SUS_FILTER_OPTIONS,
} from "../../../../utils/config";
import { validateIATA } from "../../../../store/feature/transienttax/transientTaxSlice";

export default function AuditFilter({ filterCallback }) {
  const { payloadForTable } = useSelector((state) => state.audit);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isBlankIata, setIsBlankIata] = useState(false);
  const [isIataEntered, setIsIataEntered] = useState(false);

  const dispatch = useDispatch();

  const statusDefault = payloadForTable.filterBy.status.map(
    (element) => GA_STATUS_TYPE[element]
  );

  useEffect(() => {
    setStartDate(payloadForTable.filterBy.eventStartDateFrom);
    setEndDate(payloadForTable.filterBy.eventStartDateTo);
  }, [payloadForTable.filterBy]);

  const suspensionIndicatorDefault = payloadForTable.filterBy.suspsnseInd;
  const fastPayIndicatorDefault = payloadForTable.filterBy.fpIndicator;
  const commissionIndicatorDefault = payloadForTable.filterBy.comIndicator;
  const activationIndicatorDefault = payloadForTable.filterBy.actIndicator;
  const showCXLDBlockDefault = payloadForTable.filterBy.cancelledBlock;
  const defaultGroupIataNumber = payloadForTable.filterBy.groupIataNumber;
  const defaultQuoteNumber = payloadForTable.filterBy.quoteNumber;
  const defaultGroupBlockCode = payloadForTable.filterBy.groupBlockCode;
  const defaultGroupBlockName = payloadForTable.filterBy.groupBlockName;
  const defaultBlankIATA = payloadForTable.filterBy.blankIATA;

  const config = useMemo(() => {
    let configData = {
      form: {
        groupIataNumber: {
          label: GA_FILTER_LABEL_IATA,
          type: "text",
          maxLength: 8,
          defaultValue: defaultGroupIataNumber,
          fieldWidth: 185,
          fieldClassName: "flex-none",
          rules: {
            required: false,
            blurMode: true,
            validate: (value) => {
              let result = true;
              if (value?.length) {
                setIsIataEntered(true);
                result = dispatch(validateIATA(value));
              } else {
                setIsIataEntered(false);
              }
              return result;
            },
          },
        },
        quoteNumber: {
          label: GA_FILTER_LABEL_QUOTE,
          type: "text",
          defaultValue: defaultQuoteNumber,
          fieldWidth: 150,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        groupBlockCode: {
          label: GA_FILTER_LABEL_BLOCK_CODE,
          type: "text",
          defaultValue: defaultGroupBlockCode,
          fieldWidth: 200,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        eventStartDateFrom: {
          label: GA_FILTER_LABEL_EVENT_START_FROM,
          type: "date",
          minDate: "",
          maxDate: endDate,
          defaultValue: startDate,
          fieldClassName: "flex-none",
          fieldWidth: 245,
          rules: {
            validate: (value) => {
              setStartDate(value);
              return true;
            },
          },
        },
        eventStartDateTo: {
          label: GA_FILTER_LABEL_EVENT_START_TO,
          type: "date",
          minDate: startDate,
          maxDate: "",
          defaultValue: endDate,
          fieldClassName: "flex-none",
          fieldWidth: 245,
          rules: {
            validate: (value) => {
              setEndDate(value);
              return true;
            },
          },
        },
        status: {
          label: FILTER_TITLE_STATUS,
          placeholder: SS_FILTER_SELECTED,
          type: "multiselect",
          defaultValue: statusDefault,
          options: GA_STATUS_FILTER_OPTIONS,
          fieldWidth: 170,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        groupBlockName: {
          label: GA_FILTER_LABEL_BLOCK_NAME,
          type: "text",
          defaultValue: defaultGroupBlockName,
          fieldWidth: 325,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        suspsnseInd: {
          label: GA_FILTER_LABEL_SUSPENSION_INDICATOR,
          type: "multiselect",
          placeholder: SS_FILTER_SELECTED,
          defaultValue: suspensionIndicatorDefault,
          options: GA_SUS_FILTER_OPTIONS,
          fieldWidth: 200,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        actIndicator: {
          label: GA_FILTER_LABEL_ACTIVATION_INDICATOR,
          type: "multiselect",
          placeholder: SS_FILTER_SELECTED,
          defaultValue: activationIndicatorDefault,
          options: GA_ACT_FILTER_OPTIONS,
          fieldWidth: 200,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        comIndicator: {
          label: GA_FILTER_LABEL_COMMISSION_INDICATOR,
          type: "multiselect",
          placeholder: SS_FILTER_SELECTED,
          defaultValue: commissionIndicatorDefault,
          options: GA_COM_FILTER_OPTIONS,
          fieldWidth: 200,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        fpIndicator: {
          label: GA_FILTER_LABEL_FAST_PAY_INDICATOR,
          type: "multiselect",
          placeholder: SS_FILTER_SELECTED,
          defaultValue: fastPayIndicatorDefault,
          options: GA_PAY_FILTER_OPTIONS,
          fieldWidth: 200,
          fieldClassName: "flex-none",
          rules: {
            required: false,
          },
        },
        cancelledBlock: {
          label: GA_FILTER_LABEL_SHOW_CXLD_BLOCKS,
          type: "checkbox",
          defaultValue: showCXLDBlockDefault,
          fieldClassName: "show_cancel_blocks",
          divClassName: "float-start",
          rules: {
            required: false,
          },
        },
      },
    };
    configData.secondaryBlock = {};
    configData.secondaryBlock.blankIATA = {
      label: GA_FILTER_LABEL_BLANK_IATA,
      fieldClassName: "blank_iata",
      type: "checkbox",
      defaultValue: defaultBlankIATA,
      rules: {
        validate: (value) => {
          setIsBlankIata(value);
        },
      },
    };
    if (isBlankIata) {
      configData.form.groupIataNumber.disabled = true;
    }
    if (isIataEntered) {
      configData.secondaryBlock.blankIATA.disabled = true;
    }
    return configData;
  }, [
    isBlankIata,
    isIataEntered,
    startDate,
    endDate,
    payloadForTable.filterBy,
    suspensionIndicatorDefault,
  ]);

  const handleSubmit = (filterData) => {
    if (filterData === undefined) {
      setIsBlankIata(false);
      setIsIataEntered(false);
      setEndDate(null);
      setStartDate(null);
    }
    if (filterData?.status) {
      filterData.status = filterData.status.map((element) =>
        Object.keys(GA_STATUS_TYPE).find((k) => GA_STATUS_TYPE[k] === element)
      );
    }
    if (filterCallback) {
      filterData =
        filterData && Object.keys(filterData)?.length
          ? filterData
          : {
              ...GA_DEFAULT_TABLE_PAYLOAD.filterBy,
              eventStartDateFrom: null,
              eventStartDateTo: null,
            };
      filterCallback(filterData);
    }
  };

  return (
    <div className="mx-2">
      <h3 className="font-30 weight-700">{TB_FILTER_TITLE}</h3>
      <Form
        config={config}
        submitCallBack={handleSubmit}
        className={`audit-filters filter-form m-0 g-0`}
      />
    </div>
  );
}
