import React from "react";
import { CTAC_SUPPORT, ERROR_MESSAGE } from "../utils/constants";
import { CTAC_SUPPORT_LINK } from "../utils/config";

export default function ErrorText({ sortBy }) {
  return (
    <>
      {ERROR_MESSAGE}
      <a
        target="_blank"
        className="support"
        rel="noopener noreferrer"
        href={CTAC_SUPPORT_LINK}
      >
        {CTAC_SUPPORT}
      </a>
    </>
  );
}
