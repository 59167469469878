import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTableServerSide from "../../../../components/datatableserverside/DataTableServerSide";
import {
  GA_PAY_HISTORY,
  GA_PENDING,
  GA_TABLE_HEADER_COMMISSION_AMT,
  GA_TABLE_HEADER_PAYMENT,
  GA_TABLE_HEADER_PAY_CURRENCY,
  GA_TABLE_HEADER_PAY_DATE,
  LANDING_NO_DATA_FOUND,
  STATUS,
} from "../../../../utils/constants";
import { formatDate, setSession } from "../../../../utils/utils";
import { Button } from "react-bootstrap";
import { setPendingLinkBlockID } from "../../../../store/feature/landing/landingSlice";
import { useDispatch } from "react-redux";

const RenderStatus = (row, parantData) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gotoGroupBlock = () => {
    dispatch(setPendingLinkBlockID(parantData.groupBlockId));
    navigate(`/groupblock`);
  };
  return (
    <div>
      {row?.original?.status === GA_PENDING ? (
        <Button
          variant="link"
          onClick={gotoGroupBlock}
          className="view-stays p-0"
        >
          {row.original.status}
        </Button>
      ) : (
        <span>{row.original.status}</span>
      )}
    </div>
  );
};

const renderCommissionAmtCell = ({ row }) => (
  <div className="align-right pe-1">{row.original.commissionAmount}</div>
);

const renderCheckDateCell = ({ row }) =>
  row?.original?.checkDate &&
  (row?.original?.checkDate.includes("*") ||
    row?.original?.checkDate.includes("NA"))
    ? row?.original?.checkDate
    : formatDate(row?.original?.checkDate, "DDMMMYY").toUpperCase();

export default function PayDetail({ data }) {
  const column = useMemo(() => [
    {
      accessorKey: "status",
      header: () => STATUS,
      footer: (props) => props.column.id,
      enableSorting: false,
      cell: ({ row }) => RenderStatus(row, data),
      width: 110,
    },
    {
      accessorKey: "checkDate",
      header: () => GA_TABLE_HEADER_PAY_DATE,
      footer: (props) => props.column.id,
      cell: renderCheckDateCell,
      enableSorting: false,
      width: 180,
    },
    {
      accessorKey: "checkNumber",
      header: () => GA_TABLE_HEADER_PAYMENT,
      footer: (props) => props.column.id,
      enableSorting: false,
      width: 180,
    },
    {
      accessorKey: "commissionAmount",
      header: () => GA_TABLE_HEADER_COMMISSION_AMT,
      footer: (props) => props.column.id,
      enableSorting: false,
      cell: renderCommissionAmtCell,
      className: "header_right_align pe-1",
      width: 150,
    },
    {
      accessorKey: "paidCurrency",
      header: () => GA_TABLE_HEADER_PAY_CURRENCY,
      footer: (props) => props.column.id,
      width: 110,
      enableSorting: false,
    },
  ]);
  const { payHistory = [] } = data;

  return (
    <div className="pay-history">
      <p className="weight-700">{GA_PAY_HISTORY}</p>
      {payHistory.length ? (
        <DataTableServerSide
          data={payHistory}
          column={column}
          fixedWidthColumn={true}
        />
      ) : (
        <p>{LANDING_NO_DATA_FOUND}</p>
      )}
    </div>
  );
}
