import { NavLink } from "react-router-dom";

const TabNavigation = ({ activeKey = "transient", selectedTab, children }) => {
  return (
    <>
      <ul className="mb-3 tab-navigation nav nav-tabs" role="tablist">
        <li className="nav-item" role="presentation">
          <NavLink className="nav-link" to="/transient">
            Transient Batches
          </NavLink>
        </li>
        <li className="nav-item" role="presentation">
          <NavLink className="nav-link" to="/transienttaxes">
            Transient Taxes
          </NavLink>
        </li>
        <li className="nav-item" role="presentation">
          <NavLink className="nav-link" to="/groupblock">
            Group Blocks
          </NavLink>
        </li>
        <li className="nav-item" role="presentation">
          <NavLink className="nav-link" to="/group_research_audit">
            Group Research &amp; Audit
          </NavLink>
        </li>
      </ul>
      <div>{children}</div>
    </>
  );
};
export default TabNavigation;
