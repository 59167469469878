import React from "react";
import Alert from "react-bootstrap/Alert";

export default function ActionBar({ showActionBar, message, children }) {
  return (
    <div className="action-bar-comp">
      <Alert
        show={showActionBar}
        variant="success"
        className="action-bar-alert px-0 m-0"
      >
        <div className="d-flex flex-row bd-highlight mx-4">
          <div className="action-bar-info my-1 weight-700">{message}</div>
          {children}
        </div>
      </Alert>
    </div>
  );
}
