import React from "react";

export default function Logout() {
  return (
    <>
      <div className="marginHorizontal vh-body">
        <div className="logout m-5">
          <div className="w-100 my-5 bg p-5">
            <h5 className="text-center mx-5">
              You have been signed out, but your session will stay active for 15
              minutes. <b>Close your browser to end your session completely.</b>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}
